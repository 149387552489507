/** VUEX module for device components **/
import Vue from "vue";
import moment from "moment";

let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    settings: {}
  },
  // -----------------------------------------------------------------
  getters: {
    getSettings: (state) => {
      return state.settings;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    ADD_SETTING(state, payload) {
      payload = {
        ...payload,
        report_generation_days_array: payload.report_generation_days.split(",")
      };
      Vue.set(state, "settings", payload);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    getSettings: async ({ dispatch, commit, state, rootGetters }, value) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/visitorservice/settings/";

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client.getRequest(API_URL).then((data) => {
          let validUrl = "";
          let result = data.result;

          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined) {
                validUrl = key;
                break outer_loop;
              }
            }

            if (result[validUrl] !== undefined) {
              result[validUrl].report_generation_time = moment(
                result[validUrl].report_generation_time,
                "hh:mm"
              ).format("hh:mm:ss");
              let data = result[validUrl];
              let urlObject;
              let hostName;
              let protocol;
              try {
                urlObject = new URL(data.public_url);
                if (
                  urlObject.pathname != undefined &&
                  urlObject.pathname != "/"
                ) {
                  hostName = urlObject.hostname + urlObject.pathname;
                  protocol = urlObject.protocol;
                } else {
                  hostName = urlObject.hostname;
                  protocol = urlObject.protocol;
                }
              } catch (e) {
                hostName = null;
                protocol = "http://";
              }
              data = {
                ...data,
                public_hostname: hostName,
                public_protocol: protocol + "//"
              };
              commit("ADD_SETTING", data);
            }
          } else {
            let message = {
              message_text: data.detail + " get settings",
              message_type: "danger"
            };

            console.log("message", message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    updateSettings: async ({ dispatch, commit, state, rootGetters }, value) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/visitorservice/settings/";

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      value = {
        ...value,
        report_generation_days: value.report_generation_days_array.join(),
        public_url: value.public_protocol + value.public_hostname
      };

      console.log("value", value);

      try {
        client.putRequest(API_URL, value).then((data) => {
          if (data.detail) {
            let message = {
              message_text: data.detail + " update settings",
              message_type: "danger"
            };

            console.log("message", message);

            dispatch("session/addGlobalAlertMessage", message, {
              root: true
            });
          } else {
            let message = {
              message_text: "Success update settings.",
              message_type: "success"
            };

            dispatch("session/addGlobalAlertMessage", message, {
              root: true
            });
          }

          setTimeout(() => {
            dispatch("getSettings");
          }, 1000);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
