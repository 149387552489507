/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    visitors: {},
    visitorsOrder: []
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },

    getVisitors: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.visitorsOrder) {
        var obj_id = state.visitorsOrder[i];
        obj_list.push(state.visitors[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }

      return result;
    },
    getVisitor: (state, getters) => (id) => {
      var result = state.visitors[id];
      return result;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    ADD_VISITOR(state, payload) {
      var id = String(payload.id);
      if (state.visitorsOrder.indexOf(id) < 0) {
        state.visitorsOrder.push(id);
      }
      Vue.set(state.visitors, id, payload);
    },
    UPDATE_VISITOR(state, payload) {
      var id = String(payload.id);
      var update_data = payload.data;
      var obj = state.visitors[id];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.visitors, id, obj);
    },
    DELETE_VISITOR(state, id) {
      var id2 = String(id);
      var index = state.visitorsOrder.indexOf(id2);
      if (index >= 0) {
        state.visitorsOrder.splice(index, 1);
        delete state.visitors[id2];
      }
    },
    RESET_VISITOR(state) {
      state.visitorsOrder = [];
      state.visitors = {};
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addVisitor: (context, value) => {
      context.commit("ADD_VISITOR", value);
    },
    updateVisitor: (context, value) => {
      context.commit("UPDATE_VISITOR", value);
    },
    deleteVisitor: (context, value) => {
      context.commit("DELETE_VISITOR", value);
    },
    resetVisitor: (context) => {
      context.commit("RESET_VISITOR");
    },

    getVisitHistory: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL =
        apiserverURL + "/api/visitorservice/visitor/" + value.id + "/visits/";
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      client.getRequest(API_URL).then((data) => {
        let array = [];
        let validUrl = "";
        let result = data.result;

        if (result !== undefined) {
          outer_loop: for (let key in result) {
            if (result[key] !== undefined && Array.isArray(result[key])) {
              validUrl = key;
              break outer_loop;
            }
          }
        }

        if (result[validUrl] !== undefined && Array.isArray(result[validUrl])) {
          for (let key2 in result[validUrl]) {
            array.push(result[validUrl][key2]);
          }
        }

        if (array.length == 0) {
          value.is_disabled = false;
          value.has_history = true;
        } else {
          value.is_disabled = true;
          value.has_history = false;
        }
      });
    },
    getCheckBlock: async ({ dispatch, commit, state, rootGetters }, value) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/visitorservice/blocklist/check/";
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      let param = [];
      if (value.nric_number == "") {
        value.nric_number = null;
      }
      if (value.passport_number == "") {
        value.passport_number = null;
      }
      if (value.custom_id == "") {
        value.custom_id = null;
      }
      param.push(
        { nric_number: value.nric_number },
        { passport_number: value.passport_number },
        { custom_id: value.custom_id }
      );

      for (let key3 in param) {
        client.postRequest(API_URL, param[key3]).then((data) => {
          let result = data.result;
          let error = data.detail;

          if (error != null) {
            console.log("param[key3]", param[key3]);
            console.log("error", error);
          } else {
            let validUrl = "";

            if (Object.keys(result).length !== 0) {
              outer_loop: for (let key4 in Object.keys(result)) {
                if (Object.keys(result)[key4] != undefined) {
                  validUrl = Object.keys(result)[key4];
                  break outer_loop;
                }
              }
              if (result[validUrl] != null) {
                value.blocklist = result[validUrl];
                value.is_blocklisted = result[validUrl].is_blocklisted;
                value.disabled_update = true;
              }
            }
          }
        });
      }
    },
    getVmsPreferences: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL =
        apiserverURL + "/api/visitorservice/visit_preference/all/";

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client.getRequest(API_URL).then((data) => {
          let validUrl = "";
          let result = data.result;

          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }

            if (
              result[validUrl] !== undefined &&
              Array.isArray(result[validUrl])
            ) {
              if (result[validUrl].length > 0) {
                for (let index in result[validUrl]) {
                  if (
                    result[validUrl][index].additional_metadata
                      .vms_pdpa_setting == "save_partial"
                  ) {
                    if (value.passport_number != null) {
                      let input = value.passport_number;
                      let length = input.length;
                      let input2 = input.substring(0, length - 4).split("");
                      let input3 = input.substring(length - 4);

                      let output = [];
                      input2.forEach((letter) => {
                        letter = "*";
                        output.push(letter);
                      });

                      value.passport_number = output.join("") + input3;
                    } else if (value.nric_number != null) {
                      let input = value.nric_number;
                      let length = input.length;
                      let input2 = input.substring(0, length - 4).split("");
                      let input3 = input.substring(length - 4);

                      let output = [];
                      input2.forEach((letter) => {
                        letter = "*";
                        output.push(letter);
                      });

                      value.nric_number = output.join("") + input3;
                    } else {
                      value.custom_id = null;
                    }

                    let contact_number = value.contact_number;
                    let length1 = contact_number.length;
                    contact_number = contact_number.substring(length1 - 4);

                    //let email = data.email.substring(4, 0);

                    let custom_id = null;

                    if (value.passport_number != null) {
                      let length2 = value.passport_number.length;
                      let passport_number = value.passport_number.substring(
                        length2 - 4
                      );
                      custom_id = contact_number + passport_number;
                      value.custom_id = custom_id;
                    } else if (value.nric_number != null) {
                      let length3 = value.nric_number.length;
                      let nric_number = value.nric_number.substring(
                        length3 - 4
                      );
                      custom_id = contact_number + nric_number;
                      value.custom_id = custom_id;
                    } else {
                      value.custom_id = null;
                    }
                  } else {
                    value.custom_id = null;
                  }
                }
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
