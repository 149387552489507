<template>
  <nav
    id="sidebar"
    class="sidebar-wrapper"
    :class="[isExpandMode ? 'expand-mode' : '']"
  >
    <div class="sidebar-content">
      <!-- sidebar-brand  -->
      <div class="sidebar-brand" v-if="hasDashboardPermission">
        <router-link
          :to="{ name: 'PSIM' }"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a :href="href" class="logo" @click="navigate">
            <div class="logo-part-1">Smart</div>
            <div class="logo-part-2">{{ sidebarScModeDisplay }}</div>
          </a>
        </router-link>
      </div>
      <div class="sidebar-brand" v-else>
        <div class="logo">
          <div class="logo-part-1">Smart</div>
          <div class="logo-part-2">{{ sidebarScModeDisplay }}</div>
        </div>
      </div>

      <!-- sidebar-menu  -->
      <div class="sidebar-menu">
        <ul>
          <li
            class="sidebar-item"
            v-b-tooltip.hover
            title="Workspaces"
            v-if="hasDashboardPermission"
          >
            <b-dropdown id="nav_workspaces" dropright no-caret>
              <template #button-content>
                <i class="fa fa-columns"></i>
              </template>
              <b-dropdown-header
                id="dropdown-header-label"
                v-if="getSharedWorkspaces.length > 0"
              >
                Shared Workspaces
              </b-dropdown-header>
              <b-dropdown-item-button
                v-for="(workspace, w_index) in getSharedWorkspaces"
                :key="'shared_' + workspace.id"
                :active="getSelectedWorkspace.id === workspace.id"
                @click="updateSelectedWorkspace(workspace)"
              >
                {{ w_index + 1 }}. {{ workspace.name }}
              </b-dropdown-item-button>

              <div v-if="getCurrentScMode === 'SC_DASHBOARD'">
                <b-dropdown-header
                  id="dropdown-header-label"
                  v-if="getWorkspaces.length > 0"
                >
                  Personal Workspaces
                </b-dropdown-header>
                <b-dropdown-item-button
                  v-for="(workspace, w_index) in getWorkspaces"
                  :key="workspace.id"
                  :active="getSelectedWorkspace.id === workspace.id"
                  @click="updateSelectedWorkspace(workspace)"
                >
                  {{ w_index + 1 }}. {{ workspace.name }}
                  <span v-if="workspace.is_default"
                    ><small>(default)</small></span
                  >
                </b-dropdown-item-button>
              </div>
              <div v-if="getCurrentScMode === 'SC_DASHBOARD'">
                <b-dropdown-divider></b-dropdown-divider>
              </div>

              <router-link
                :to="{ name: 'Workspace List' }"
                v-slot="{ href, route, navigate }"
                custom
                v-if="hasWorkspaceManagementPermission"
              >
                <b-dropdown-item
                  :href="href"
                  :class="[
                    activeMenu === 'workspace_management' ? 'active' : ''
                  ]"
                  @click="navigate"
                >
                  <i class="fa fa-edit mr-1"></i>Manage Workspaces
                </b-dropdown-item>
              </router-link>
            </b-dropdown>
          </li>

          <li
            class="sidebar-item"
            v-b-tooltip.hover.righttop
            title="Smart VMS"
            v-if="hasVmsPermission"
          >
            <b-dropdown id="nav_settings" dropright no-caret>
              <template #button-content>
                <i class="fa fa-address-card"></i>
              </template>
              <b-dropdown-header id="dropdown-header-label">
                Smart VMS
              </b-dropdown-header>

              <!--              <router-link-->
              <!--                :to="{ name: 'create-visit' }"-->
              <!--                v-slot="{ href, route, navigate }"-->
              <!--                custom-->
              <!--              >-->
              <!--                <b-dropdown-item :href="href" @click="navigate">-->
              <!--                  Operator Mode-->
              <!--                </b-dropdown-item>-->
              <!--              </router-link>-->

              <!--              <router-link-->
              <!--                :to="{ name: 'kiosk' }"-->
              <!--                v-slot="{ href, route, navigate }"-->
              <!--                custom-->
              <!--              >-->
              <!--                <b-dropdown-item :href="href" @click="navigate">-->
              <!--                  Kiosk Mode-->
              <!--                </b-dropdown-item>-->
              <!--              </router-link>-->

              <!--              <router-link-->
              <!--                :to="{ name: 'kiosk2' }"-->
              <!--                v-slot="{ href, route, navigate }"-->
              <!--                custom-->
              <!--              >-->
              <!--                <b-dropdown-item :href="href" @click="navigate">-->
              <!--                  Kiosk Mode-->
              <!--                </b-dropdown-item>-->
              <!--              </router-link>-->

              <!--              <b-dropdown-divider></b-dropdown-divider>-->

              <!--              <b-dropdown-header id="dropdown-header-label">-->
              <!--                Manage-->
              <!--              </b-dropdown-header>-->

              <router-link
                :to="{ name: 'visits' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate()">
                  Visits
                </b-dropdown-item>
              </router-link>

              <router-link
                :to="{ name: 'visitors' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate()">
                  Visitors
                </b-dropdown-item>
              </router-link>

              <router-link
                :to="{ name: 'company' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate()">
                  Companies
                </b-dropdown-item>
              </router-link>

              <router-link
                :to="{ name: 'host' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate()">
                  Hosts
                </b-dropdown-item>
              </router-link>
              <router-link
                :to="{ name: 'visitor-type' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate()">
                  Visitor Type
                </b-dropdown-item>
              </router-link>

              <!--              <router-link-->
              <!--                :to="{ name: 'custom-forms' }"-->
              <!--                v-slot="{ href, route, navigate }"-->
              <!--                custom-->
              <!--              >-->
              <!--                <b-dropdown-item :href="href" @click="navigate">-->
              <!--                  Custom Forms-->
              <!--                </b-dropdown-item>-->
              <!--              </router-link>-->

              <!-- <router-link
                :to="{ name: 'preferences' }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-dropdown-item :href="href" @click="navigate">
                  Visit Preferences
                </b-dropdown-item>
              </router-link> -->

              <!--              <b-dropdown-divider></b-dropdown-divider>-->

              <!--              <b-dropdown-header id="dropdown-header-label">-->
              <!--                Sample-->
              <!--              </b-dropdown-header>-->

              <!--              <router-link-->
              <!--                :to="{ name: 'ic_reader_sample' }"-->
              <!--                v-slot="{ href, route, navigate }"-->
              <!--                custom-->
              <!--              >-->
              <!--                <b-dropdown-item :href="href" @click="navigate">-->
              <!--                  IC Reader-->
              <!--                </b-dropdown-item>-->
              <!--              </router-link>-->
            </b-dropdown>
          </li>
        </ul>
      </div>
    </div>

    <div class="sidebar-footer">
      <ul>
        <li
          class="sidebar-item"
          :class="[
            activeMenu === 'open-cases' || activeMenu === 'close-cases'
              ? 'active'
              : ''
          ]"
          v-b-tooltip.hover.righttop
          title="Cases"
          v-if="hasCasesManagementPermission"
        >
          <b-dropdown dropright no-caret>
            <template #button-content>
              <i class="fas fa-clipboard-list"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              Cases
            </b-dropdown-header>
            <router-link
              :to="{ name: 'open-cases' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'open-cases' ? 'active' : '']"
                @click="navigate()"
                >Open Cases
              </b-dropdown-item>
            </router-link>
            <router-link
              :to="{ name: 'close-cases' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'close-cases' ? 'active' : '']"
                @click="navigate()"
                >Closed Cases
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[activeMenu === 'services-monitoring' ? 'active' : '']"
          v-b-tooltip.hover
          title="Monitoring"
          v-if="hasMonitoringPermission"
        >
          <b-dropdown dropright no-caret>
            <template #button-content>
              <i class="fas fa-desktop"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              Monitoring
            </b-dropdown-header>
            <router-link
              :to="{ name: 'services-monitoring' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'services-monitoring' ? 'active' : '']"
                @click="navigate"
              >
                Services
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[activeMenu === 'devices' ? 'active' : '']"
          v-b-tooltip.hover
          title=" Device Management"
          v-if="hasDeviceManagementPermission"
        >
          <b-dropdown dropright no-caret>
            <template #button-content>
              <i class="fas fa-network-wired"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              Device Management
            </b-dropdown-header>

            <router-link
              :to="{ name: 'devices' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'devices' ? 'active' : '']"
                @click="navigate"
              >
                Devices
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[activeMenu === 'reports' ? 'active' : '']"
          v-b-tooltip.hover
          title="Reports"
          v-if="hasReportsPermission"
        >
          <b-dropdown dropright no-caret>
            <template #button-content>
              <i class="fas fa-file-invoice"></i>
            </template>

            <b-dropdown-header
              v-if="getCurrentScMode === 'SC_DASHBOARD' || 'VMS'"
              id="dropdown-header-label"
            >
              Reports
            </b-dropdown-header>

            <router-link
              v-if="getCurrentScMode === 'SC_DASHBOARD'"
              :to="{ name: 'reports' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'reports' ? 'active' : '']"
                @click="navigate"
                >Reports Type
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasLprReportsPermission"
              :to="{ name: '/reports/lpr/report-on-demand' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate">
                {{ getCurrentScMode === "SC_DASHBOARD" ? "LPR " : "" }} Report
                On Demand
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasVmsSettingsPermission"
              :to="{ name: '/reports/vms/report-on-demand' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate()">
                {{ getCurrentScMode === "SC_DASHBOARD" ? "VMS " : "" }} Report
                On Demand
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasVmsSettingsPermission"
              :to="{ name: '/reports/vms/evacuation-report' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate()"
                >{{ getCurrentScMode === "SC_DASHBOARD" ? "VMS " : "" }}
                Evacuation Report
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasVmsSettingsPermission"
              :to="{ name: '/reports/vms/scheduled-report' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate()"
                >{{ getCurrentScMode === "SC_DASHBOARD" ? "VMS " : "" }}
                Scheduled Report
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasVmsSettingsPermission"
              :to="{ name: '/reports/vms/bulk-import-progress-report' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate()"
                >{{ getCurrentScMode === "SC_DASHBOARD" ? "VMS " : "" }}
                Bulk Import Progress Report
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[
            activeMenu === 'system-logs' || activeMenu === 'audit-logs'
              ? 'active'
              : ''
          ]"
          v-b-tooltip.hover.righttop
          title="Logs"
          v-if="hasLogsPermission"
        >
          <b-dropdown dropright no-caret>
            <template #button-content>
              <i class="fas fa-list-alt"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              Logs
            </b-dropdown-header>

            <router-link
              :to="{ name: 'audit-logs' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'audit-logs' ? 'active' : '']"
                @click="navigate()"
                >Audit Logs
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{ name: 'system-logs' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'system-logs' ? 'active' : '']"
                @click="navigate()"
                >System Logs
              </b-dropdown-item>
            </router-link>
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[
            activeMenu === 'acs/person' ||
            activeMenu === 'users' ||
            activeMenu === 'building_management' ||
            activeMenu === 'license'
              ? 'active'
              : ''
          ]"
          v-b-tooltip.hover.left
          title="Settings"
          v-if="hasSettingsPermission"
        >
          <b-dropdown id="nav_settings" dropright no-caret>
            <template #button-content>
              <i class="fa fa-cog"></i>
            </template>
            <b-dropdown-header id="dropdown-header-label">
              Settings
            </b-dropdown-header>

            <router-link
              v-if="hasSystemSettingsPermission"
              :to="{ name: 'settings/system' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'settings/system' ? 'active' : '']"
                @click="navigate()"
              >
                System
              </b-dropdown-item>
            </router-link>

            <router-link
              v-if="hasVmsSettingsPermission"
              :to="{ name: 'settings/vms' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'settings/vms' ? 'active' : '']"
                @click="navigate()"
              >
                SmartVMS
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{ name: 'users' }"
              v-slot="{ href, route, navigate }"
              custom
              v-if="hasUserManagementPermission"
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'users' ? 'active' : '']"
                @click="navigate"
              >
                {{ sidebarUsersDisplay }}
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{ name: 'acs/person' }"
              v-slot="{ href, route, navigate }"
              custom
              v-if="hasAcsPersonPermission"
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'acs/person' ? 'active' : '']"
                @click="navigate"
                >ACS Persons
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{ name: 'Building List' }"
              v-slot="{ href, route, navigate }"
              custom
              v-if="hasBuildingManagementPermission"
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'building_management' ? 'active' : '']"
                @click="navigate"
                >Buildings
              </b-dropdown-item>
            </router-link>

            <router-link
              :to="{ name: 'settings/workflow' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate"
                >Workflow
              </b-dropdown-item>
            </router-link>

            <!-- <router-link
              :to="{ name: 'license' }"
              v-slot="{ href, route, navigate }"
              custom
              v-if="hasLicensePermission"
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'license' ? 'active' : '']"
                @click="navigate"
                >License
              </b-dropdown-item>
            </router-link> -->
          </b-dropdown>
        </li>

        <li
          class="sidebar-item"
          :class="[activeMenu === 'profile' ? 'active' : '']"
          v-b-tooltip.hover.righttop
          title="Profile"
        >
          <b-dropdown id="nav_user" dropright no-caret>
            <template #button-content>
              <i class="fa fa-user-circle"></i>
            </template>

            <b-dropdown-header id="dropdown-header-label">
              Welcome, {{ this.username }}
            </b-dropdown-header>

            <router-link
              :to="{ name: 'profile' }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item
                :href="href"
                :class="[activeMenu === 'profile' ? 'active' : '']"
                @click="navigate()"
                >View profile
              </b-dropdown-item>
            </router-link>

            <b-dropdown-item-button @click="confirmLogout"
              >Log out
            </b-dropdown-item-button>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import router from "../router";

export default {
  data() {
    return {
      activeMenu: null,
      isExpandMode: false
    };
  },
  computed: {
    ...mapGetters({
      getSharedWorkspaces: "psim/getSharedWorkspaces",
      getWorkspaces: "psim/getWorkspaces",
      getSelectedWorkspace: "psim/getSelectedWorkspace",
      getCurrentUser: "session/getCurrentUser",
      getCurrentScMode: "session/getScMode"
    }),
    sidebarScModeDisplay() {
      switch (this.getCurrentScMode) {
        case "SC_LPR":
          return "LPR";
        case "SC_VMS":
          return "VMS";
        case "SC_CONNECT":
          return "CONNECT";
        default:
          return "CANOPY";
      }
    },
    sidebarUsersDisplay() {
      switch (this.getCurrentScMode) {
        case "SC_CONNECT":
          return "API Users";
        default:
          return "Users";
      }
    },
    username() {
      let username = "";
      if (this.getCurrentUser != null) {
        username = this.getCurrentUser.username;
      }
      return username;
    },
    hasDashboardPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_user"],
        ["dashboard"],
        this.getCurrentScMode,
        ["SC_LPR", "SC_VMS", "SC_DASHBOARD"]
      );
    },
    hasWorkspaceManagementPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_user"],
        ["workspace_management"],
        this.getCurrentScMode,
        []
      );
    },
    hasVmsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_VMS"]
      );
    },
    hasAcsPersonPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_user"],
        [],
        this.getCurrentScMode,
        ["SC_DASHBOARD"]
      );
    },
    hasReportsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_LPR", "SC_VMS"]
      );
    },
    hasLprReportsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_LPR", "SC_DASHBOARD"]
      );
    },
    hasVmsReportsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_VMS", "SC_DASHBOARD"]
      );
    },
    hasMonitoringPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_DASHBOARD"]
      );
    },
    hasDeviceManagementPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_DASHBOARD"]
      );
    },
    hasLogsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        [],
        this.getCurrentScMode,
        ["SC_LPR", "SC_VMS", "SC_DASHBOARD"]
      );
    },
    hasSettingsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin"],
        [],
        this.getCurrentScMode,
        []
      );
    },
    hasServicesSettingsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin", "is_user"],
        [],
        this.getCurrentScMode,
        []
      );
    },
    hasSystemSettingsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin", "is_user"],
        [],
        this.getCurrentScMode,
        []
      );
    },
    hasVmsSettingsPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin", "is_user"],
        [],
        this.getCurrentScMode,
        ["SC_VMS"]
        /* ["SC_VMS", "SC_DASHBOARD"] */
      );
    },
    hasUserManagementPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin", "is_user"],
        ["user_management"],
        this.getCurrentScMode,
        []
      );
    },
    hasBuildingManagementPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin", "is_user"],
        ["building_management"],
        this.getCurrentScMode,
        ["SC_DASHBOARD"]
      );
    },
    hasLicensePermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_admin"],
        [],
        this.getCurrentScMode,
        []
      );
    },
    hasCasesManagementPermission() {
      return this.checkPermission(
        this.getCurrentUser,
        ["is_superuser", "is_admin"],
        ["case_management"],
        this.getCurrentScMode,
        ["SC_LPR", "SC_VMS", "SC_DASHBOARD"]
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        let current_route_name = to.name;

        if (current_route_name === "psim") {
          this.activeMenu = "psim";
        } else if (
          current_route_name === "Workspace List" ||
          current_route_name === "Workspace Create" ||
          current_route_name === "Workspace Edit"
        ) {
          this.activeMenu = "workspace_management";
        } else if (current_route_name === "devices") {
          this.activeMenu = "devices";
        } else if (current_route_name === "reports") {
          this.activeMenu = "reports";
        } else if (current_route_name === "system-logs") {
          this.activeMenu = "system-logs";
        } else if (current_route_name === "audit-logs") {
          this.activeMenu = "audit-logs";
        } else if (current_route_name === "open-cases") {
          this.activeMenu = "open-cases";
        } else if (current_route_name === "close-cases") {
          this.activeMenu = "close-cases";
        } else if (current_route_name === "license") {
          this.activeMenu = "license";
        } else if (
          current_route_name === "Building List" ||
          current_route_name === "Building Detail" ||
          current_route_name === "Floor Plan" ||
          current_route_name === "Floor Plan Editor"
        ) {
          this.activeMenu = "building_management";
        } else if (current_route_name === "users") {
          this.activeMenu = "users";
        } else if (current_route_name === "settings/services") {
          this.activeMenu = "settings/services";
        } else if (current_route_name === "acs/person") {
          this.activeMenu = "acs/person";
        } else if (current_route_name === "profile") {
          this.activeMenu = "profile";
        } else if (current_route_name === "services-monitoring") {
          this.activeMenu = "services-monitoring";
        } else {
          this.activeMenu = null;
        }
      }
    }
  },
  methods: {
    checkPermission(
      currentUser,
      rolesRequired,
      permissionsRequired,
      currentScMode,
      modesAllowed
    ) {
      let flag = false;
      if (currentUser != null) {
        let validRole,
          validPagePermissions,
          validMode = false;

        /** check role is_superuser **/
        if (
          currentUser.is_superuser &&
          rolesRequired.includes("is_superuser")
        ) {
          validRole = true;
        }

        /** check role is_admin **/
        if (currentUser.is_admin && rolesRequired.includes("is_admin")) {
          validRole = true;
        }

        /** normal user **/
        if (rolesRequired.includes("is_user")) {
          validRole = true;
        }

        /** check sc_mode **/
        if (modesAllowed.length === 0) {
          validMode = true;
        } //else if (currentScMode === "SC_DASHBOARD") {
        //validMode = true;
        //}
        else if (modesAllowed.includes(currentScMode)) {
          validMode = true;
        }

        /** check page permissions **/
        const HasPermissions = currentUser.pages;
        validPagePermissions = permissionsRequired.every((val) =>
          HasPermissions.includes(val)
        );

        if (currentUser.is_superuser) {
          flag = true;
        } else if (validRole && validPagePermissions && validMode) {
          flag = true;
        }
      }
      return flag;
    },
    updateSelectedWorkspace: function (workspace) {
      this.$store.dispatch("psim/setSelectedWorkspace", workspace);
      if (
        this.$route.name !== "PSIM"
        /* && this.getCurrentUser.panels.length > 0  */
      ) {
        this.$router.push({ name: "PSIM" }).catch(() => {});
      } /* else {
        this.$router.push({ name: "Error" }).catch(() => {});
      } */
    },
    confirmLogout() {
      // this.$refs['logout-modal'].show()
      this.$bvModal
        .msgBoxConfirm("Do you wish to log out?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            this.doLogout();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    doLogout: function () {
      this.$store.dispatch("session/logoutSession");
    },
    toggleExpandedMode: function () {
      // console.log(sessionStorage.isExpanded);
      this.isExpandMode = sessionStorage.isExpanded === "true";
    },

    navigate() {
      this.$router.go();
    }
  }
};
</script>
