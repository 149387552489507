/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    hosts: {},
    hostsOrder: []
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },

    getHosts: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.hostsOrder) {
        var obj_id = state.hostsOrder[i];
        obj_list.push(state.hosts[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }

      return result;
    },
    getHost: (state, getters) => (id) => {
      var result = state.hosts[id];
      return result;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    ADD_HOST(state, payload) {
      var id = String(payload.id);
      if (state.hostsOrder.indexOf(id) < 0) {
        state.hostsOrder.push(id);
      }
      Vue.set(state.hosts, id, payload);
    },
    UPDATE_HOST(state, payload) {
      var id = String(payload.id);
      var update_data = payload.data;
      var obj = state.hosts[id];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.hosts, id, obj);
    },
    DELETE_HOST(state, id) {
      var id2 = String(id);
      var index = state.hostsOrder.indexOf(id2);
      if (index >= 0) {
        state.hostsOrder.splice(index, 1);
        delete state.hosts[id2];
      }
    },
    RESET_HOST(state) {
      (state.hosts = {}), (state.hostsOrder = []);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addHost: (context, value) => {
      context.commit("ADD_HOST", value);
    },
    updateHost: (context, value) => {
      context.commit("UPDATE_HOST", value);
    },
    deleteHost: (context, value) => {
      context.commit("DELETE_HOST", value);
    },
    resetHost: (context) => {
      context.commit("RESET_HOST");
    }
  }
};
