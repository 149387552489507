import moment from "moment";
const sodium = require("libsodium-wrappers");

export function secondsToHms(d, show_seconds) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes") : "";
  var sDisplay = "";
  if (show_seconds == true) {
    var secondsDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    if (secondsDisplay != "") {
      sDisplay = ", " + secondsDisplay;
    } else {
      sDisplay = "";
    }
  }
  return hDisplay + mDisplay + sDisplay;
}

export function formatDateObj(d) {
  var dateDisplay = d.getDate().toString().padStart(2, "0");
  var monthDisplay = d.getMonth().toString().padStart(2, "0");
  var hourDisplay = d.getHours().toString().padStart(2, "0");
  var minDisplay = d.getMinutes().toString().padStart(2, "0");
  var secDisplay = d.getSeconds().toString().padStart(2, "0");
  var dtDisplay =
    dateDisplay +
    "/" +
    monthDisplay +
    "/" +
    d.getFullYear() +
    " " +
    hourDisplay +
    ":" +
    minDisplay +
    ":" +
    secDisplay;
  // console.log(dtDisplay);
  return dtDisplay;
}

export function formatISODate(isodate) {
  var replaced_isodate = isodate.replace("+00:00", "Z");
  var d = new Date(replaced_isodate);
  var dateDisplay = d.getDate().toString().padStart(2, "0");
  var monthDisplay = d.getMonth().toString().padStart(2, "0");
  var hourDisplay = d.getHours().toString().padStart(2, "0");
  var minDisplay = d.getMinutes().toString().padStart(2, "0");
  var secDisplay = d.getSeconds().toString().padStart(2, "0");
  var dtDisplay =
    dateDisplay +
    "/" +
    monthDisplay +
    "/" +
    d.getFullYear() +
    " " +
    hourDisplay +
    ":" +
    minDisplay +
    ":" +
    secDisplay;
  // console.log(dtDisplay);
  return dtDisplay;
}

export function formatTimestamp(ts) {
  var d = new Date(ts);
  var dateDisplay = d.getDate().toString().padStart(2, "0");
  var monthDisplay = d.getMonth().toString().padStart(2, "0");
  var hourDisplay = d.getHours().toString().padStart(2, "0");
  var minDisplay = d.getMinutes().toString().padStart(2, "0");
  var secDisplay = d.getSeconds().toString().padStart(2, "0");
  var dtDisplay =
    dateDisplay +
    "/" +
    monthDisplay +
    "/" +
    d.getFullYear() +
    " " +
    hourDisplay +
    ":" +
    minDisplay +
    ":" +
    secDisplay;
  // console.log(dtDisplay);
  return dtDisplay;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function searchInDict(dictObj, dictKey, dictValue) {
  var splitKeys = dictKey.split("__");
  var firstKey = splitKeys.shift();
  var nextKey = splitKeys.length > 0 ? splitKeys[0] : null;
  var result = null;

  if (dictObj[firstKey] != undefined) {
    if (dictObj[firstKey].constructor == Object) {
      var joinedKeys = splitKeys.join("__");

      result = searchInDict(dictObj[firstKey], joinedKeys, dictValue);
      return result;
    } else {
      if (nextKey == "contains") {
        // search if array contains dictValue
        result = dictObj[firstKey].indexOf(dictValue) >= 0 ? true : false;
      } else if (nextKey == "includes") {
        // search if string includes dictValue
        result = dictObj[firstKey].includes(dictValue);
      } else if (nextKey == "in") {
        // search if dictValue is in array
        result = dictValue.indexOf(dictObj[firstKey]) >= 0 ? true : false;
      } else if (nextKey == "gte") {
        // check datetime is greater than or equal to dictValue
        var check_gte = moment(dictObj[firstKey]).isAfter(moment(dictValue));
        result = check_gte;
      } else if (nextKey == "lte") {
        // check datetime is greater than or equal to dictValue
        var check_lte = moment(dictObj[firstKey]).isBefore(moment(dictValue));
        result = check_lte;
      } else {
        // check if element is equal to dictValue
        result = dictObj[firstKey] == dictValue ? true : false;
      }
      return result;
    }
  }
  return false;
}

export function cleanBase64ImgSrc(imgstr, mimetype) {
  // console.log("imgstr is " + imgstr);
  if (imgstr == "" || imgstr == undefined || imgstr == null) {
    return imgstr;
  }

  if (mimetype == "" || mimetype == undefined || mimetype == null) {
    mimetype = "image/jpeg";
  }

  var has_prefix = imgstr.includes("data:image/");
  var is_http_url = imgstr.includes("http://");
  var is_https_url = imgstr.includes("https://");
  var base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  var is_base64 = base64regex.test(imgstr);

  if (is_base64 && !has_prefix && !is_http_url && !is_https_url) {
    return "data:" + mimetype + ";base64," + imgstr;
  } else {
    return imgstr;
  }
}

export function titleCase(str, split_str) {
  var skipped_words = [
    "from",
    "in",
    "the",
    "a",
    "an",
    "and",
    "but",
    "or",
    "for",
    "nor"
  ];
  var result = str
    .toLowerCase()
    .split(split_str)
    .map(function (word, index) {
      if (index == 0) {
        return word.replace(word[0], word[0].toUpperCase());
      } else {
        if (skipped_words.indexOf(word) >= 0) {
          return word.toLowerCase();
        } else {
          return word.replace(word[0], word[0].toUpperCase());
        }
      }
    })
    .join(" ");
  return result;
}

export function canopy_decoder(data, token) {
  /*
        Helper to decode canopy encryption.
    */

  // console.log(data)
  // console.log(token)
  data = sodium.from_hex(data);
  token = sodium.from_hex(token);

  let nonce = data.slice(0, sodium.crypto_secretbox_NONCEBYTES),
    ciphertext = data.slice(sodium.crypto_secretbox_NONCEBYTES);

  data = sodium.crypto_secretbox_open_easy(ciphertext, nonce, token);

  return sodium.to_string(data);
}
