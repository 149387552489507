<template>
  <div id="app" class="page-wrapper">
    <TheSidebar
      v-if="this.getCurrentUser !== null && !this.isKiosk"
      ref="sidebar"
    ></TheSidebar>

    <div class="page-content" :class="[isExpanded ? 'expand-mode' : '']">
      <div class="global-alert-container">
        <b-alert
          v-for="alert in alertQueue"
          :key="'alert_' + alert.id"
          :variant="alert.message_type"
          :show="alert.dismiss_countdown"
          fade
          dismissible
          @dismissed="alert.dismiss_countdown = 0"
          @dismiss-count-down="countDownChanged"
          class="global-alert"
        >
          {{ alert.message_text }}
        </b-alert>
      </div>

      <div id="overlay" class="overlay"></div>

      <main
        role="main"
        class="page-main"
        :class="[isExpanded ? 'expand-mode' : '']"
      >
        <div class="page-body" :class="[isExpanded ? 'expand-mode' : '']">
          <router-view ref="pageRouterView"></router-view>
        </div>
      </main>

      <!-- Footer -->
      <TheFooter
        v-if="this.getCurrentUser !== null && !this.isKiosk"
        ref="footer"
      ></TheFooter>
    </div>

    <div
      v-if="this.getCurrentUser !== null && !this.isKiosk"
      class="btn-expand-container"
      :class="[isExpanded ? 'expand-mode' : '']"
      v-b-tooltip.hover.right
      :title="expandModeText"
    >
      <span
        :class="[isExpanded ? 'btn-collapse' : 'btn-expand']"
        ref="expandButton"
        @click="toggleExpand"
        ><svg
          width="22"
          height="22"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1650 288q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23z"
          />
          <path
            d="M896 960v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z"
          /></svg
      ></span>
    </div>
  </div>
</template>

<!-- App JS -->
<script>
import { mapGetters } from "vuex";
import TheSidebar from "./components/TheSidebar.vue";
import TheFooter from "./components/TheFooter.vue";

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "App",
  components: {
    TheSidebar,
    TheFooter
  },
  data() {
    let isExpanded = true;
    let isKiosk = false;

    return {
      alertQueue: [],
      currentUser: null,
      isExpanded: isExpanded,
      isKiosk: isKiosk,

      expandModeText: "Expand view",

      /** setting **/
      setting: null,

      /** dark mode **/
      darkMode: false
    };
  },
  computed: {
    ...mapGetters({
      getGlobalAlertMessage: "session/getGlobalAlertMessage",
      getAccessToken: "session/getAccessToken",
      secureToken: "session/secureToken",
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getWSServerURL: "session/getWSServerURL",
      isBuildingsInitiated: "psim/isBuildingsInitiated"
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        let title = "";
        if (to.meta && to.meta.title) {
          title = "| " + to.meta.title;
        }
        document.title = "Smart Canopy " + title;
      }
    },
    getGlobalAlertMessage: {
      handler: function (n, o) {
        var $this = this;
        if (n != null) {
          var msg_list = [];
          for (var _n in n) {
            msg_list.push(n[_n]);
          }
          var results = msg_list.filter(function (item) {
            for (var i in $this.alertQueue) {
              var msg = $this.alertQueue[i];
              if (msg.id == item.id) {
                return false;
              }
            }
            return true;
          });

          if (results.length > 0) {
            for (var r in results) {
              results[r]["dismiss_countdown"] = 5;
              $this.alertQueue.push(results[r]);
            }
          }
        }
      },
      deep: true
    },
    getCurrentUser: async function (n, o) {
      if (n !== o) {
        console.log("App watch getCurrentUser n");
        console.log(n);

        try {
          let response2 = await this.getAllRoles();

          let rolesList = response2.result;

          let roleObj = rolesList.find((role) => {
            return role.id === n.role_id;
          });

          if (roleObj !== null && roleObj !== undefined) {
            if (roleObj.name === "VMS Kiosk") {
              this.isExpanded = true;
              this.isKiosk = true;

              if (this.isKiosk) {
                await this.$router.push({ name: "kiosk2" });
              }
            } else {
              this.isExpanded = false;
              await this.getAllSettings();
            }
          } else {
            this.isExpanded = false;
            this.isKiosk = false;
          }
        } catch (e) {
          this.isExpanded = false;
          this.isKiosk = false;

          console.log(e);
        }
      }
    },

    darkMode: function () {
      let htmlElement = document.documentElement;
      if (this.darkMode) {
        localStorage.setItem("theme", "dark");
        htmlElement.setAttribute("data-theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
        htmlElement.setAttribute("data-theme", "light");
      }
    }
  },
  mounted() {
    console.log("APP Mounted");
    console.info("App version " + this.$store.state.app_version);

    let htmlElement = document.documentElement;

    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      htmlElement.setAttribute("data-theme", "dark");
      this.darkMode = true;
    } else {
      htmlElement.setAttribute("data-theme", "light");
      this.darkMode = false;
    }

    // htmlElement.setAttribute('theme', 'dark');
    // htmlElement.setAttribute('data-theme', 'light');
  },
  updated() {
    if (this.$refs !== undefined) {
      if (this.$refs.pageRouterView !== undefined) {
        if (
          this.$refs.pageRouterView.componentName !== undefined &&
          this.$refs.pageRouterView.componentName === "PSIM"
        ) {
          this.$refs.pageRouterView.calculateRowHeight();
        }
      }
    }
  },
  methods: {
    toggleExpand: function (e) {
      var $this = this;
      this.isExpanded = !this.isExpanded;
      sessionStorage.isExpanded = this.isExpanded;

      this.$refs.sidebar.toggleExpandedMode();
      this.$refs.footer.toggleExpandedMode();

      if (this.isExpanded) {
        this.expandModeText = "Normal view";
      } else {
        this.expandModeText = "Expand view";
      }
      if (
        this.$refs.pageRouterView.componentName !== undefined &&
        this.$refs.pageRouterView.componentName === "PSIM"
      ) {
        $this.$refs.pageRouterView.calculateRowHeight();
      }
    },
    countDownChanged(countdown) {
      var $this = this;
      var max_queue_length = 20;
      if (countdown <= 0 && this.alertQueue.length > max_queue_length) {
        while (this.alertQueue.length > max_queue_length) {
          var firstElement = $this.alertQueue.shift();
          $this.$store.dispatch(
            "session/removeGlobalAlertMessage",
            firstElement.id
          );
        }
      }
    },
    async getAllRoles() {
      try {
        let url = this.getAPIServerURL + "/roles/";
        const client = this.$root.getAjaxFetchClient();
        return await client.getRequest(url);
      } catch (e) {
        console.log(e);
      }
    },
    async getAllSettings() {
      try {
        let that = this;

        let API_URL = this.getAPIServerURL + "/settings/all/";
        const client = this.$root.getAjaxFetchClient();
        let response = await client.getRequest(API_URL);

        if (response.result && response.result.length !== 0) {
          let setting;

          for (let i = 0; i < response.result.length; i++) {
            setting = response.result[i];

            try {
              if (
                Object.prototype.hasOwnProperty.call(setting, "service_setting")
              ) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    setting["service_setting"],
                    "api_and_ws"
                  )
                ) {
                  let setting = response.result[i];
                  let apiserver_url =
                    setting["service_setting"]["api_and_ws"]["apiserver_url"];
                  let websocket_url =
                    setting["service_setting"]["api_and_ws"]["websocket_url"];

                  await that.$store.dispatch(
                    "session/setAPIServerURL",
                    apiserver_url
                  );
                  await localStorage.setItem("apiserverURL", apiserver_url);

                  await that.$store.dispatch(
                    "session/setWSServerURL",
                    websocket_url
                  );
                  await localStorage.setItem("wsserverURL", websocket_url);

                  // location.reload();

                  break;
                }
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      } catch (err) {
        console.log("get setting err in APP", err);
      }
    },
    editSetting(data) {
      let url = this.getAPIServerURL + "/settings/?uid=" + data.id;

      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(async () => {
          await this.getAllSettings();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/global/scss/sc_global.scss";
</style>
