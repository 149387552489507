/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {},
  // -----------------------------------------------------------------
  getters: {
    getPreferences: (state) => {
      return state;
    },

    operatorPresetCredential: (state) => {
      let output = [];

      if (state.additional_metadata.vms_credential_operator_physical_card) {
        output.push("card");
      }

      if (
        state.additional_metadata.vms_credential_operator_dynamic_qr ||
        state.additional_metadata.vms_credential_operator_static_qr
      ) {
        output.push("qr");
      }

      if (state.additional_metadata.vms_credential_operator_face_recognition) {
        output.push("face");
      }

      if (
        state.additional_metadata
          .vms_credential_operator_license_plate_recognition
      ) {
        output.push("car_plate_no");
      }

      if (state.additional_metadata.vms_credential_operator_fingerprint) {
        output.push("fingerprint");
      }

      if (
        state.additional_metadata
          .vms_credential_operator_lift_access_physical_card ||
        state.additional_metadata
          .vms_credential_operator_lift_access_static_qr ||
        state.additional_metadata
          .vms_credential_operator_lift_access_dynamic_qr ||
        state.additional_metadata
          .vms_credential_operator_lift_access_face_recognition ||
        state.additional_metadata
          .vms_credential_operator_lift_access_license_plate_recognition ||
        state.additional_metadata
          .vms_credential_operator_lift_access_fingerprint
      ) {
        output.push("lift_card");
      }

      return output;
    },

    kioskPresetCredential: (state) => {
      let output = [];

      if (state.additional_metadata.vms_credential_kiosk_physical_card) {
        output.push("card");
      }

      if (
        state.additional_metadata.vms_credential_kiosk_dynamic_qr ||
        state.additional_metadata.vms_credential_kiosk_static_qr
      ) {
        output.push("qr");
      }

      if (state.additional_metadata.vms_credential_kiosk_face_recognition) {
        output.push("face");
      }

      if (
        state.additional_metadata.vms_credential_kiosk_license_plate_recognition
      ) {
        output.push("car_plate_no");
      }

      if (state.additional_metadata.vms_credential_kiosk_fingerprint) {
        output.push("fingerprint");
      }

      if (
        state.additional_metadata
          .vms_credential_kiosk_lift_access_physical_card ||
        state.additional_metadata.vms_credential_kiosk_lift_access_static_qr ||
        state.additional_metadata.vms_credential_kiosk_lift_access_dynamic_qr ||
        state.additional_metadata
          .vms_credential_kiosk_lift_access_face_recognition ||
        state.additional_metadata
          .vms_credential_kiosk_lift_access_license_plate_recognition ||
        state.additional_metadata.vms_credential_kiosk_lift_access_fingerprint
      ) {
        output.push("lift_card");
      }

      return output;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    ADD_PREFERENCE(state, payload) {
      Vue.set(state, "visit_start_time", payload.visit_start_time);
      Vue.set(state, "visit_end_time", payload.visit_end_time);
      Vue.set(state, "tolerance_time_in", payload.tolerance_time_in);
      Vue.set(state, "tolerance_time_out", payload.tolerance_time_out);
      Vue.set(
        state,
        "forms_compulsory_fields",
        payload.forms_compulsory_fields
      );
      Vue.set(state, "forms", payload.forms);
      Vue.set(state, "additional_metadata", payload.additional_metadata);
      Vue.set(state, "tnc_metadata", payload.tnc_metadata);
      Vue.set(state, "id", payload.id);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    getAllPreferences: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL =
        apiserverURL + "/api/visitorservice/visit_preference/all/";

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client.getRequest(API_URL).then((data) => {
          let validUrl = "";
          let result = data.result;

          if (result !== undefined) {
            outer_loop: for (let key in result) {
              if (result[key] !== undefined && Array.isArray(result[key])) {
                validUrl = key;
                break outer_loop;
              }
            }

            if (
              result[validUrl] !== undefined &&
              Array.isArray(result[validUrl])
            ) {
              if (result[validUrl].length > 0) {
                commit("ADD_PREFERENCE", result[validUrl][0]);
              }
            }
          } else {
            let message = {
              message_text: data.detail + " gets preferences",
              message_type: "danger"
            };

            console.log("message", message);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    createPreferences: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/visitorservice/visit_preference/";

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      console.log("post value", value);
      try {
        client.postRequest(API_URL, value).then((data) => {
          if (data.detail) {
            let message = {
              message_text: data.detail + " create preferences",
              message_type: "danger"
            };

            console.log("message", message);
          } else {
            console.log("data post", data);
          }

          setTimeout(() => {
            dispatch("getAllPreferences");
          }, 1000);
        });
      } catch (e) {
        console.log(e);
      }
    },
    updatePreferences: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL =
        apiserverURL +
        "/api/visitorservice/visit_preference/?visit_preference_id=" +
        state.id;

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      let additional_metadata = {
        ...value.additional_metadata,
        public: {
          national_id_type: {
            HKG: ["HKID", "Passport"],
            IDN: ["KTP", "SIM", "Passport"],
            MYS: ["MyKAD", "Passport"],
            SGP: ["NRIC", "Passport"]
          },
          vms_preferences_default_visitor_type:
            value.additional_metadata.vms_preferences_default_visitor_type,
          vms_public_welcome_message:
            value.additional_metadata.public.vms_public_welcome_message,
          vms_public_branding_image:
            value.additional_metadata.public.vms_public_branding_image,
          vms_registration_visitor_profile_picture_required:
            value.additional_metadata
              .vms_registration_visitor_profile_picture_required,

          vms_public_default_duration:
            value.additional_metadata.public.vms_public_default_duration,
          vms_public_visit_start_time:
            value.additional_metadata.public.vms_public_visit_start_time,
          vms_public_visit_end_time:
            value.additional_metadata.public.vms_public_visit_end_time,
          vms_public_duration_hours:
            value.additional_metadata.public.vms_public_duration_hours,
          vms_public_visit_end_time_only:
            value.additional_metadata.public.vms_public_visit_end_time_only,
          vms_public_duration_days:
            value.additional_metadata.public.vms_public_duration_days,

          vms_pdpa_setting: value.additional_metadata.vms_pdpa_setting,
          vms_host_invite_check_in_auto:
            value.additional_metadata.vms_host_invite_check_in_auto,
          vms_credential_operator_physical_card:
            value.additional_metadata.vms_credential_operator_physical_card,
          vms_credential_operator_static_qr:
            value.additional_metadata.vms_credential_operator_static_qr,
          vms_credential_operator_dynamic_qr:
            value.additional_metadata.vms_credential_operator_dynamic_qr,
          vms_credential_operator_face_recognition:
            value.additional_metadata.vms_credential_operator_face_recognition,
          vms_preferences_access_level:
            value.additional_metadata.vms_preferences_access_level,
          vms_preferences_access_groups:
            value.additional_metadata.vms_preferences_access_groups,

          vms_credential_operator_lift_access_physical_card:
            value.additional_metadata
              .vms_credential_operator_lift_access_physical_card,
          vms_credential_operator_lift_access_static_qr:
            value.additional_metadata
              .vms_credential_operator_lift_access_static_qr,
          vms_credential_operator_lift_access_dynamic_qr:
            value.additional_metadata
              .vms_credential_operator_lift_access_dynamic_qr,
          vms_credential_operator_lift_access_face_recognition:
            value.additional_metadata
              .vms_credential_operator_lift_access_face_recognition,

          // new addition
          vms_credential_operator_license_plate_recognition:
            value.additional_metadata
              .vms_credential_operator_license_plate_recognition,
          vms_credential_operator_fingerprint:
            value.additional_metadata.vms_credential_operator_fingerprint,

          vms_credential_operator_lift_access_license_plate_recognition:
            value.additional_metadata
              .vms_credential_operator_lift_access_license_plate_recognition,
          vms_credential_operator_lift_access_fingerprint:
            value.additional_metadata
              .vms_credential_operator_lift_access_fingerprint
        }
      };

      value = {
        ...value,
        additional_metadata: additional_metadata
      };

      console.log("value", value);

      try {
        client.putRequest(API_URL, value).then((data) => {
          if (data.detail) {
            let message = {
              message_text: data.detail + " update preferences",
              message_type: "danger"
            };
            console.log("message", message);
          } else {
            console.log("update post", data);
          }
          setTimeout(() => {
            dispatch("getAllPreferences");
          }, 1000);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
