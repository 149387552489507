/** VUEX module for log WS data **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");

let auditlogStates = {
  audit: {},
  auditOrder: []
};

let copiedAuditLogStates = JSON.parse(JSON.stringify(auditlogStates));

export default {
  namespaced: true,
  state: copiedAuditLogStates,
  getters: {
    getAuditLogs: (state) => {
      var auditlogs_list = [];
      for (var s in state.auditOrder) {
        var auditlog_id = String(state.auditOrder[s]);
        auditlogs_list.push(state.audit[auditlog_id]);
      }
      return auditlogs_list;
    }
  },
  mutations: {
    ADD_AUDITLOG(state, payload) {
      var log_id = String(payload.id);
      var log_obj = payload.data;
      if (state.auditOrder.indexOf(log_id) < 0) {
        state.auditOrder.push(log_id);
      }
      Vue.set(state.audit, log_id, log_obj);
    },

    RESET_STORE(state) {
      state.audit = {};
      state.auditOrder = [];
    }
  },
  actions: {
    addAuditLog(context, value) {
      context.commit("ADD_AUDITLOG", value);
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    }
  }
};
