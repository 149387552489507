/** VUEX module for alert WS data **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");

let alertStates = {
  intervalId: null,
  rawAlerts: {},
  rawAlertsOrder: [],
  alerts: {},
  alertsOrder: [],
  discardedAlertIDs: [],
  updatedAlerts: {},
  updatedAlertsOrder: []
};

let copiedAlertStates = JSON.parse(JSON.stringify(alertStates));

export default {
  namespaced: true,
  state: copiedAlertStates,
  getters: {
    getIntervalId: (state) => {
      return state.intervalId;
    },
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },
    // ---- Raw Alerts ----
    getRawAlertIDs: (state) => {
      return state.rawAlertsOrder;
    },
    getRawAlerts: (state) => {
      var alerts_list = [];
      for (var s in state.rawAlertsOrder) {
        var alert_id = String(state.rawAlertsOrder[s]);
        alerts_list.push(state.rawAlerts[alert_id]);
      }
      return alerts_list;
    },
    getRawAlert: (state) => (alert_id) => {
      return state.rawAlerts[String(alert_id)];
    },
    // ---- Alerts ----
    getAlertIDs: (state) => {
      return state.alertsOrder;
    },
    getAlerts: (state) => {
      var alerts_list = [];
      for (var s in state.alertsOrder) {
        var alert_id = String(state.alertsOrder[s]);
        alerts_list.push(state.alerts[alert_id]);
      }
      return alerts_list;
    },
    getAlertsDict: (state) => {
      return state.alerts;
    },
    filterAlerts: (state, getters) => (filters) => {
      var alerts_list = getters.getAlerts;
      // console.log(alerts_list);
      return getters._filter(alerts_list, filters);
    },
    getAlert: (state) => (alert_id) => {
      return state.alerts[String(alert_id)];
    },
    getUpdatedAlertIDs: (state) => {
      return state.updatedAlertsOrder;
    },
    getUpdatedAlerts: (state) => {
      var alerts_list = [];
      for (var s in state.updatedAlertsOrder) {
        var alert_id = String(state.updatedAlertsOrder[s]);
        alerts_list.push(state.updatedAlerts[alert_id]);
      }
      return alerts_list;
    },
    getUpdatedAlertsDict: (state) => {
      return state.updatedAlerts;
    },
    filterUpdatedAlerts: (state, getters) => (filters) => {
      var alerts_list = getters.getUpdatedAlerts;
      return getters._filter(alerts_list, filters);
    },
    getUpdatedAlert: (state) => (alert_id) => {
      return state.updatedAlerts[String(alert_id)];
    },
    getDiscardedAlertIDs: (state) => {
      return state.discardedAlertIDs;
    }
  },
  mutations: {
    ADD_RAW_ALERT(state, payload) {
      var alert_id = String(payload.id);
      var alert_obj = payload.data;

      if (state.rawAlertsOrder.indexOf(alert_id) < 0) {
        state.rawAlertsOrder.push(alert_id);
      }
      // state.rawAlerts[alert_id] = alert_obj;
      Vue.set(state.rawAlerts, alert_id, alert_obj);
    },
    ADD_ALERT(state, payload) {
      var alert_id = String(payload.id);
      var alert_obj = payload.data;
      if (state.alertsOrder.indexOf(alert_id) < 0) {
        state.alertsOrder.push(alert_id);
      }
      // state.alerts[alert_id] = alert_obj;
      Vue.set(state.alerts, alert_id, alert_obj);
    },
    REMOVE_ALERT(state, alert_id) {
      let alert_id_str = String(alert_id);
      var alert_index = state.alertsOrder.indexOf(alert_id_str);
      if (alert_index > 0) {
        try {
          state.alertsOrder.splice(alert_index, 1);
          delete state.alerts[alert_id_str];
        } catch (err) {
          // console.log(err);
        }
      }
    },
    DISCARD_ALERT(state, alert_id) {
      let alert_id_str = String(alert_id);
      var alert = state.alerts[alert_id_str];
      // console.log("Discarding", alert_id_str, alert)
      if (alert != undefined) {
        alert.is_discarded = true;
        Vue.set(state.alerts, alert_id_str, alert);
        // console.log("Discarded from alertWSStore", state.alerts[alert_id_str].is_discarded);
        state.discardedAlertIDs.push(alert_id_str);
      }
    },
    ADD_UPDATED_ALERT(state, payload) {
      var alert_id = String(payload.id);
      var alert_obj = payload.data;
      if (state.updatedAlertsOrder.indexOf(alert_id) < 0) {
        state.updatedAlertsOrder.push(alert_id);
      }

      Vue.set(state.updatedAlerts, alert_id, alert_obj);
    },
    REMOVE_UPDATED_ALERT(state, alert_id) {
      let alert_id_str = String(alert_id);
      var alert_index = state.updatedAlertsOrder.indexOf(alert_id_str);
      if (alert_index > 0) {
        try {
          state.updatedAlertsOrder.splice(alert_index, 1);
          delete state.updatedAlerts[alert_id_str];
        } catch (err) {
          // console.log(err);
        }
      }
    },
    ALERT_HOUSEKEEPING(state) {
      var max_alerts = 10000;
      if (state.alertsOrder.length > max_alerts) {
        while (state.alertsOrder.length > max_alerts) {
          var removed_alert_id = state.alertsOrder.shift();
          try {
            delete state.alerts[String(removed_alert_id)];
            delete state.rawAlerts[String(removed_alert_id)];
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    UPDATED_ALERT_HOUSEKEEPING(state) {
      var max_alerts = 10000;
      if (state.updatedAlertsOrder.length > max_alerts) {
        while (state.updatedAlertsOrder.length > max_alerts) {
          var removed_alert_id = state.updatedAlertsOrder.shift();
          try {
            delete state.updatedAlerts[String(removed_alert_id)];
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    RESET_STORE(state) {
      // console.log("Reset Store");
      let originalStates = JSON.parse(JSON.stringify(alertStates));
      for (var s in originalStates) {
        Vue.set(state, s, originalStates[s]);
        // console.log("state ", s, " = ", state[s]);
      }
    },
    UPDATE_INTERVAL_ID(state, payload) {
      state.intervalId = payload;
    },
    RESET_ALERT(state) {
      state.rawAlertsOrder = [];
      state.rawAlerts = {};
      state.alertsOrder = [];
      state.alerts = {};
    }
  },
  actions: {
    addRawAlert(context, value) {
      // console.log(value);
      context.commit("ADD_RAW_ALERT", value);
      context.commit("ALERT_HOUSEKEEPING");
    },
    addAlert(context, value) {
      // console.log(value);
      context.commit("ADD_ALERT", value);
      context.commit("ALERT_HOUSEKEEPING");
    },
    discardAlert(context, alert_id) {
      context.commit("DISCARD_ALERT", alert_id);
    },
    addUpdatedAlert(context, value) {
      // console.log(value);
      context.commit("ADD_UPDATED_ALERT", value);
      context.commit("UPDATED_ALERT_HOUSEKEEPING");
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    },
    updateIntervalId: (context, value) => {
      context.commit("UPDATE_INTERVAL_ID", value);
    },
    resetAlert: (context) => {
      context.commit("RESET_ALERT");
    }
  }
};
