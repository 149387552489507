/** VUEX module for device components **/
import Vue from "vue";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

let utils = require("@/assets/global/js/utils.js");

var deviceStates = {
  viewingPopup: null,
  viewingDetails: null,
  cameras: {},
  camerasOrder: [],
  controllers: {},
  controllersOrder: [],
  doors: {},
  doorsOrder: [],
  readers: {},
  readersOrder: [],
  inputs: {},
  inputsOrder: [],
  initDevices: false,
  devicesPollingInterval: null
};
let copiedDeviceStates = JSON.parse(JSON.stringify(deviceStates));

function generateDeviceId(controller_url, type, no) {
  return controller_url + "_" + type + "_" + no;
}

function generateDeviceName(controller_name, type, no) {
  return controller_name + " (" + type + " " + no + ")";
}

function findControllerSubDevicesByType(controller, type, data) {
  let subDeviceList = [];
  for (let i = 0; i < data.length; i++) {
    let subDevice = data[i];
    let subDeviceData = {
      id: generateDeviceId(controller.id, type, subDevice[type + "_no"]),
      ip: controller.ip,
      type: type,
      status: controller.status,
      data: {
        server_url: controller.data.server_url,
        details: subDevice,
        meta: null
      }
    };
    subDeviceList.push(subDeviceData);
  }
  return subDeviceList;
}

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: copiedDeviceStates,
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },
    _get: (state) => (objlist, object_id) => {
      var result = null;
      if (object_id != null && object_id != undefined) {
        for (var i in objlist) {
          var item = objlist[i];
          if (item.id == object_id) {
            result = item;
            break;
          }
        }
      }

      return result;
    },
    getViewingPopup: (state) => {
      return state.viewingPopup;
    },
    isDevicesInitiated: (state) => {
      return state.initDevices;
    },
    getCameras: (state) => {
      return state.cameras;
    },
    filterCameras: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.camerasOrder) {
        var obj_id = state.camerasOrder[i];
        obj_list.push(state.cameras[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getCamera: (state) => (device_id) => {
      return state.cameras[device_id];
    },
    getControllers: (state) => {
      return state.controllers;
    },
    filterControllers: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.controllersOrder) {
        var obj_id = state.controllersOrder[i];
        obj_list.push(state.controllers[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getController: (state) => (device_id) => {
      // console.log("getter getController device_id");
      // console.log(device_id);
      return state.controllers[device_id];
    },
    getDoors: (state) => {
      return state.doors;
    },
    filterDoors: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.doorsOrder) {
        var obj_id = state.doorsOrder[i];
        obj_list.push(state.doors[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getDoor: (state) => (device_id) => {
      return state.doors[device_id];
    },
    getReaders: (state) => {
      return state.readers;
    },
    filterReaders: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.readersOrder) {
        var obj_id = state.readersOrder[i];
        obj_list.push(state.readers[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getReader: (state) => (device_id) => {
      return state.readers[device_id];
    },
    getInputs: (state) => {
      return state.inputs;
    },
    filterInputs: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.inputsOrder) {
        var obj_id = state.inputsOrder[i];
        obj_list.push(state.inputs[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getInput: (state) => (device_id) => {
      return state.inputs[device_id];
    },
    getDevicesPollingInterval: (state) => {
      return state.devicesPollingInterval;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    setState(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    SET_VIEWING_POPUP(state, payload) {
      if (payload == null) {
        state.viewingPopup = payload;
      } else {
        state.viewingPopup = {};
        Vue.set(state.viewingPopup, "type", payload.type);
        Vue.set(state.viewingPopup, "id", payload.id);
      }
    },
    SET_DEVICES_INITIATED(state, payload) {
      state.initDevices = payload;
    },
    ADD_CAMERA(state, payload) {
      var device_id = String(payload.id);
      var device_data = payload;
      if (state.camerasOrder.indexOf(device_id) < 0) {
        state.camerasOrder.push(device_id);
      } else {
        device_data = JSON.parse(JSON.stringify(state.cameras[device_id]));
        for (var i in payload) {
          device_data[i] = payload[i];
        }
      }
      Vue.set(state.cameras, device_id, device_data);
    },
    ADD_CONTROLLER(state, payload) {
      var device_id = String(payload.id);
      var device_data = payload;
      if (state.controllersOrder.indexOf(device_id) < 0) {
        state.controllersOrder.push(device_id);
      } else {
        device_data = JSON.parse(JSON.stringify(state.controllers[device_id]));
        for (var i in payload) {
          device_data[i] = payload[i];
        }
      }
      // console.log(payload);
      Vue.set(state.controllers, device_id, device_data);
    },
    ADD_DOOR(state, payload) {
      var device_id = String(payload.id);
      var device_data = payload;
      if (state.doorsOrder.indexOf(device_id) < 0) {
        state.doorsOrder.push(device_id);
      } else {
        device_data = JSON.parse(JSON.stringify(state.doors[device_id]));
        for (var i in payload) {
          device_data[i] = payload[i];
        }
      }
      // console.log(payload);
      Vue.set(state.doors, device_id, device_data);
    },
    ADD_READER(state, payload) {
      var device_id = String(payload.id);
      var device_data = payload;
      if (state.readersOrder.indexOf(device_id) < 0) {
        state.readersOrder.push(device_id);
      } else {
        device_data = JSON.parse(JSON.stringify(state.readers[device_id]));
        for (var i in payload) {
          device_data[i] = payload[i];
        }
      }
      // console.log(payload);
      Vue.set(state.readers, device_id, device_data);
    },
    ADD_INPUT(state, payload) {
      var device_id = String(payload.id);
      var device_data = payload;
      if (state.inputsOrder.indexOf(device_id) < 0) {
        state.inputsOrder.push(device_id);
      } else {
        device_data = JSON.parse(JSON.stringify(state.inputs[device_id]));
        for (var i in payload) {
          device_data[i] = payload[i];
        }
      }
      // console.log(payload);
      Vue.set(state.inputs, device_id, device_data);
    },
    UPDATE_CAMERA(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      let device_obj;

      if (state.cameras[device_id] != undefined) {
        device_obj = JSON.parse(JSON.stringify(state.cameras[device_id]));
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      } else {
        device_obj = device_update_data;
      }
      Vue.set(state.cameras, device_id, device_obj);
      // console.log(device_obj);
    },
    UPDATE_CONTROLLER(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      let device_obj;

      if (state.controllers[device_id] != undefined) {
        device_obj = JSON.parse(JSON.stringify(state.controllers[device_id]));
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      } else {
        device_obj = device_update_data;
      }
      Vue.set(state.controllers, device_id, device_obj);
    },
    UPDATE_DOOR(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      let device_obj;

      if (state.doors[device_id] != undefined) {
        device_obj = JSON.parse(JSON.stringify(state.doors[device_id]));
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      } else {
        device_obj = device_update_data;
      }
      // console.log(device_obj)
      Vue.set(state.doors, device_id, device_obj);
    },
    UPDATE_READER(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      let device_obj;

      if (state.readers[device_id] != undefined) {
        device_obj = JSON.parse(JSON.stringify(state.readers[device_id]));
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      } else {
        device_obj = device_update_data;
      }
      Vue.set(state.readers, device_id, device_obj);
    },
    UPDATE_INPUT(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      let device_obj;

      if (state.inputs[device_id] != undefined) {
        device_obj = JSON.parse(JSON.stringify(state.inputs[device_id]));
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      } else {
        device_obj = device_update_data;
      }
      Vue.set(state.inputs, device_id, device_obj);
    },
    DELETE_CAMERA(state, id) {
      var device_id = String(id);
      var index = state.camerasOrder.indexOf(device_id);
      if (index >= 0) {
        state.camerasOrder.splice(index, 1);
        delete state.cameras[device_id];
      }
    },
    DELETE_CONTROLLER(state, id) {
      var device_id = String(id);
      var index = state.controllersOrder.indexOf(device_id);
      if (index >= 0) {
        state.controllersOrder.splice(index, 1);
        delete state.controllers[device_id];
      }
    },
    DELETE_DOOR(state, id) {
      var device_id = String(id);
      var index = state.doorsOrder.indexOf(device_id);
      if (index >= 0) {
        state.doorsOrder.splice(index, 1);
        delete state.doors[device_id];
      }
    },
    DELETE_READER(state, id) {
      var device_id = String(id);
      var index = state.readersOrder.indexOf(device_id);
      if (index >= 0) {
        state.readersOrder.splice(index, 1);
        delete state.readers[device_id];
      }
    },
    DELETE_INPUT(state, id) {
      var device_id = String(id);
      var index = state.inputsOrder.indexOf(device_id);
      if (index >= 0) {
        state.inputsOrder.splice(index, 1);
        delete state.inputs[device_id];
      }
    },
    CLEAR_CAMERA(state) {
      for (var i in state.camerasOrder) {
        var id = state.camerasOrder[i];
        delete state.cameras[id];
      }
      state.camerasOrder = [];
    },
    CLEAR_CONTROLLER(state) {
      for (var i in state.controllersOrder) {
        var id = state.controllersOrder[i];
        delete state.controllers[id];
      }
      state.controllersOrder = [];
    },
    CLEAR_DOOR(state) {
      for (var i in state.doorsOrder) {
        var id = state.doorsOrder[i];
        delete state.doors[id];
      }
      state.doorsOrder = [];
    },
    CLEAR_READER(state) {
      for (var i in state.readersOrder) {
        var id = state.readersOrder[i];
        delete state.readers[id];
      }
      state.readersOrder = [];
    },
    CLEAR_INPUT(state) {
      for (var i in state.inputsOrder) {
        var id = state.inputsOrder[i];
        delete state.inputs[id];
      }
      state.inputsOrder = [];
    },
    START_DEVICES_POLLING(state, params) {
      var intervalID = setInterval(() => {
        params["poll_func"]();
      }, params["interval"]);

      var intervalID_value = parseInt(String(intervalID));
      state.devicesPollingInterval = intervalID_value;
    },
    RESET_DEVICES_POLLING(state, intervalID) {
      clearInterval(intervalID);
    },
    RESET_STORE(state) {
      let originalStates = JSON.parse(JSON.stringify(deviceStates));
      for (var s in originalStates) {
        Vue.set(state, s, originalStates[s]);
        // console.log("state ", s, " = ", state[s]);
      }
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addDevice: (context, value) => {
      if (value["device_group"] == "DEV_CAM") {
        context.commit("ADD_CAMERA", value);
        // console.log("add camera...");

        context.dispatch(
          "device/updateCamera",
          {
            id: value.id,
            data: {
              name: value.name,
              type: value.type,
              device_group: value.device_group,
              status: value.status
            }
          },
          { root: true }
        );
      } else if (value["device_group"] == "DEV_CTRL") {
        context.commit("ADD_CONTROLLER", value);
        // console.log("add controllers...");

        context.dispatch(
          "device/updateController",
          {
            id: value.id,
            data: {
              name: value.name,
              type: value.type,
              device_group: value.device_group,
              status: value.status
            }
          },
          { root: true }
        );
      }
    },
    deleteDevice: (context, value) => {
      if (value["device_type"] == "camera") {
        context.commit("DELETE_CAMERA", value["id"]);
      } else if (value["device_type"] == "controller") {
        context.commit("DELETE_CONTROLLER", value["id"]);
      }
    },

    setViewingPopup: (context, value) => {
      context.commit("SET_VIEWING_POPUP", value);
    },
    clearDevices: (context, value) => {
      context.commit("CLEAR_CAMERA", value);
      context.commit("CLEAR_CONTROLLER", value);
    },
    updateCamera: (context, value) => {
      context.commit("UPDATE_CAMERA", { id: value.id, data: value.data });
    },
    updateController: (context, value) => {
      context.commit("UPDATE_CONTROLLER", { id: value.id, data: value.data });
    },
    updateDoor: (context, value) => {
      context.commit("UPDATE_DOOR", { id: value.id, data: value.data });
    },
    updateReader: (context, value) => {
      context.commit("UPDATE_READER", { id: value.id, data: value.data });
    },
    updateInput: (context, value) => {
      context.commit("UPDATE_INPUT", { id: value.id, data: value.data });
    },
    async fetchDevices(context) {
      let that = this;
      // console.log("Polling devices from Monitor Service...");
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/api/monitorservice/status/";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );
      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        // console.log("err in Polling devices from Monitor Service...");
        // console.log(err);

        context.dispatch(
          "session/addGlobalAlertMessage",
          {
            message_text: err.detail,
            message_type: "danger"
          },
          { root: true }
        );
        // if (err.status == 401) {
        //   context.dispatch("session/logoutSession", null, { root: true });
        // }
        return err;
      }
      // console.log(response.result);

      if (response.result != undefined) {
        for (var service_url in response["result"]) {
          let service_result_camera = null;
          let service_result_ctrl = null;

          // Camera result
          try {
            service_result_camera =
              response["result"][service_url]["result"]["DEV_CAM"];
          } catch (err) {
            //
          }

          if (service_result_camera != null) {
            for (var i in service_result_camera) {
              var camera_url = i;
              var camera_data = service_result_camera[i];
              var camera_ip = new URL(camera_url).host;
              if (camera_data.details != undefined) {
                if (camera_data.details.ip != undefined) {
                  camera_ip = camera_data.details.ip;
                }
              }
              // var camera_status = Math.floor(Math.random() * 2);
              var camera_status = camera_data["status"];
              var monitor_type = camera_data["monitor_type"];
              var camera_result = {
                id: camera_url,
                ip: camera_ip,
                type: "camera",
                status: camera_status,
                monitor_type: monitor_type,
                data: {
                  server_url:
                    camera_data["monitor_url"] == undefined
                      ? null
                      : camera_data["monitor_url"],
                  details:
                    camera_data["details"] == undefined
                      ? null
                      : camera_data["details"],
                  meta:
                    camera_data["meta"] == undefined
                      ? null
                      : camera_data["meta"]
                }
              };
              // console.log("camera_result", camera_result)

              context.commit("ADD_CAMERA", camera_result);
            }
          }

          // Controller result
          try {
            service_result_ctrl =
              response["result"][service_url]["result"]["DEV_CTRL"];
          } catch (err) {
            //
          }
          if (service_result_ctrl != null) {
            for (var j in service_result_ctrl) {
              var ctrl_data = service_result_ctrl[j];
              var ctrl_ip = new URL(j).host;
              if (ctrl_data.details != undefined) {
                if (ctrl_data.details.ip != undefined) {
                  ctrl_ip = ctrl_data.details.ip;
                }
              }
              // var ctrl_status = Math.floor(Math.random() * 2);
              var ctrl_status = ctrl_data["status"];
              var ctr_monitor_type = ctrl_data["monitor_type"];

              var ctrl_result = {
                id: j,
                ip: ctrl_ip,
                type: "controller",
                status: ctrl_status,
                monitor_type: ctr_monitor_type,
                data: {
                  server_url:
                    ctrl_data["monitor_url"] == undefined
                      ? null
                      : ctrl_data["monitor_url"],
                  details:
                    ctrl_data["details"] == undefined
                      ? null
                      : ctrl_data["details"],
                  meta:
                    ctrl_data["meta"] == undefined ? null : ctrl_data["meta"]
                }
              };

              // console.log("controller result", ctrl_result)
              context.commit("ADD_CONTROLLER", ctrl_result);

              // get door, reader, input from controller details

              // let door_list = [];
              // let reader_list = [];
              // let input_list = [];

              // if (ctrl_result.data.details.doors.length > 0) {
              //   door_list = findControllerSubDevicesByType(
              //     ctrl_result,
              //     "door",
              //     ctrl_result.data.details.doors
              //   );
              // }
              // if (ctrl_result.data.details.readers.length > 0) {
              //   reader_list = findControllerSubDevicesByType(
              //     ctrl_result,
              //     "reader",
              //     ctrl_result.data.details.readers
              //   );
              // }
              // if (ctrl_result.data.details.inputs.length > 0) {
              //   input_list = findControllerSubDevicesByType(
              //     ctrl_result,
              //     "input",
              //     ctrl_result.data.details.inputs
              //   );
              // }

              // console.log("device module door_list");
              // console.log(door_list);
              //
              // console.log("reader module door_list");
              // console.log(reader_list);
              //
              // console.log("input module door_list");
              // console.log(input_list);

              // door_list.map((door) => context.commit("ADD_DOOR", door));
              // reader_list.map((reader) => context.commit("ADD_READER", reader));
              // input_list.map((input) => context.commit("ADD_INPUT", input));
            }
          }
        }

        context.commit("SET_DEVICES_INITIATED", true);
      }

      return response;
    },

    startDevicesPolling: (context, value) => {
      var fetchDevicesFunc = function () {
        context.dispatch("fetchDevices").then((result) => {});
      };
      fetchDevicesFunc();

      context.commit("START_DEVICES_POLLING", {
        poll_func: fetchDevicesFunc,
        interval: value
      });
    },
    resetDevicesPolling: (context) => {
      let intervalID = context.getters["getDevicesPollingInterval"];
      // console.log("resetDevicesPolling...");
      //
      // console.log("intervalID");
      // console.log(intervalID);

      context.commit("RESET_DEVICES_POLLING", intervalID);
    },
    resetStore: (context) => {
      var intervalID = context.getters["getDevicesPollingInterval"];
      context.commit("RESET_DEVICES_POLLING", intervalID);

      context.commit("RESET_STORE");
    }
  }
};
