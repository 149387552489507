/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    visitors: [],
    selectedHostNameObj: {},
    selectedHostCompanyObj: {},
    registration_type: "WALK_IN"
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });
        return results;
      }
    },
    getVisitors: (state, getters) => {
      return state.visitors;
    },
    getSelectedHostCompanyObj: (state, getters) => {
      return state.selectedHostCompanyObj;
    },

    validSelf: (state) => {
      return Object.keys(state.selectedHostCompanyObj).length > 0;
    },
    validSummary: (state) => {
      return Object.keys(state.visitors).length > 0;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    ADD_VISITOR(state, payload) {
      state.visitors.push(payload);
    },
    RESET_VISITOR(state) {
      //state.visitors = [];
      state.visitors = [];
    },
    RESET_ALL(state) {
      state.visitors = [];
      state.selectedHostNameObj = {};
      state.selectedHostCompanyObj = {};
      state.registration_type = "WALK_IN";
      state.visit_type = null;
      state.visit_start_date = null;
      state.visit_end_date = null;
      state.visit_start_time = null;
      state.visit_end_time = null;
      state.visit_purpose = null;
      state.additional_metadata = {};
      state.declaration_form_data = {};
    }
  },
  // -----------------------------------------------------------------
  actions: {
    setState: (context, payload) => {
      context.commit("SET_STATE", payload);
    },
    addVisitor: (context, value) => {
      context.commit("ADD_VISITOR", value);
    },
    resetVisitor: (context) => {
      context.commit("RESET_VISITOR");
    },
    resetAll: (context) => {
      context.commit("RESET_ALL");
    }
  }
};
