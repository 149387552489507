import Vue from "vue";
import VueRouter from "vue-router";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import store from "../store";
import moment from "moment";

Vue.use(VueRouter);
const Activation = () => import("@/pages/Activation/App.vue");
const Login = () => import("@/pages/Login/App.vue");
const ForgotPassword = () => import("@/pages/ForgotPassword/App.vue");
const ResetPassword = () => import("@/pages/ResetPassword/App.vue");
const PSIM = () => import("@/pages/PSIM/App.vue");
const Profile = () => import("@/pages/Profile/App.vue");
const License = () => import("@/pages/License/App.vue");
const SettingsFloorplanHome = () => import("@/pages/SettingsFloorplan/App.vue");
const SettingsFloorplanBuildingList = () =>
  import("@/pages/SettingsFloorplan/BuildingList.vue");
const SettingsFloorplanBuildingDetail = () =>
  import("@/pages/SettingsFloorplan/BuildingDetail.vue");
const SettingsFloorplanFloor = () =>
  import("@/pages/SettingsFloorplan/FloorDetail.vue");
const SettingsFloorplanFloorEditor = () =>
  import("@/pages/SettingsFloorplan/FloorPlanEditor.vue");
const SettingsWorkspaceHome = () => import("@/pages/SettingsWorkspace/App.vue");
const SettingsWorkspaceList = () =>
  import("@/pages/SettingsWorkspace/WorkspaceList.vue");
const SettingsWorkspaceEditor = () =>
  import("@/pages/SettingsWorkspace/WorkspaceEditor.vue");
const SettingsUser = () => import("@/pages/SettingsUser/App.vue");
const SettingsRoot = () => import("@/pages/Settings/App.vue");
const ServicesSettings = () => import("@/pages/Settings/Services/App.vue");
const SystemSettings = () => import("@/pages/Settings/System/App.vue");
const VmsSettings = () => import("@/pages/Settings/SmartVms/App.vue");
// vms
const SmartVmsHome = () => import("@/pages/SmartVms/App.vue");
const Visitors = () => import("@/pages/SmartVms/Visitor/App.vue");
const VisitorType = () => import("@/pages/SmartVms/Visitor/VisitorType.vue");
const Visits = () => import("@/pages/SmartVms/Visit/App.vue");
const VisitDetail = () => import("@/pages/SmartVms/Visit/VisitDetail.vue");
const VisitAccess = () => import("@/pages/SmartVms/Visit/VisitAccess.vue");
const VisitHistory = () => import("@/pages/SmartVms/Visit/VisitHistory.vue");
const Registration = () => import("@/pages/SmartVms/Registration/App2.vue");
const Invitation = () => import("@/pages/SmartVms/Invitation/App.vue");
const Company = () => import("@/pages/SmartVms/Company/App.vue");
const Host = () => import("@/pages/SmartVms/Host/App.vue");
const CustomForm = () => import("@/pages/SmartVms/CustomForm/App.vue");
const CustomFormCreate = () =>
  import("@/pages/SmartVms/CustomForm/CustomFormCreate.vue");
// kiosk
const Kiosk2 = () => import("@/pages/Kiosk/App.vue");
const Kiosk2Registration = () => import("@/pages/Kiosk/App2.vue");
const Kiosk2VisitationCode = () => import("@/pages/Kiosk/VisitationCode.vue");
const Kiosk2InviteConfirmation = () =>
  import("@/components/SmartVMS/InviteConfirmation.vue");
// public
const Public = () => import("@/pages/Public/App.vue");
const PublicRegistration = () => import("@/pages/Public/App2.vue");
const PublicSuccess = () => import("@/pages/Public/Success.vue");
const PublicInviteConfirmation = () =>
  import("@/components/SmartVMS/InviteConfirmation.vue");
// report
const Reports = () => import("@/pages/Reports/App.vue");
const SmartVMSBulkImportProgressReports = () =>
  import("@/pages/Reports/SmartVMS/BulkImportProgressReport.vue");
const SmartVmsReportOnDemand = () => import("@/pages/Reports/SmartVMS/App.vue");
const SmartVmsEvacuationReport = () =>
  import("@/pages/Reports/SmartVMS/EvacuationReport.vue");
const SmartVmsScheduledReport = () =>
  import("@/pages/Reports/SmartVMS/ScheduledReport.vue");
const SmartLprReportOnDemand = () => import("@/pages/Reports/SmartLPR/App.vue");
const DeviceManagement = () => import("@/pages/DeviceManagement/App.vue");
const AcsPerson = () => import("@/pages/Acs/Person/App.vue");
const ServicesMonitoring = () => import("@/pages/ServicesMonitoring/App.vue");
// logs
const Logs = () => import("@/pages/Logs/App.vue");
const SystemLogs = () => import("@/pages/Logs/SystemLogs/App.vue");
const AuditLogs = () => import("@/pages/Logs/AuditLogs/App.vue");
const Error = () => import("@/pages/Error/App.vue");
// workflow
const WorkflowSettings = () => import("@/pages/Settings/Workflow/App.vue");
const WorkflowAdditor = () =>
  import("@/pages/Settings/WorkflowAdditor/App.vue");
const WorkflowEditor = () => import("@/pages/Settings/WorkflowEditor/App.vue");
const Cases = () => import("@/pages/CaseManagement/App.vue");
const OpenCases = () => import("@/pages/CaseManagement/OpenCases/App.vue");
const CloseCases = () => import("@/pages/CaseManagement/CloseCases/App.vue");
const DetailCases = () => import("@/pages/CaseManagement/DetailCases/App.vue");

let sc_mode = "SC_DASHBOARD";
let country_code = null;

function guardRoute(to, from, next) {
  //debugger; // eslint-disable-line no-debugger
  let validRole = false;
  let validPagePermissions = false;
  let validMode = false;
  let currentUser = store.getters["session/getCurrentUser"];
  // check role is_superuser
  if (
    currentUser != null &&
    currentUser.is_superuser &&
    to.meta.role_required.includes("is_superuser")
  ) {
    validRole = true;
    console.log("validRole_is_superuser", validRole);
  }
  // check role is_admin
  if (
    currentUser != null &&
    currentUser.is_admin &&
    to.meta.role_required.includes("is_admin")
  ) {
    validRole = true;
    console.log("validRole_is_admin", validRole);
  }
  // check role is_kiosk
  if (
    currentUser != null &&
    !currentUser.is_admin &&
    to.meta.role_required.includes("is_kiosk")
  ) {
    validRole = true;
    console.log("validRole_is_kiosk", validRole);
  }
  // check role is_public
  if (currentUser == null && to.meta.role_required.includes("is_public")) {
    validRole = true;
    console.log("validRole_is_public", validRole);
  }
  // check role is_user
  if (
    currentUser != null &&
    to.name == "login" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = false;
    console.log("validRole_is_user1", validRole);
  }
  if (
    currentUser != null &&
    to.name != "login" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user2", validRole);
  }
  if (
    currentUser != null &&
    to.name == "PSIM" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user3", validRole);
  }
  if (
    currentUser != null &&
    to.name == "profile" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user4", validRole);
  }
  if (
    currentUser == null &&
    to.name == "login" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user5", validRole);
  }
  if (
    currentUser == null &&
    to.name == "forgot-password" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user6", validRole);
  }
  if (
    currentUser == null &&
    to.name == "reset-password" &&
    to.meta.role_required.includes("is_user")
  ) {
    validRole = true;
    console.log("validRole_is_user7", validRole);
  }

  // check page permissions
  const RequiredPermissions = to.meta.page_permissions_required;
  if (currentUser != null) {
    const HasPermissions = currentUser.pages;
    validPagePermissions = RequiredPermissions.every((val) =>
      HasPermissions.includes(val)
    );
    console.log(
      "validPagePermissions_currentUser_not_null",
      validPagePermissions
    );
  } else {
    validPagePermissions = true;
    console.log("validPagePermissions_currentUser_null", validPagePermissions);
  }

  // check sc_mode
  if (sc_mode === "SC_DASHBOARD") {
    validMode = true;
    console.log("validMode1", validMode);
  } else if (to.meta.sc_modes_allowed.length === 0) {
    validMode = true;
    console.log("validMode2", validMode);
  } else if (to.meta.sc_modes_allowed.includes(sc_mode)) {
    validMode = true;
    console.log("validMode3", validMode);
  }
  //validMode = true;

  if (currentUser != null && currentUser.is_superuser && validMode) {
    // allow all for superuser
    console.log("allow all for superuser");
    next();
  } else if (validRole && validPagePermissions && validMode) {
    // allow all for not superuser
    console.log("allow all for not superuser");
    if (
      currentUser != null &&
      (to.name == "forgot-password" || to.name == "reset-password")
    ) {
      store
        .dispatch("session/addGlobalAlertMessage", {
          message_text: "You do not have permission to view this page.",
          message_type: "danger"
        })
        .then();
      next({ name: "error" });
    } else {
      next();
    }
  } else if (
    currentUser != null &&
    !validRole &&
    validPagePermissions &&
    validMode
  ) {
    // allow all for not superuser
    console.log("for login");
    next({ name: "PSIM" });
  } else {
    // back to login
    console.log("back to login");
    next({ name: "login" });
  } /* else if (currentUser != null) {
    // not allow any role
    console.log("not allow any role");
    store
      .dispatch("session/addGlobalAlertMessage", {
        message_text: "You do not have permission to view this page.",
        message_type: "danger"
      })
      .then();
    next({ name: "error" });
  } */
}

const routes = [
  {
    path: "/activation",
    name: "activation",
    component: Activation,
    meta: {
      title: "Activation",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/login",
    name: "login",
    beforeEnter: guardRoute,
    component: Login,
    meta: {
      title: "Login",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    beforeEnter: guardRoute,
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/reset-password/token=:token&email=:email",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "",
    name: "PSIM",
    beforeEnter: guardRoute,
    component: PSIM,
    meta: {
      title: "PSIM",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/license",
    name: "license",
    beforeEnter: guardRoute,
    component: License,
    meta: {
      title: "My License",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/profile",
    name: "profile",
    beforeEnter: guardRoute,
    component: Profile,
    meta: {
      title: "My Profile",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/buildings",
    component: SettingsFloorplanHome,
    children: [
      {
        path: "",
        name: "Building List",
        beforeEnter: guardRoute,
        component: SettingsFloorplanBuildingList,
        meta: {
          title: "Building List",
          role_required: ["is_user"],
          page_permissions_required: ["building_management"],
          sc_modes_allowed: ["SC_OTHER"]
        }
      },
      {
        path: ":building_id",
        name: "Building Detail",
        beforeEnter: guardRoute,
        component: SettingsFloorplanBuildingDetail,
        meta: {
          title: "Building Detail",
          role_required: ["is_user"],
          page_permissions_required: ["building_management"],
          sc_modes_allowed: ["SC_OTHER"]
        }
      },
      {
        path: ":building_id/:floor_id",
        name: "Floor Plan",
        beforeEnter: guardRoute,
        component: SettingsFloorplanFloor,
        meta: {
          title: "Floor Plan",
          role_required: ["is_user"],
          page_permissions_required: ["building_management"],
          sc_modes_allowed: ["SC_OTHER"]
        }
      },
      {
        path: ":building_id/:floor_id/editor",
        name: "Floor Plan Editor",
        beforeEnter: guardRoute,
        component: SettingsFloorplanFloorEditor,
        meta: {
          title: "Floor Plan Editor",
          role_required: ["is_user"],
          page_permissions_required: ["building_management"],
          sc_modes_allowed: ["SC_OTHER"]
        }
      }
    ]
  },
  {
    path: "/workspaces",
    component: SettingsWorkspaceHome,
    children: [
      {
        path: "",
        name: "Workspace List",
        beforeEnter: guardRoute,
        component: SettingsWorkspaceList,
        meta: {
          title: "Workspace List",
          role_required: ["is_user"],
          page_permissions_required: ["workspace_management"],
          sc_modes_allowed: []
        }
      },
      {
        path: "create",
        name: "Workspace Create",
        beforeEnter: guardRoute,
        component: SettingsWorkspaceEditor,
        meta: {
          title: "Workspace Create",
          role_required: ["is_user"],
          page_permissions_required: ["workspace_management"],
          sc_modes_allowed: []
        }
      },
      {
        path: "edit/:workspace_id",
        name: "Workspace Edit",
        beforeEnter: guardRoute,
        component: SettingsWorkspaceEditor,
        meta: {
          title: "Workspace Edit",
          role_required: ["is_user"],
          page_permissions_required: ["workspace_management"],
          sc_modes_allowed: []
        }
      },
      {
        path: "shared/create",
        name: "Workspace Create Shared",
        beforeEnter: guardRoute,
        component: SettingsWorkspaceEditor,
        meta: {
          title: "Workspace Create",
          role_required: ["is_user"],
          page_permissions_required: ["workspace_management"],
          sc_modes_allowed: []
        }
      },
      {
        path: "shared/edit/:workspace_id",
        name: "Workspace Edit Shared",
        beforeEnter: guardRoute,
        component: SettingsWorkspaceEditor,
        meta: {
          title: "Workspace Edit",
          role_required: ["is_user"],
          page_permissions_required: ["workspace_management"],
          sc_modes_allowed: []
        }
      }
    ]
  },

  {
    path: "/users",
    name: "users",
    beforeEnter: guardRoute,
    component: SettingsUser,
    meta: {
      title: "Users Management",
      role_required: ["is_admin", "is_superuser"],
      page_permissions_required: ["user_management"],
      sc_modes_allowed: []
    }
  },
  {
    path: "/acs/persons",
    name: "acs/person",
    beforeEnter: guardRoute,
    component: AcsPerson,
    meta: {
      title: "ACS Persons",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_OTHER"]
    }
  },
  {
    path: "/services-monitoring",
    name: "services-monitoring",
    beforeEnter: guardRoute,
    component: ServicesMonitoring,
    meta: {
      title: "Services Monitoring",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/logs",
    component: Logs,
    beforeEnter: guardRoute,
    meta: {
      title: "Logs",
      role_required: ["is_admin", "is_superuser"],
      page_permissions_required: [],
      sc_modes_allowed: []
    },
    children: [
      {
        path: "system-logs",
        name: "system-logs",
        beforeEnter: guardRoute,
        component: SystemLogs,
        meta: {
          title: "System Logs",
          role_required: ["is_admin", "is_superuser"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "audit-logs",
        name: "audit-logs",
        beforeEnter: guardRoute,
        component: AuditLogs,
        meta: {
          title: "Audit Logs",
          role_required: ["is_admin", "is_superuser"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      }
    ]
  },
  {
    path: "/devices",
    name: "devices",
    beforeEnter: guardRoute,
    component: DeviceManagement,
    meta: {
      title: "Device Management",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_OTHER"]
    }
  },
  {
    path: "/reports",
    name: "reports",
    beforeEnter: guardRoute,
    component: Reports,
    meta: {
      title: "Reports",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/reports/vms/report-on-demand",
    name: "/reports/vms/report-on-demand",
    beforeEnter: guardRoute,
    component: SmartVmsReportOnDemand,
    meta: {
      title: "Report On Demand",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_VMS"]
    }
  },
  {
    path: "/reports/vms/evacuation-report",
    name: "/reports/vms/evacuation-report",
    beforeEnter: guardRoute,
    component: SmartVmsEvacuationReport,
    meta: {
      title: "Evacuation Report",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_VMS"]
    }
  },
  {
    path: "/reports/vms/scheduled-report",
    name: "/reports/vms/scheduled-report",
    beforeEnter: guardRoute,
    component: SmartVmsScheduledReport,
    meta: {
      title: "Scheduled Report",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_VMS"]
    }
  },
  {
    path: "/reports/vms/bulk-import-progress-report",
    name: "/reports/vms/bulk-import-progress-report",
    beforeEnter: guardRoute,
    component: SmartVMSBulkImportProgressReports,
    meta: {
      title: "Bulk Import Progress Report",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_VMS"]
    }
  },
  {
    path: "/reports/lpr/report-on-demand",
    name: "/reports/lpr/report-on-demand",
    beforeEnter: guardRoute,
    component: SmartLprReportOnDemand,
    meta: {
      title: "Report On Demand",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_LPR"]
    }
  },

  {
    path: "/settings",
    beforeEnter: guardRoute,
    component: SettingsRoot,
    meta: {
      title: "Settings",
      role_required: ["is_admin"],
      page_permissions_required: [],
      sc_modes_allowed: []
    },
    children: [
      {
        path: "services",
        name: "settings/services",
        beforeEnter: guardRoute,
        component: ServicesSettings,
        meta: {
          title: "Services Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "system",
        name: "settings/system",
        beforeEnter: guardRoute,
        component: SystemSettings,
        meta: {
          title: "System Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "vms",
        name: "settings/vms",
        beforeEnter: guardRoute,
        component: VmsSettings,
        meta: {
          title: "VMS Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "workflow",
        name: "settings/workflow",
        beforeEnter: guardRoute,
        component: WorkflowSettings,
        meta: {
          title: "Workflow Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "settings/workflow/update/:workflow_id",
        name: "settings/workflow/update",
        beforeEnter: guardRoute,
        component: WorkflowEditor,
        meta: {
          title: "Workflow Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "settings/workflow/create",
        name: "settings/workflow/create",
        beforeEnter: guardRoute,
        component: WorkflowAdditor,
        meta: {
          title: "Workflow Settings",
          role_required: ["is_admin"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      }
    ]
  },
  {
    path: "/vms",
    component: SmartVmsHome,
    beforeEnter: guardRoute,
    meta: {
      title: "VMS",
      role_required: ["is_user"],
      page_permissions_required: [],
      sc_modes_allowed: ["SC_VMS"]
    },
    children: [
      {
        path: "create-visit",
        name: "create-visit",
        beforeEnter: guardRoute,
        component: Registration,
        meta: {
          title: "Registration",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "create-invite",
        name: "create-invite",
        beforeEnter: guardRoute,
        component: Invitation,
        meta: {
          title: "Invitation",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visits",
        name: "visits",
        beforeEnter: guardRoute,
        component: Visits,
        meta: {
          title: "Visits",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visits/:id",
        name: "visit-detail",
        beforeEnter: guardRoute,
        component: VisitDetail,
        meta: {
          title: "Visit Detail",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visits/:id",
        name: "visit-access",
        beforeEnter: guardRoute,
        component: VisitAccess,
        meta: {
          title: "Visit Access",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visits/history/:id",
        name: "visit-history",
        beforeEnter: guardRoute,
        component: VisitHistory,
        meta: {
          title: "Visit History",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visitors",
        name: "visitors",
        beforeEnter: guardRoute,
        component: Visitors,
        meta: {
          title: "Visitors",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "visitor-type",
        name: "visitor-type",
        beforeEnter: guardRoute,
        component: VisitorType,
        meta: {
          title: "Visitor Type",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "company",
        name: "company",
        beforeEnter: guardRoute,
        component: Company,
        meta: {
          title: "Company",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "host",
        name: "host",
        beforeEnter: guardRoute,
        component: Host,
        meta: {
          title: "Host",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "custom-forms",
        name: "custom-forms",
        component: CustomForm,
        beforeEnter: guardRoute,
        meta: {
          title: "Custom Forms",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "custom-forms/add",
        name: "Custom Create",
        component: CustomFormCreate,
        beforeEnter: guardRoute,
        meta: {
          title: "Custom Forms",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      },
      {
        path: "custom-forms/edit/:form_id",
        name: "Custom Editor",
        beforeEnter: guardRoute,
        component: CustomFormCreate,
        meta: {
          title: "",
          role_required: ["is_user"],
          page_permissions_required: [],
          sc_modes_allowed: ["SC_VMS"]
        }
      }
    ]
  },
  {
    path: "/public/registration",
    name: "/public/registration",
    component: Public,
    beforeEnter: guardRoute,
    meta: {
      title: "Public",
      role_required: ["is_public"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/public-registration",
    name: "public-registration",
    component: PublicRegistration,
    beforeEnter: guardRoute,
    meta: {
      title: "Public",
      role_required: ["is_public"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/public/success",
    name: "/public/success",
    component: PublicSuccess,
    beforeEnter: guardRoute,
    meta: {
      title: "Public",
      role_required: ["is_public"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/public/invitation/:invite_code",
    name: "/public/invitation",
    component: PublicInviteConfirmation,
    beforeEnter: guardRoute,
    meta: {
      title: "Invitation",
      role_required: ["is_public"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/kiosk2",
    name: "kiosk2",
    component: Kiosk2,
    beforeEnter: guardRoute,
    meta: {
      title: "Kiosk",
      role_required: ["is_kiosk"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/kiosk2-registration",
    name: "kiosk2-registration",
    component: Kiosk2Registration,
    beforeEnter: guardRoute,
    meta: {
      title: "Kiosk",
      role_required: ["is_kiosk"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/kiosk2-visitation-code",
    name: "kiosk2-visitation-code",
    component: Kiosk2VisitationCode,
    beforeEnter: guardRoute,
    meta: {
      title: "Kiosk",
      role_required: ["is_kiosk"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/kiosk2-invite/:invite_code",
    name: "kiosk2-invite",
    component: Kiosk2InviteConfirmation,
    beforeEnter: guardRoute,
    meta: {
      title: "Kiosk",
      role_required: ["is_kiosk"],
      page_permissions_required: [],
      sc_modes_allowed: []
    }
  },
  {
    path: "/error",
    name: "Error",
    component: Error
  },
  {
    path: "*",
    name: "error",
    redirect: "/error"
  },
  {
    path: "/cases",
    component: Cases,
    beforeEnter: guardRoute,
    meta: {
      title: "Cases",
      role_required: ["is_admin", "is_superuser"],
      page_permissions_required: [],
      sc_modes_allowed: []
    },
    children: [
      {
        path: "open-cases",
        name: "open-cases",
        beforeEnter: guardRoute,
        component: OpenCases,
        meta: {
          title: "Open Cases",
          role_required: ["is_admin", "is_superuser"],
          page_permissions_required: [],
          sc_modes_allowed: []
        },
        children: []
      },
      {
        path: "close-cases",
        name: "close-cases",
        beforeEnter: guardRoute,
        component: CloseCases,
        meta: {
          title: "Close Cases",
          role_required: ["is_admin", "is_superuser"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      },
      {
        path: "case-details/:id",
        name: "case-details",
        beforeEnter: guardRoute,
        component: DetailCases,
        meta: {
          title: "Detail Cases",
          role_required: ["is_admin", "is_superuser"],
          page_permissions_required: [],
          sc_modes_allowed: []
        }
      }
    ]
  }
  // {
  //   path: "edit-visit/:visit_id",
  //   name: "edit-visit",
  //   beforeEnter: guardRoute,
  //   component: Registration,
  //   meta: {
  //     title: "Registration",
  //     role_required: ["is_user"],
  //     page_permissions_required: [],
  //     sc_modes_allowed: ["SC_VMS"]
  //   }
  // },
  // {
  //   path: "kiosk",
  //   name: "kiosk",
  //   beforeEnter: guardRoute,
  //   component: Kiosk,
  //   meta: {
  //     title: "Kiosk",
  //     role_required: ["is_user"],
  //     page_permissions_required: [],
  //     sc_modes_allowed: ["SC_VMS"]
  //   },
  //   children: [
  //     {
  //       path: "kiosk-arrive/create-visit",
  //       name: "kiosk-arrive/create-visit",
  //       beforeEnter: guardRoute,
  //       component: KioskArrive,
  //       meta: {
  //         title: "Kiosk Arrive",
  //         role_required: ["is_user"],
  //         page_permissions_required: [],
  //         sc_modes_allowed: ["SC_VMS"]
  //       }
  //     },
  //     {
  //       path: "kiosk-invite/create-visit",
  //       name: "kiosk-invite/create-visit",
  //       beforeEnter: guardRoute,
  //       component: KioskInvite,
  //       meta: {
  //         title: "Kiosk Invite",
  //         role_required: ["is_user"],
  //         page_permissions_required: [],
  //         sc_modes_allowed: ["SC_VMS"]
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: "preferences",
  //   name: "preferences",
  //   beforeEnter: guardRoute,
  //   component: Preferences,
  //   meta: {
  //     title: "Preferences",
  //     role_required: ["is_user"],
  //     page_permissions_required: [],
  //     sc_modes_allowed: ["SC_VMS"]
  //   }
  // },
  // {
  //   path: "ic_reader_sample",
  //   name: "ic_reader_sample",
  //   beforeEnter: guardRoute,
  //   component: IcReaderSample,
  //   meta: {
  //     title: "IC Reader",
  //     role_required: ["is_user"],
  //     page_permissions_required: [],
  //     sc_modes_allowed: ["SC_VMS"]
  //   }
  // }
  // {
  //   path: "/public/fill",
  //   name: "/public/fill",
  //   component: PublicFill
  // },
  // {
  //   path: "/public/self",
  //   name: "/public/self",
  //   component: PublicSelf
  // },
  // {
  //   path: "/public/summary",
  //   name: "/public/summary",
  //   component: PublicSummary
  // },
  // {
  //   path: "/kiosk2-self",
  //   name: "kiosk2-self",
  //   component: Kiosk2Self
  // },
  // {
  //   path: "/kiosk2-fill",
  //   name: "kiosk2-fill",
  //   component: Kiosk2Fill
  // },
  // {
  //   path: "/kiosk2-summary",
  //   name: "kiosk2-summary",
  //   component: Kiosk2Summary
  // },
  // {
  //   path: "/kiosk2-action",
  //   name: "kiosk2-action",
  //   component: Kiosk2Action
  // },
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  let hasConnectedToLicenseServices = false;
  let hasLicense = false;
  let hasVerifiedLicense = false;
  let isAuthenticated = false;
  // Set apiserverURL, wsserver_url
  let apiserver_url = "";
  if (
    store.getters["session/getAPIServerURL"] !== undefined &&
    store.getters["session/getAPIServerURL"] !== null &&
    store.getters["session/getAPIServerURL"] !== ""
  ) {
    apiserver_url = store.getters["session/getAPIServerURL"];
  } else if (
    localStorage.apiserverURL !== undefined &&
    localStorage.apiserverURL !== null &&
    localStorage.apiserverURL !== ""
  ) {
    apiserver_url = localStorage.apiserverURL;
  } else if (
    process.env.VUE_APP_APISERVER !== undefined &&
    process.env.VUE_APP_APISERVER !== null &&
    process.env.VUE_APP_APISERVER !== ""
  ) {
    apiserver_url = process.env.VUE_APP_APISERVER;
  } else {
    if (
      process.env.VUE_APP_APISERVER_USE_PORT !== undefined &&
      process.env.VUE_APP_APISERVER_USE_PORT !== null &&
      process.env.VUE_APP_APISERVER_USE_PORT === "true"
    ) {
      apiserver_url =
        window.location.protocol + "//" + window.location.hostname + ":8000";
    } else {
      apiserver_url =
        window.location.protocol + "//" + window.location.hostname + "/0";
    }
  }
  console.log("session/setAPIServerURL", apiserver_url);
  await store.dispatch("session/setAPIServerURL", apiserver_url);

  let wsserver_url = "";
  if (
    store.getters["session/getWSServerURL"] !== undefined &&
    store.getters["session/getWSServerURL"] !== null &&
    store.getters["session/getWSServerURL"] !== ""
  ) {
    wsserver_url = store.getters["session/getWSServerURL"];
  } else if (
    localStorage.wsserverURL !== undefined &&
    localStorage.wsserverURL !== null &&
    localStorage.wsserverURL !== ""
  ) {
    wsserver_url = localStorage.wsserverURL;
  } else if (
    process.env.VUE_APP_WSSERVER !== undefined &&
    process.env.VUE_APP_WSSERVER !== null &&
    process.env.VUE_APP_WSSERVER !== ""
  ) {
    wsserver_url = process.env.VUE_APP_WSSERVER;
  } else {
    wsserver_url = "ws://" + window.location.hostname + ":18080/w";
  }
  await store.dispatch("session/setWSServerURL", wsserver_url);

  // Check License
  const API_URL = store.getters["session/getAPIServerURL"] + "/license/verify/";
  const client = new AjaxFetch(null, this);
  let data = await client.postRequest(API_URL);
  console.log("data", data);
  if (data.result !== null) {
    for (const url in data.result) {
      if (data["result"][url]["result"]) {
        hasLicense = true;
        hasConnectedToLicenseServices = true;
      } else {
        hasConnectedToLicenseServices = true;
      }
    }
  }

  // Verify License
  const API_URL2 = store.getters["session/getAPIServerURL"] + "/license/info/";
  let data2 = await client.getRequest(API_URL2);
  let result2 = data2.result;
  if (data2.result !== null) {
    for (const url in result2) {
      if (result2[url] != null) {
        // let exp = moment("2020-1-1 12:00:00");
        let exp = moment(result2[url]["exp"]);
        let now = moment();
        let diffSec = exp.diff(now);
        if (diffSec > 0) {
          hasVerifiedLicense = true;
        }

        if (
          process.env.VUE_APP_SC_MODE !== undefined &&
          process.env.VUE_APP_SC_MODE !== null
        ) {
          switch (process.env.VUE_APP_SC_MODE) {
            case "SC_LPR":
              await store.dispatch("session/setScMode", "SC_LPR");
              break;
            case "SC_VMS":
              await store.dispatch("session/setScMode", "SC_VMS");
              break;
            default:
              await store.dispatch("session/setScMode", "SC_DASHBOARD");
          }
        } else if (result2[url]["sc_mode"] !== undefined) {
          sc_mode = result2[url]["sc_mode"];
          await store.dispatch("session/setScMode", sc_mode);
        }

        if (result2[url]["country_code"] !== undefined) {
          country_code = result2[url]["country_code"];
          await store.dispatch("session/setCountryCode", country_code);
        }
      }
    }
  }

  // Check Auth
  if (sessionStorage.sessionKey) {
    if (store.getters["session/getAccessToken"] == null) {
      const API_URL3 =
        store.getters["session/getAPIServerURL"] + "/auth/token/";
      const client = new AjaxFetch(null, this);
      let data3 = await client.postRequest(API_URL3, {
        session_key: sessionStorage.sessionKey
      });
      if (data3 != null) {
        let accessTokenType = data3.token_type;
        let accessToken = data3.session_token;
        let sessionKey = data3.session_key;
        let wsserverKey = data3.wsserver_key;

        await store.dispatch("session/setAccessTokenType", accessTokenType);
        await store.dispatch("session/setAccessToken", accessToken);
        await store.dispatch("session/setWSServerKey", wsserverKey);
        // $this.$store.dispatch('session/setSecureToken', secureToken);

        sessionStorage.sessionKey = sessionKey;
        sessionStorage.wsserverKey = wsserverKey;

        /** getUserProfile **/
        let API_URL4 =
          store.getters["session/getAPIServerURL"] + "/users/my-user/";
        const client4 = router.app.$root.getAjaxFetchClient();
        let user_data = await client4.getRequest(API_URL4);

        if (user_data.is_active) {
          await store.dispatch("session/setCurrentUser", user_data);
          isAuthenticated = true;

          if (user_data.need_password_change) {
            next({ name: "Change Password" });
          }
        } else {
          await store.dispatch("session/setCurrentUser", null);
          await store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "You account is inactive. Please contact admin for further information.",
            message_type: "warning"
          });
        }
      } else {
        await store.dispatch("session/setCurrentUser", null);
        await store.dispatch("session/addGlobalAlertMessage", {
          message_text: "Session expired. Please login again.",
          message_type: "warning"
        });

        await store.dispatch("session/setAccessTokenType", null);
        await store.dispatch("session/setAccessToken", null);
        await store.dispatch("session/setSecureToken", null);
        await store.dispatch("session/setWSServerKey", null);
        sessionStorage.removeItem("sessionKey");
        sessionStorage.removeItem("wsserverKey");
      }
    } else {
      isAuthenticated = true;
    }
  }
  // hasConnectedToLicenseServices = true;
  // hasLicense = true;
  // hasVerifiedLicense = true;
  // isAuthenticated = true;
  switch (to.name) {
    case "activation":
      next();
      break;
    case "/public/registration":
      next();
      break;
    case "public-registration":
      next();
      break;
    case "/public/fill":
      next();
      break;
    case "/public/self":
      next();
      break;
    case "/public/summary":
      next();
      break;
    case "/public/success":
      next();
      break;
    case "/public/invitation":
      next();
      break;
    case "forgot-password":
      next();
      break;
    case "reset-password":
      next();
      break;
    case "profile":
      next();
      break;
    case "create-invite":
      next();
      break;
    case "PSIM":
      next();
      break;
    case "login":
      if (hasConnectedToLicenseServices && hasLicense && hasVerifiedLicense) {
        next();
      } else {
        if (!hasConnectedToLicenseServices) {
          await store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "There is no response from the licence server. Please try again later.",
            message_type: "danger"
          });
        } else if (!hasLicense) {
          await store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "Please activate Smart Canopy with a valid license. Thank you",
            message_type: "danger"
          });
        } else if (!hasVerifiedLicense) {
          await store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "Your license is expired. You must renew your license to use Smart Canopy. Thank you",
            message_type: "danger"
          });
        }
        next({ name: "activation" });
      }
      break;
    default:
      if (
        hasConnectedToLicenseServices &&
        hasLicense &&
        hasVerifiedLicense &&
        isAuthenticated
      ) {
        next();
      } else {
        next({ name: "login" });
      }
  }
});

router.beforeResolve((to, from, next) => {
  next();
});

export default router;

/*
const Preferences = () => import("@/pages/SmartVms/Preferences/App.vue");
const Registration = () => import("@/pages/SmartVms/Registration/App.vue");
const Kiosk = () => import("@/pages/SmartVms/Kiosk/App.vue");
const KioskArrive = () => import("@/pages/SmartVms/Kiosk/KioskArrive.vue");
const KioskInvite = () => import("@/pages/SmartVms/Kiosk/KioskInvite.vue");
const CustomFormHome = () =>
  import("@/pages/SmartVms/CustomFormEditor/App.vue");
const CustomFormEditor = () =>
  import("@/pages/SmartVms/CustomFormEditor/CustomFormEditor.vue");
const CustomFormList = () =>
  import("@/pages/SmartVms/CustomFormEditor/CustomFormList.vue");
const IcReaderSample = () => import("@/pages/SmartVms/IcReaderSample/App.vue");
const Kiosk2Self = () => import("@/pages/Kiosk/Self.vue");
const Kiosk2Fill = () => import("@/pages/Kiosk/Fill.vue");
const Kiosk2Summary = () => import("@/pages/Kiosk/Summary.vue");
const Kiosk2Action = () => import("@/pages/Kiosk/Action.vue");
const PublicFill = () => import("@/pages/Public/Fill.vue");
const PublicSelf = () => import("@/pages/Public/Self.vue");
const PublicSummary = () => import("@/pages/Public/Summary.vue"); */
