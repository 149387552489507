/** VUEX module for log WS data **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");

let monitorStates = {
  monitors: {},
  monitorOrder: [],
  log: {},
  logOrder: []
};

let copiedMonitorStates = JSON.parse(JSON.stringify(monitorStates));

export default {
  namespaced: true,
  state: copiedMonitorStates,
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
          }
          return true;
        });

        return results;
      }
    },

    getMonitor: (state) => {
      var monitor_list = [];
      for (var s in state.monitorOrder) {
        var monitor_id = String(state.monitorOrder[s]);
        monitor_list.push(state.monitors[monitor_id]);
      }
      return monitor_list;
    },
    getLog: (state) => {
      var monitor_list = [];
      for (var s in state.logOrder) {
        var monitor_id = String(state.logOrder[s]);
        monitor_list.push(state.log[monitor_id]);
      }
      return monitor_list;
    },
    filterMonitor: (state, getters) => (filters) => {
      var monitor_list = getters.getMonitor;
      // console.log("monitor_list", monitor_list);
      return getters._filter(monitor_list, filters);
    }
  },
  mutations: {
    ADD_MONITOR(state, payload) {
      var monitor_id = String(payload.id);
      var monitor_obj = payload;
      if (state.monitorOrder.indexOf(monitor_id) < 0) {
        state.monitorOrder.push(monitor_id);
      }
      Vue.set(state.monitors, monitor_id, monitor_obj);
    },
    ADD_LOG(state, payload) {
      var monitor_id = String(payload.id);
      var monitor_obj = payload;
      if (state.logOrder.indexOf(monitor_id) < 0) {
        state.logOrder.push(monitor_id);
      }
      Vue.set(state.log, monitor_id, monitor_obj);
    },
    RESET_STORE(state) {
      state.monitors = {};
      state.monitorOrder = [];
    }
  },
  actions: {
    addMonitor(context, value) {
      context.commit("ADD_MONITOR", value);
    },
    addLog(context, value) {
      context.commit("ADD_LOG", value);
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    }
  }
};
