/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    preferences: {},
    preferencesOrder: []
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });
        return results;
      }
    },
    getPreferences: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.preferencesOrder) {
        var obj_id = state.preferencesOrder[i];
        obj_list.push(state.preferences[obj_id]);
      }
      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      return result;
    },
    getPreference: (state, getters) => (id) => {
      return state.preferences[id];
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    ADD_PREFERENCE(state, payload) {
      var id = String(payload.id);
      if (state.preferencesOrder.indexOf(id) < 0) {
        state.preferencesOrder.push(id);
      }
      Vue.set(state.preferences, id, payload);
    },
    UPDATE_PREFERENCE(state, payload) {
      var id = String(payload.id);
      var update_data = payload.data;
      var obj = state.preferences[id];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.preferences, id, obj);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addPreference: (context, value) => {
      context.commit("ADD_PREFERENCE", value);
    },
    updatePreference: (context, value) => {
      context.commit("UPDATE_PREFERENCE", value);
    }
  }
};
