import sodium from "libsodium-wrappers";
import NProgress from "nprogress";

let loadingConfig = {
  color: "#F7A155",
  loader: "dots",
  backgroundColor: "rgba(102, 100, 98, 0.5)"
};

export default class AjaxFetch {
  constructor(fetchConfig, thisVue = null) {
    this.reloginCount = 0;
    this.reloginMax = 5;
    this.headers = null;
    this.authToken = null;
    this.secureToken = null;
    this.refreshTokenFunc = null;
    this.refreshTokenFuncArgs = null;
    this.responseType = "json";

    this.showingLoadingIndicatorStatus = false;
    /* if (fetchConfig != undefined) { */
    if (fetchConfig != null && fetchConfig != undefined) {
      if (
        fetchConfig.responseType != null &&
        fetchConfig.responseType != undefined
      ) {
        let allowedTypes = ["json", "blob", "stream", "arraybuffer"];
        if (allowedTypes.indexOf(fetchConfig.responseType) >= 0) {
          this.responseType = fetchConfig.responseType;
        }
      }
      if (
        fetchConfig.reloginMax != null &&
        fetchConfig.reloginMax != undefined
      ) {
        this.reloginMax = fetchConfig.reloginMax;
      }
      if (fetchConfig.headers != null && fetchConfig.headers != undefined) {
        this.headers = fetchConfig.headers;
      }
      if (fetchConfig.authToken != null && fetchConfig.authToken != undefined) {
        this.authToken = fetchConfig.authToken;
      }
      if (
        fetchConfig.secureToken != null &&
        fetchConfig.secureToken != undefined
      ) {
        this.secureToken = fetchConfig.secureToken;
      }
      if (fetchConfig.refreshTokenFunc != undefined) {
        this.refreshTokenFunc = fetchConfig.refreshTokenFunc;
      }
      if (fetchConfig.refreshTokenFuncArgs != undefined) {
        this.refreshTokenFuncArgs = fetchConfig.refreshTokenFuncArgs;
      } else {
        this.refreshTokenFuncArgs = [];
      }
    }

    if (thisVue != null) {
      this.thisVue = thisVue;
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  decodeMsg(data, token) {
    /*Helper to decode canopy encryption.*/
    // console.log(data)
    // console.log(token)
    data = sodium.from_hex(data);
    token = sodium.from_hex(token);

    let nonce = data.slice(0, sodium.crypto_secretbox_NONCEBYTES),
      ciphertext = data.slice(sodium.crypto_secretbox_NONCEBYTES);

    data = sodium.crypto_secretbox_open_easy(ciphertext, nonce, token);

    return sodium.to_string(data);
  }

  async fetch(
    url,
    methodType,
    data = null,
    thisVue = null,
    showLoadingIndicator = true,
    showProgressIndicator = true
  ) {
    let loader;

    if (
      showLoadingIndicator &&
      thisVue != null &&
      this.showingLoadingIndicatorStatus === false
    ) {
      loader = thisVue.$loading.show(loadingConfig);
      this.showingLoadingIndicatorStatus = true;
    }

    if (showProgressIndicator) {
      NProgress.start();
    }

    let $this = this;
    let fetchOptions = {
      method: methodType,
      headers: {},
      mode: "cors",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer"
    };

    if ($this.headers) {
      for (let h in $this.headers) {
        fetchOptions.headers[h] = $this.headers[h];
      }
    }

    if (data != null && data.constructor == Object) {
      fetchOptions["headers"]["Content-Type"] = "application/json";
      fetchOptions["body"] = JSON.stringify(data);
    } else if (data != null && data instanceof FormData) {
      fetchOptions["body"] = data;
    } else if (data != null && data instanceof URLSearchParams) {
      fetchOptions["headers"]["Content-Type"] =
        "application/x-www-form-urlencoded";
      fetchOptions["body"] = data;
    }
    // let authToken = $this.$store.getters["session/getAPIToken"];
    if ($this.authToken != null && $this.authToken != undefined) {
      fetchOptions["headers"]["Authorization"] = this.authToken;
    }
    // fetchOptions["headers"]["Access-Control-Allow-Origin"] = "*";
    // console.log(this.authToken)
    const response = await fetch(url, fetchOptions).catch((error) => {
      NProgress.remove();
      if (showLoadingIndicator && thisVue != null) {
        loader.hide();
        this.showingLoadingIndicatorStatus = false;
      }

      let error_msg = error.message;
      if (error.message.includes("NetworkError")) {
        error_msg = "Network Error. Unable to connect to " + url;
      }
      // $this.$store.dispatch('session/addGlobalAlertMessage', { "message_text": error_msg, "message_type": "danger" });
      // console.log(error_msg);
      return { detail: error_msg, error: true };
    });

    //console.log("response fetch", response);

    if (response == null) {
      NProgress.remove();
      if (showLoadingIndicator && thisVue != null) {
        loader.hide();
        this.showingLoadingIndicatorStatus = false;
      }

      return null;
    }

    let has_error = await response.error;
    //let resp_detail = await response.detail;

    if (has_error) {
      NProgress.remove();
      loader.hide();

      return response;
    }

    let status = await response.status;
    let result;
    let err;
    // console.log(status);
    if ($this.responseType == "json") {
      result = await response.json();
    } else if ($this.responseType == "blob") {
      result = await response.blob();
    } else if ($this.responseType == "stream") {
      result = response.body;
    }
    // console.log($this.responseType)
    // console.log(status);
    // console.log("result", result);

    if (this.secureToken) {
      try {
        result = $this.decodeMsg(result, this.secureToken);
        result = JSON.parse(result);
      } catch (err) {
        // pass
      }
    }
    /************************  SECOND PHASE */

    if (status >= 400 && status <= 500) {
      let x = document.getElementById("nprogress").querySelectorAll(".bar");

      x[0].style.background = "#8b0000";
      x[0].style.boxShadow = "#8b0000";
      x[0].style.borderTopColor = "#8b0000";
      x[0].style.borderLeftColor = "#8b0000";

      if (status == 401) {
        if ($this.refreshTokenFunc != null) {
          // const tokenResponse = await $this.refreshTokenFunc(
          //   ...$this.refreshTokenFuncArgs
          // );
          // $this.authToken = tokenResponse;

          // const tokenResponse = await $this.refreshToken();
          $this.reloginCount += 1;
          // if ($this.reloginCount <= $this.reloginMax) {
          //   await $this.sleep(1000);
          //
          //   // call method again
          //   const response2 = await $this.fetch(url, methodType, data);
          //   console.log("testtttttt" ,response2);
          //   return response2;
          // } else {
          // Exceeded max login retries
          $this.reloginCount = 0;
          sessionStorage.removeItem("sessionKey");
          sessionStorage.removeItem("wsserverKey");

          err = "Session expired";
          if (
            result.detail != undefined &&
            result.detail != "" &&
            result.detail != null
          ) {
            err = result.detail;
          }

          this.thisVue.store.dispatch("session/logoutSession");
          //return result;
          //
          // throw { status: 401, detail: err };
          //}
        } else {
          NProgress.done();
          if (showLoadingIndicator && thisVue != null) {
            loader.hide();
            this.showingLoadingIndicatorStatus = false;
          }

          $this.reloginCount = 0;
          return result;
          /* // return result;
          err = "Session expired";
          this.thisVue.store.dispatch("session/logoutSession");
          // throw { status: 401, detail: err }; */
        }
      }

      if (status == 422) {
        console.log("422", result);
        NProgress.done();
        if (showLoadingIndicator && thisVue != null) {
          loader.hide();
          this.showingLoadingIndicatorStatus = false;
        }

        $this.reloginCount = 0;
        return result;
      }
    }

    NProgress.done();
    if (showLoadingIndicator && thisVue != null) {
      loader.hide();
      this.showingLoadingIndicatorStatus = false;
    }

    $this.reloginCount = 0;
    return result;

    /************************  SECOND PHASE */

    /************************  FIRST PHASE */

    /* if (status == 401) {
      let x = document.getElementById("nprogress").querySelectorAll(".bar");

      x[0].style.background = "#8b0000";
      x[0].style.boxShadow = "#8b0000";
      x[0].style.borderTopColor = "#8b0000";
      x[0].style.borderLeftColor = "#8b0000";
      NProgress.done();
      if (showLoadingIndicator && thisVue != null) {
        loader.hide();
        this.showingLoadingIndicatorStatus = false;
      }

      // refresh token
      if ($this.refreshTokenFunc != null) {
        const tokenResponse = await $this.refreshTokenFunc(
          ...$this.refreshTokenFuncArgs
        );
        $this.authToken = tokenResponse;

        // const tokenResponse = await $this.refreshToken();
        $this.reloginCount += 1;
        if ($this.reloginCount <= $this.reloginMax) {
          await $this.sleep(1000);

          // call method again
          const response2 = await $this.fetch(url, methodType, data);
          // console.log(response2);
          return response2;
        } else {
          // Exceeded max login retries
          $this.reloginCount = 0;
          sessionStorage.removeItem("sessionKey");
          sessionStorage.removeItem("wsserverKey");

          err = "Session expired";
          if (
            result.detail != undefined &&
            result.detail != "" &&
            result.detail != null
          ) {
            err = result.detail;
          }
          // return result;

          this.thisVue.store.dispatch("session/logoutSession");
          // throw { status: 401, detail: err };
        }
      } else {
        $this.reloginCount = 0;
        // return result;
        err = "Session expired";

        this.thisVue.store.dispatch("session/logoutSession");
        // throw { status: 401, detail: err };
      }
    } else {
      if (status >= 400 && status <= 500) {
        let x = document.getElementById("nprogress").querySelectorAll(".bar");

        x[0].style.background = "#8b0000";
        x[0].style.boxShadow = "#8b0000";
        x[0].style.borderTopColor = "#8b0000";
        x[0].style.borderLeftColor = "#8b0000";
      }

      NProgress.done();
      if (showLoadingIndicator && thisVue != null) {
        loader.hide();
        this.showingLoadingIndicatorStatus = false;
      }

      $this.reloginCount = 0;
      return result;
    } */

    /************************  FIRST PHASE */
  }

  async getRequest(
    url,
    params,
    showLoadingIndicator = false,
    showProgressIndicator = true
  ) {
    let request_url = new URL(url);
    if (params != undefined) {
      request_url.search = new URLSearchParams(params);
    }
    let request_url_str = request_url.toString();

    let result = await this.fetch(
      request_url_str,
      "GET",
      null,
      null,
      showLoadingIndicator,
      showProgressIndicator
    );
    return result;
  }

  async postRequest(
    url,
    data,
    showLoadingIndicator = true,
    showProgressIndicator = true
  ) {
    let result = await this.fetch(
      url,
      "POST",
      data,
      this.thisVue,
      showLoadingIndicator,
      showProgressIndicator
    );
    return result;
  }

  async putRequest(
    url,
    data,
    showLoadingIndicator = true,
    showProgressIndicator = true
  ) {
    let result = await this.fetch(
      url,
      "PUT",
      data,
      this.thisVue,
      showLoadingIndicator,
      showProgressIndicator
    );
    return result;
  }

  async deleteRequest(
    url,
    data,
    showLoadingIndicator = true,
    showProgressIndicator = true
  ) {
    let result = await this.fetch(
      url,
      "DELETE",
      data,
      this.thisVue,
      showLoadingIndicator,
      showProgressIndicator
    );
    return result;
  }
}
