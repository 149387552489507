/** VUEX module for case WS data **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");

let caseStates = {
  rawCases: {},
  rawCasesOrder: [],
  cases: {},
  casesOrder: []
};
let copiedCaseStates = JSON.parse(JSON.stringify(caseStates));

export default {
  namespaced: true,
  state: copiedCaseStates,
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },
    // ---- Raw Cases ----
    getRawCaseIDs: (state) => {
      // console.log(state.rawCasesOrder);
      return state.rawCasesOrder;
    },
    getRawCases: (state) => {
      var cases_list = [];
      for (var s in state.rawCasesOrder) {
        var case_id = state.rawCasesOrder[s];
        cases_list.push(state.rawCases[case_id]);
      }
      return cases_list;
    },
    getRawCase: (state) => (case_id) => {
      return state.rawCases[case_id];
    },
    // ---- Cases ----
    getCaseIDs: (state) => {
      return state.casesOrder;
    },
    getCases: (state) => {
      var cases_list = [];
      for (var s in state.casesOrder) {
        var case_id = state.casesOrder[s];
        cases_list.push(state.cases[case_id]);
      }
      return cases_list;
    },
    filterCases: (state, getters) => (filters) => {
      var cases_list = getters.getCases;
      // console.log(cases_list);
      return getters._filter(cases_list, filters);
    },
    getCase: (state) => (case_id) => {
      return state.cases[case_id];
    }
  },
  mutations: {
    ADD_RAW_CASE(state, payload) {
      var case_id = payload.id;
      var case_obj = payload.data;

      if (state.rawCasesOrder.indexOf(case_id) < 0) {
        state.rawCasesOrder.push(case_id);
      }
      state.rawCases[case_id] = case_obj;
    },
    ADD_CASE(state, payload) {
      var case_id = payload.id;
      var case_obj = payload.data;

      if (state.casesOrder.indexOf(case_id) < 0) {
        state.casesOrder.push(case_id);
      }
      state.cases[case_id] = case_obj;
    },
    REMOVE_CASE(state, case_id) {
      var case_index = state.casesOrder.indexOf(case_id);
      if (case_index > 0) {
        try {
          state.casesOrder.splice(case_index, 1);
          delete state.cases[case_id];
        } catch (err) {
          // console.log(err);
        }
      }
    },
    CASE_HOUSEKEEPING(state) {
      var max_cases = 10;
      if (state.casesOrder.length > max_cases) {
        while (state.casesOrder.length > max_cases) {
          var removed_case_id = state.casesOrder.shift();
          try {
            delete state.cases[removed_case_id];
            delete state.rawCases[removed_case_id];
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    RESET_STORE(state) {
      // console.log("Reset Store");
      let originalStates = JSON.parse(JSON.stringify(caseStates));
      for (var s in originalStates) {
        Vue.set(state, s, originalStates[s]);
        // console.log("state ", s, " = ", state[s]);
      }
    }
  },
  actions: {
    addRawCase(context, value) {
      // <-- this action is triggered when `user_message` is emmited on the server
      // console.log(value);
      context.commit("ADD_RAW_CASE", value);
      // context.commit('CASE_HOUSEKEEPING');
    },
    addCase(context, value) {
      // <-- this action is triggered when `user_message` is emmited on the server
      // console.log(value);
      context.commit("ADD_CASE", value);
      // context.commit('CASE_HOUSEKEEPING');
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    }
  }
};
