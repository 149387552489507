import Vue from "vue";

let utils = require("@/assets/global/js/utils.js");

function delay(n) {
  return new Promise(function (resolve) {
    setTimeout(resolve, n * 1000);
  });
}

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    workflows: [],
    filter_workflows: [],
    actions: {},

    selectedWorkflow: {
      pk: null,
      status: null,
      event_type: null,
      subevent_type: null,
      triggers: {},
      activate_date: null,
      deactivate_date: null,
      title: null,
      match: null,
      priority: null
    },
    selectedWorkflowTriggers: {},
    selectedWorkflowActions: [],
    eventTypes: [],
    triggerOptions: [],
    actionOptions: [],

    triggerCategories: {}
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });
        return results;
      }
    },
    getFilterWorkflows: (state, getters) => {
      return state.filter_workflows;
    },
    getWorkflows: (state, getters) => {
      return state.workflows;
    },
    getWorkflowsActions: (state, getters) => {
      return state.actions;
    },
    getSelectedWorkflow: (state, getters) => {
      return state.selectedWorkflow;
    },
    getSelectedWorkflowTriggers: (state, getters) => {
      return state.selectedWorkflowTriggers;
    },
    getSelectedWorkflowActions: (state, getters) => {
      return state.selectedWorkflowActions;
    },
    getSelectedWorkflowTitle: (state, getters) => {
      return state.selectedWorkflow.title;
    },
    getSelectedWorkflowEventType: (state, getters) => {
      return state.selectedWorkflow.event_type;
    },
    getSelectedWorkflowSubEventType: (state, getters) => {
      /** hardcoded to 0 for null **/
      return state.selectedWorkflow.subevent_type
        ? state.selectedWorkflow.subevent_type
        : 0;
    },
    getEventTypes: (state, getters) => {
      return state.eventTypes;
    },
    getSubEventTypes: (state, getters) => {
      for (let i = 0; i < state.eventTypes.length; i++) {
        if (state.eventTypes[i].value === state.selectedWorkflow.event_type) {
          // return state.eventTypes[i].subevents;
          /** hardcoded to 0 for null **/
          return state.eventTypes[i].subevents.map((subevent) => {
            return {
              ...subevent,
              value: subevent.value ? subevent.value : 0
            };
          });
        }
      }
      return [];
    },
    getActionOptions: (state, getters) => {
      return state.actionOptions;
    },
    getTriggerOptions: (state, getters) => {
      return state.triggerOptions;
    },

    getTriggerCategories: (state, getters) => {
      return state.triggerCategories;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },

    SET_WORKFLOWS(state, payload) {
      Vue.set(state, "workflows", payload);
    },
    SET_WORKFLOWS_FILTER(state, payload) {
      Vue.set(state, "filter_workflows", payload);
    },
    SET_SELECTED_WORKFLOW(state, payload) {
      Vue.set(state, "selectedWorkflow", payload);
    },
    SET_SELECTED_WORKFLOW_TRIGGERS(state, payload) {
      Vue.set(state, "selectedWorkflowTriggers", payload);
    },
    SET_SELECTED_WORKFLOW_ACTIONS(state, payload) {
      Vue.set(state, "selectedWorkflowActions", payload);
    },
    SET_EVENT_TYPES(state, payload) {
      Vue.set(state, "eventTypes", payload);
    },
    SET_TRIGGER_OPTIONS(state, payload) {
      Vue.set(state, "triggerOptions", payload);
    },
    SET_ACTION_OPTIONS(state, payload) {
      Vue.set(state, "actionOptions", payload);
    },
    SET_WORKFLOWS_ACTIONS(state, payload) {
      Vue.set(state.actions, payload.pk, payload.data);
    },
    SET_SELECTED_WORKFLOW_EVENT_TYPE(state, payload) {
      let data = {
        ...state.selectedWorkflow,
        event_type: payload
      };
      //console.log("SET_SELECTED_WORKFLOW_EVENT_TYPE", data);
      Vue.set(state, "selectedWorkflow", data);
    },
    SET_SELECTED_WORKFLOW_SUB_EVENT_TYPE(state, payload) {
      let data = {
        ...state.selectedWorkflow,
        subevent_type: payload
      };
      //console.log("SET_SELECTED_WORKFLOW_SUB_EVENT_TYPE", data);
      Vue.set(state, "selectedWorkflow", data);
    },
    SET_TRIGGER_CATEGORIES(state, payload) {
      Vue.set(state, "triggerCategories", payload);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    setState: (context, payload) => {
      context.commit("SET_STATE", payload);
    },
    setSelectedWorkflowEventTypeObj: (context, payload) => {
      context.commit("SET_SELECTED_WORKFLOW_EVENT_TYPE", payload.value);
    },
    setSelectedWorkflowSubEventTypeObj: (context, payload) => {
      context.commit("SET_SELECTED_WORKFLOW_SUB_EVENT_TYPE", payload.value);
    },

    fetchWorkflows: async ({ dispatch, commit, state, rootGetters }, value) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/workflows/";
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client
          .getRequest(API_URL)
          .then(async (data) => {
            let validUrl = "";
            let result = data.result;
            let error = null;
            if (data.detail != undefined) {
              error = data.detail;
            }

            //console.log("Object.keys(result).length", result);
            if (result != undefined) {
              if (Object.keys(result).length !== 0) {
                outer_loop: for (let key in result) {
                  //console.log("result[key]", result[key]);
                  if (
                    result[key] != undefined &&
                    Array.isArray(result[key]["content"])
                  ) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
                if (
                  result[validUrl] != undefined &&
                  Array.isArray(result[validUrl]["content"])
                ) {
                  let updatedWorkflows = result[validUrl]["content"].map(
                    function (element) {
                      return {
                        ...element,
                        boolStatus: element.status === 1
                      };
                    }
                  );

                  updatedWorkflows.sort((a, b) => a.pk - b.pk);

                  commit("SET_WORKFLOWS", updatedWorkflows);

                  for (let i = 0; i < state.workflows.length; i++) {
                    let API_URL2 =
                      API_URL + `${state.workflows[i].pk}` + "/actions/";
                    try {
                      await client
                        .getRequest(API_URL2)
                        .then((data) => {
                          let validUrl = "";
                          let result = data.result;
                          let error2 = null;
                          if (data.detail != undefined) {
                            error2 = data.detail;
                          }
                          if (result != undefined) {
                            if (Object.keys(result).length !== 0) {
                              outer_loop: for (let key in result) {
                                //console.log("result[key]", result[key]);

                                if (
                                  result[key] != undefined &&
                                  Array.isArray(result[key]["content"])
                                ) {
                                  validUrl = key;
                                  break outer_loop;
                                }
                              }
                              if (
                                result[validUrl] != undefined &&
                                Array.isArray(result[validUrl]["content"])
                              ) {
                                commit("SET_WORKFLOWS_ACTIONS", {
                                  pk: state.workflows[i].pk,
                                  data: result[validUrl]["content"]
                                });
                              }
                            } else {
                              //
                            }
                          } else {
                            //
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } catch (e) {
                      console.log(e);
                    }
                  }
                }
              } else {
                //alert("error", error);
              }
            } else {
              /* dispatch(
                "session/addGlobalAlertMessage",
                {
                  message_text: error,
                  message_type: "danger"
                },
                { root: true }
              ); */

              commit("SET_WORKFLOWS_ACTIONS", {
                pk: null,
                data: null
              });

              //alert("error", error);
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/${value}/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      /* commit("SET_SELECTED_WORKFLOW", {
        pk: null,
        status: null,
        event_type: null,
        subevent_type: null,
        triggers: {},
        activate_date: null,
        deactivate_date: null,
        title: null,
        match: null,
        priority: null
      }); */

      try {
        client
          .getRequest(API_URL)
          .then((data) => {
            let validUrl = "";
            let result = data.result;
            let error = null;
            if (data.detail != undefined) {
              error = data.detail[0].msg;
            }

            if (result != undefined) {
              if (Object.keys(result).length !== 0) {
                outer_loop: for (let key in result) {
                  if (result[key] != undefined) {
                    validUrl = key;
                    break outer_loop;
                  }
                }

                if (result[validUrl] != undefined) {
                  commit("SET_SELECTED_WORKFLOW", result[validUrl]);
                }
              } else {
                //
              }
            } else {
              /* dispatch(
                "session/addGlobalAlertMessage",
                {
                  message_text: error,
                  message_type: "danger"
                },
                { root: true }
              ); */

              commit("SET_SELECTED_WORKFLOW", {
                pk: null,
                status: null,
                event_type: null,
                subevent_type: null,
                triggers: {},
                activate_date: null,
                deactivate_date: null,
                title: null,
                match: null,
                priority: null
              });
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchFilterWorkflows: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = apiserverURL + "/api/workflows/" + value.pk + "/";
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client
          .getRequest(API_URL)
          .then((data) => {
            let result = data.result;
            let validUrl = "";

            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl] != undefined) {
              commit("SET_WORKFLOWS_FILTER", result[validUrl]);
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchWorkflowTriggers: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/${value}/triggers/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        client
          .getRequest(API_URL)
          .then((data) => {
            let validUrl = "";
            let result = data.result;
            let error = null;
            if (data.detail != undefined) {
              error = data.detail[0].msg;
            }

            if (result != undefined) {
              if (Object.keys(result).length !== 0) {
                outer_loop: for (let key in result) {
                  if (result[key] != undefined) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
                if (result[validUrl] != undefined) {
                  commit(
                    "SET_SELECTED_WORKFLOW_TRIGGERS",
                    result[validUrl]["triggers"]
                  );
                }
              } else {
                //
              }
            } else {
              /* dispatch(
                "session/addGlobalAlertMessage",
                {
                  message_text: error,
                  message_type: "danger"
                },
                { root: true }
              ); */

              let object = {};
              commit("SET_SELECTED_WORKFLOW_TRIGGERS", object);
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchWorkflowActions: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/${value}/actions/`;

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      try {
        client
          .getRequest(API_URL)
          .then((data) => {
            let validUrl = "";
            let result = data.result;
            let error = null;
            if (data.detail != undefined) {
              error = data.detail;
            }

            if (result != undefined) {
              if (Object.keys(result).length !== 0) {
                outer_loop: for (let key in result) {
                  //console.log("result[key]", result[key]);

                  if (
                    result[key] != undefined &&
                    Array.isArray(result[key]["content"])
                  ) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
                if (
                  result[validUrl] != undefined &&
                  Array.isArray(result[validUrl]["content"])
                ) {
                  commit(
                    "SET_SELECTED_WORKFLOW_ACTIONS",
                    result[validUrl]["content"]
                  );
                }
              } else {
                //
              }
            } else {
              /* dispatch(
                "session/addGlobalAlertMessage",
                {
                  message_text: error,
                  message_type: "danger"
                },
                { root: true }
              ); */

              let object = {};
              commit("SET_SELECTED_WORKFLOW_ACTIONS", object);
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchEventTypes: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/event-types/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        client
          .getRequest(API_URL)
          .then((data) => {
            let validUrl = "";
            let result = data.result;
            let error = null;
            if (data.detail != undefined) {
              error = data.detail[0].msg;
            }

            if (result != undefined) {
              if (Object.keys(result).length !== 0) {
                outer_loop: for (let key in result) {
                  if (result[key] != undefined) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
                if (result[validUrl] != undefined) {
                  //console.log("result[validUrl]", result[validUrl]);

                  commit("SET_EVENT_TYPES", result[validUrl]);
                }
              } else {
                //
              }
            } else {
              /* dispatch(
                "session/addGlobalAlertMessage",
                {
                  message_text: error,
                  message_type: "danger"
                },
                { root: true }
              ); */

              let object = {};
              commit("SET_EVENT_TYPES", object);
            }
          })
          .catch((err) => {
            //alert("error", err);
            console.log(err);
          });
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchTriggerOptions: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/triggers/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        if (value != null) {
          client
            .getRequest(API_URL, { event_type: value })
            .then((data) => {
              let validUrl = "";
              let result = data.result;
              let error = null;
              if (data.detail != undefined) {
                error = data.detail[0].msg;
              }

              if (result != undefined) {
                if (Object.keys(result).length !== 0) {
                  outer_loop: for (let key in result) {
                    if (result[key] != undefined) {
                      validUrl = key;
                      break outer_loop;
                    }
                  }

                  if (result[validUrl] != undefined) {
                    //console.log("result[validUrl]", result[validUrl]);

                    commit("SET_TRIGGER_OPTIONS", result[validUrl]);
                  }
                } else {
                  //
                }
              } else {
                /* dispatch(
                  "session/addGlobalAlertMessage",
                  {
                    message_text: error,
                    message_type: "danger"
                  },
                  { root: true }
                ); */

                let object = {};
                commit("SET_TRIGGER_OPTIONS", object);
              }
            })
            .catch((err) => {
              //alert("error", err);
              console.log(err);
            });
        } else {
          let object = {};
          commit("SET_TRIGGER_OPTIONS", object);
        }
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchActionOptions: async (
      { dispatch, commit, state, rootGetters },
      value
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/actions/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        if (value != null) {
          client
            .getRequest(API_URL, { event_type: value })
            .then((data) => {
              let validUrl = "";
              let result = data.result;
              let error = null;
              if (data.detail != undefined) {
                error = data.detail;
              }

              if (result != undefined) {
                if (Object.keys(result).length !== 0) {
                  outer_loop: for (let key in result) {
                    if (result[key] != undefined) {
                      validUrl = key;
                      break outer_loop;
                    }
                  }
                  if (result[validUrl] != undefined) {
                    //console.log("result[validUrl]", result[validUrl]);

                    commit("SET_ACTION_OPTIONS", result[validUrl]);
                  }
                } else {
                  //
                }
              } else {
                /* dispatch(
                  "session/addGlobalAlertMessage",
                  {
                    message_text: error,
                    message_type: "danger"
                  },
                  { root: true }
                ); */

                let object = {};
                commit("SET_ACTION_OPTIONS", object);
              }
            })
            .catch((err) => {
              //alert("error", err);
              console.log(err);
            });
        } else {
          let object = {};
          commit("SET_ACTION_OPTIONS", object);
        }
      } catch (e) {
        //alert("error", e);
        console.log(e);
      }
    },
    fetchTriggerCategories: async ({
      dispatch,
      commit,
      state,
      rootGetters
    }) => {
      let mapper = [
        {
          trigger: "zone",
          category: "zones"
        },
        {
          trigger: "zone_risk",
          category: "zone-risks"
        },
        {
          trigger: "camera",
          category: "cameras"
        },
        {
          trigger: "camera",
          category: "cameras"
        },
        {
          trigger: "library",
          category: "libraries"
        },
        {
          trigger: "library_alert",
          category: "library-alerts"
        },
        {
          trigger: "library_alert",
          category: "library-alerts"
        },
        // {
        //   trigger: "libraryimages",
        //   category: null
        // },
        //
        // {
        //   trigger: "libraryimage-priorities",
        //   category: null
        // },
        {
          trigger: "acs_server",
          category: "acs-servers"
        },
        {
          trigger: "controller",
          category: "controllers"
        }
      ];

      let triggerCategories = {};

      const apiserverURL = rootGetters["session/getAPIServerURL"];

      for (let i = 0; i < mapper.length; i++) {
        const API_URL = `${apiserverURL}/api/workflows/triggers/${mapper[i].category}/`;
        const client = await dispatch("session/getAjaxFetchClient", null, {
          root: true
        });
        try {
          let data = await client.getRequest(API_URL);
          let validUrl = "";
          let result = data.result;
          let error = null;
          if (data.detail != undefined) {
            error = data.detail;
          }

          //console.log("result",result);

          if (result != undefined) {
            if (Object.keys(result).length !== 0) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
              if (result[validUrl] != undefined) {
                triggerCategories = {
                  ...triggerCategories,
                  [mapper[i].trigger]: result[validUrl]
                };
              } else {
                triggerCategories = {
                  ...triggerCategories,
                  [mapper[i].trigger]: []
                };
              }
            } else {
              triggerCategories = {
                ...triggerCategories,
                [mapper[i].trigger]: []
              };
            }

            //console.log("triggerCategories", triggerCategories);
            commit("SET_TRIGGER_CATEGORIES", triggerCategories);
          } else {
            /* dispatch(
              "session/addGlobalAlertMessage",
              {
                message_text: error,
                message_type: "danger"
              },
              { root: true }
            ); */
            //console.log("triggerCategories", triggerCategories);
            triggerCategories = {};
            commit("SET_TRIGGER_CATEGORIES", triggerCategories);
          }
        } catch (e) {
          console.log(e);
        }
      }
    },

    addNewTriggersForSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      let API_URL = null;
      if (state.selectedWorkflow.pk != null) {
        API_URL = `${apiserverURL}/api/workflows/${state.selectedWorkflow.pk}/triggers/`;
      } else {
        API_URL = `${apiserverURL}/api/workflows/${payload.workflow.pk}/triggers/`;
      }
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      //console.log("addNewTriggersForSelectedWorkflow payload", payload);

      for (let i = 0; i < payload.triggers.length; i++) {
        let value = null;

        try {
          value = payload.triggers[i].data.value;
        } catch (e) {
          //
        }

        try {
          await delay(1);

          client
            .postRequest(API_URL, {
              trigger: payload.triggers[i].value,
              value: value
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    addNewActionsForSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      let API_URL = null;
      if (state.selectedWorkflow.pk != null) {
        API_URL = `${apiserverURL}/api/workflows/${state.selectedWorkflow.pk}/actions/`;
      } else {
        API_URL = `${apiserverURL}/api/workflows/${payload.workflow.pk}/actions/`;
      }

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      //console.log("addNewActionsForSelectedWorkflow payload", payload);
      for (let i = 0; i < payload.actions.length; i++) {
        let data = {};
        if (Object.prototype.hasOwnProperty.call(payload.actions[i], "data")) {
          data = payload.actions[i].data;
        }

        try {
          client
            .postRequest(API_URL, {
              action: payload.actions[i].value,
              data: data
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (e) {
          console.log(e);
        }
      }
    },
    updateSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      let API_URL = null;
      if (state.selectedWorkflow.pk != null) {
        API_URL = `${apiserverURL}/api/workflows/${state.selectedWorkflow.pk}/`;
      } else {
        API_URL = `${apiserverURL}/api/workflows/${payload.workflow.pk}/`;
      }

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        client
          .putRequest(API_URL, payload.workflow)
          .then(async (res) => {
            //console.log("res updateSelectedWorkflow", res);

            /* dispatch("deleteAllTriggersForSelectedWorkflow");
            dispatch("deleteAllActionsForSelectedWorkflow"); */

            dispatch("deleteAllTriggersForSelectedWorkflow", payload);
            dispatch("deleteAllActionsForSelectedWorkflow", payload);

            await delay(1);

            dispatch("addNewTriggersForSelectedWorkflow", payload);
            dispatch("addNewActionsForSelectedWorkflow", payload);
            /* dispatch("addNewTriggersForSelectedWorkflow", payload.triggers);
            dispatch("addNewActionsForSelectedWorkflow", payload.actions); */

            dispatch(
              "session/addGlobalAlertMessage",
              {
                message_text: payload.message, //`Workflow is updated`,
                message_type: "success"
              },
              { root: true }
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    },
    updateSelectedWorkflowStatus: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/${payload.pk}/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        client
          .putRequest(API_URL, { status: payload.status })
          .then(async (res) => {
            dispatch("fetchWorkflows");

            dispatch(
              "session/addGlobalAlertMessage",
              {
                message_text: `Workflow is updated`,
                message_type: "success"
              },
              { root: true }
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    },
    deleteSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      const API_URL = `${apiserverURL}/api/workflows/${payload.pk}/`;
      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });
      try {
        client
          .deleteRequest(API_URL)
          .then(async (res) => {
            dispatch(
              "session/addGlobalAlertMessage",
              {
                message_text: `Workflow is deleted`,
                message_type: "success"
              },
              { root: true }
            );

            dispatch("fetchWorkflows");
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    },
    deleteAllTriggersForSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      let API_URL = null;
      if (state.selectedWorkflow.pk != null) {
        API_URL = `${apiserverURL}/api/workflows/${state.selectedWorkflow.pk}/triggers/`;
      } else {
        API_URL = `${apiserverURL}/api/workflows/${payload.workflow.pk}/triggers/`;
      }

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      for (let key in state.selectedWorkflowTriggers) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.selectedWorkflowTriggers,
            key
          )
        ) {
          if (key === "schedule") {
            //console.log("delete", key, state.selectedWorkflowTriggers[key]);

            try {
              client
                .deleteRequest(API_URL, {
                  trigger: "schedule"
                })
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (e) {
              console.log(e);
            }
          } else {
            try {
              client
                .deleteRequest(API_URL, {
                  trigger: key
                })
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    },
    deleteAllActionsForSelectedWorkflow: async (
      { dispatch, commit, state, rootGetters },
      payload
    ) => {
      const apiserverURL = rootGetters["session/getAPIServerURL"];
      let API_URL = null;
      if (state.selectedWorkflow.pk != null) {
        API_URL = `${apiserverURL}/api/workflows/${state.selectedWorkflow.pk}/actions/`;
      } else {
        API_URL = `${apiserverURL}/api/workflows/${payload.workflow.pk}/actions/`;
      }

      const client = await dispatch("session/getAjaxFetchClient", null, {
        root: true
      });

      for (let j = 0; j < state.selectedWorkflowActions.length; j++) {
        //console.log("delete", state.selectedWorkflowActions[j]);

        try {
          client
            .deleteRequest(API_URL, {
              pk: state.selectedWorkflowActions[j].pk
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
};
