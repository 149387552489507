/** VUEX module for log WS data **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");

let logStates = {
  logs: {},
  logsOrder: [],
  discardedLogIDs: []
};

let copiedLogStates = JSON.parse(JSON.stringify(logStates));

export default {
  namespaced: true,
  state: copiedLogStates,
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },
    // ---- Logs ----
    getLogIDs: (state) => {
      return state.logsOrder;
    },
    getLogs: (state) => {
      var logs_list = [];
      for (var s in state.logsOrder) {
        var log_id = String(state.logsOrder[s]);
        logs_list.push(state.logs[log_id]);
      }
      return logs_list;
    },
    getLogsDict: (state) => {
      return state.logs;
    },
    filterLogs: (state, getters) => (filters) => {
      var logs_list = getters.getLogs;
      // console.log(logs_list);
      // console.log(filters);
      return getters._filter(logs_list, filters);
    },
    getLog: (state) => (log_id) => {
      return state.logs[String(log_id)];
    },
    getDiscardedLogIDs: (state) => {
      return state.discardedLogIDs;
    }
  },
  mutations: {
    ADD_LOG(state, payload) {
      var log_id = String(payload.id);
      var log_obj = payload.data;
      if (state.logsOrder.indexOf(log_id) < 0) {
        state.logsOrder.push(log_id);
      }
      // state.logs[log_id] = log_obj;
      Vue.set(state.logs, log_id, log_obj);
    },
    REMOVE_LOG(state, log_id) {
      let log_id_str = String(log_id);
      var log_index = state.logsOrder.indexOf(log_id_str);
      if (log_index > 0) {
        try {
          state.logsOrder.splice(log_index, 1);
          delete state.logs[log_id_str];
        } catch (err) {
          // console.log(err);
        }
      }
    },
    DISCARD_LOG(state, log_id) {
      let log_id_str = String(log_id);
      var log = state.logs[log_id_str];
      // console.log("Discarding", log_id_str, log)
      if (log != undefined) {
        log.is_discarded = true;
        Vue.set(state.logs, log_id_str, log);
        // console.log("Discarded from logWSStore", state.logs[log_id_str].is_discarded);
        state.discardedLogIDs.push(log_id_str);
      }
    },
    LOG_HOUSEKEEPING(state) {
      var max_logs = 10000;
      if (state.logsOrder.length > max_logs) {
        while (state.logsOrder.length > max_logs) {
          var removed_log_id = state.logsOrder.shift();
          try {
            delete state.logs[String(removed_log_id)];
          } catch (err) {
            // console.log(err);
          }
        }
      }
    },
    RESET_STORE(state) {
      // console.log("Reset Store");
      let originalStates = JSON.parse(JSON.stringify(logStates));
      for (var s in originalStates) {
        Vue.set(state, s, originalStates[s]);
        // console.log("state ", s, " = ", state[s]);
      }
    }
  },
  actions: {
    addLog(context, value) {
      // console.log(value);
      context.commit("ADD_LOG", value);
      context.commit("LOG_HOUSEKEEPING");
    },
    discardLog(context, log_id) {
      context.commit("DISCARD_LOG", log_id);
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    }
  }
};
