/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    users: {},
    usersOrder: []
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },

    getUsers: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.usersOrder) {
        var obj_id = state.usersOrder[i];
        obj_list.push(state.users[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }

      return result;
    },
    getUser: (state, getters) => (id) => {
      var result = state.users[id];
      return result;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    ADD_USER(state, payload) {
      var id = String(payload.person_no);
      if (state.usersOrder.indexOf(id) < 0) {
        state.usersOrder.push(id);
      }
      Vue.set(state.users, id, payload);
    },
    UPDATE_USER(state, payload) {
      var person_no = String(payload.person_no);
      var update_data = payload.data;
      var obj = state.users[person_no];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.users, person_no, obj);
    },
    DELETE_USER(state, payload) {
      var person_no2 = String(payload.person_no);
      var index = state.usersOrder.indexOf(person_no2);
      if (index >= 0) {
        state.usersOrder.splice(index, 1);
        delete state.users[person_no2];
      }
    },
    DELETE_USER_CARD(state, payload) {
      // console.log("DELETE_USER_CARD");
      // console.log("payload", payload);

      let person_no = String(payload.person_no);

      // console.log("person_no", person_no);

      let personObj = state.users[person_no];
      //
      // console.log("personObj", personObj);

      if (payload.type === "lift-card") {
        let cards = personObj.lift_cards.filter((card) => {
          return card.card_no !== payload.card_no;
        });

        // console.log("cards");
        // console.log(cards);
        personObj.lift_cards = cards;
      } else {
        let cards = personObj.cards.filter((card) => {
          return card.card_no !== payload.card_no;
        });
        // console.log("cards");
        // console.log(cards);
        personObj.cards = cards;
      }
      //
      // console.log("updated personObj");
      // console.log(personObj);
      Vue.set(state.users, person_no, personObj);
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addUser: (context, value) => {
      context.commit("ADD_USER", value);
    },
    updateUser: (context, value) => {
      context.commit("UPDATE_USER", value);
    },
    deleteUser: (context, value) => {
      context.commit("DELETE_USER", value);
    },
    deleteUserCard: (context, value) => {
      context.commit("DELETE_USER_CARD", value);
    }
  }
};
