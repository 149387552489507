<template>
  <span>
    <!-- <slot :days="days" :hours="hours" :minutes="minutes" :seconds="seconds">{{days}} days {{hours}} hours {{ minutes}} minutes {{ seconds}} seconds</slot> -->
    {{ timesince }}
  </span>
</template>

<script>
import moment from "moment";
export default {
  name: "Timesince",
  props: {
    date: {
      required: true
    }
  },
  data: function () {
    return {
      interval: null,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervals: {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      },
      timesince: ""
    };
  },
  mounted: function () {
    this.interval = setInterval(() => {
      this.updateDiffs();
    }, 1000);
    this.updateDiffs();
  },
  destroyed: function () {
    clearInterval(this.interval);
  },
  methods: {
    updateDiffs() {
      var date_from_isostring = moment(this.date);
      var timesince_str = date_from_isostring.fromNow();
      this.timesince = timesince_str;

      // let diff = Math.abs(Date.now() - this.date.getTime());
      // this.days = Math.floor(diff / this.intervals.day);
      // diff -= this.days * this.intervals.day;
      // this.hours = Math.floor(diff / this.intervals.hour);
      // diff -= this.hours * this.intervals.hour;
      // this.minutes = Math.floor(diff / this.intervals.minute);
      // diff -= this.minutes * this.intervals.minute;
      // this.seconds = Math.floor(diff / this.intervals.second);
    }
  }
};
</script>
