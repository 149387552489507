import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

import sessionModule from "./sessionModule";
import psimModule from "./psimModule";
import deviceModule from "./deviceModule";
import alertWSStore from "./alertWSStore";
import logWSStore from "./logWSStore";
import caseWSStore from "./caseWSStore";
import settingUserModule from "./settingUserModule";
import auditlogWSStore from "./auditlogWS";
import vmsVisitorsModule from "./vmsVisitorsModule";
import companyModule from "./companyModule";
import hostModule from "./hostModule";
import visitModule from "./vmsVisitsModule";
import monitoringModule from "./monitoringModule";
import vmsPreferencesModule from "./vmsPreferencesModule";
import acsUserModule from "./acsUserModule";
import vmsDashboardModule from "./vmsDashboardModule";
import kiosk2Module from "./kiosk2Module";
import publicModule from "./publicModule";
import vmsRegistration2Module from "./vmsRegistration2Module";
import vmsPreferences2Module from "./vmsPreferences2Module";
import vmsSettingsModule from "./vmsSettingsModule";
import workflowModule from "./workflowModule";
import caseModule from "./caseModule";

// TOCLEAN
// import settingCompanyModule from '@/store/settingCompanyModule'

var appVersion = "1.3";

export default new Vuex.Store({
  state: {
    darkEnabled: false,
    app_version: appVersion
  },
  getters: {
    isDarkEnabled: (state) => {
      return state.darkEnabled;
    }
  },
  mutations: {
    TOGGLE_DARK(state) {
      // mutate state
      state.darkEnabled = !state.darkEnabled;
      // console.log(localStorage);
      localStorage.setItem("ps_dark", state.darkEnabled);
    },
    SET_DARK(state, value) {
      // mutate state
      state.darkEnabled = value;
      // console.log(localStorage);
      localStorage.setItem("ps_dark", state.darkEnabled);
    }
  },
  actions: {
    toggleDarkMode: (context) => {
      context.commit("TOGGLE_DARK");
    },
    setDarkMode: (context, value) => {
      context.commit("SET_DARK", value);
    }
  },
  modules: {
    session: sessionModule,
    psim: psimModule,
    device: deviceModule,
    alertWS: alertWSStore,
    logWS: logWSStore,
    auditlogWS: auditlogWSStore,
    monitor: monitoringModule,
    caseWS: caseWSStore,
    users: settingUserModule,
    visit: vmsDashboardModule,
    visitor: vmsVisitorsModule,
    acsUsers: acsUserModule,
    vmsPreferences: vmsPreferencesModule,
    company: companyModule,
    host: hostModule,
    visits: visitModule,
    kiosk2: kiosk2Module,
    public: publicModule,
    vmsRegistration2: vmsRegistration2Module,
    vmsPreferences2Module: vmsPreferences2Module,
    vmsSettingsModule: vmsSettingsModule,
    workflowModule: workflowModule,
    case: caseModule
    // TOCLEAN
    // companys: settingCompanyModule
  }
});
