<template>
  <footer
    class="page-footer fixed-bottom"
    :class="[isExpandMode ? 'expand-mode' : '']"
  >
    <div class="p-2 d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <b-row v-if="this.getCurrentUser.is_admin || this.role_operator">
          <b-col md="3">Services</b-col>
          <b-col md="3" v-if="statusOK"
            ><i class="fa fa-check-circle" style="color: green"></i>OK</b-col
          >
          <b-col md="5" v-if="statusWarning">
            <i class="fa fa-exclamation-triangle" style="color: goldenrod"></i
            >Needs Attention
          </b-col>
          <b-col md="4" v-if="statusProblem"
            ><i class="fa fa-exclamation-circle" style="color: orangered"></i
            >Problem</b-col
          >
          <b-col md="3"
            ><router-link
              :to="{
                name: 'services-monitoring'
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a :href="href" @click="navigate"
                ><span style="color: blue"
                  ><u><b>[details_report]</b></u></span
                >
              </a>
            </router-link></b-col
          >
        </b-row>
      </div>
      <div class="d-flex justify-content-end">
        <b-row no-gutters>
          <b-col md="3" class="d-flex justify-content-center">
            <div class="ep-logo"></div>
          </b-col>
          <b-col md="9" class="text-center">
            <div>
              <small>
                Copyright © {{ current_year }} Entrypass Corporation. All Rights
                Reserved.
                <a href="http://www.entrypass.net">www.entrypass.net</a>
              </small>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

let current = moment().diff(moment().startOf("day"), "minutes");

export default {
  data() {
    let current_year = new Date().getFullYear();

    return {
      isExpandMode: false,
      current_year: current_year,

      role_operator: false,
      rolesList: {},

      statusOK: false,
      statusProblem: false,
      statusWarning: false,

      monitor: []
    };
  },
  computed: {
    ...mapGetters({
      getWSServerURL: "session/getWSServerURL",
      getAPIServerURL: "session/getAPIServerURL",
      getCurrentUser: "session/getCurrentUser"
    })
  },
  mounted: function () {
    this.getRolesList();
    this.getMonitorList();
    this.timer();
  },
  methods: {
    timer() {
      let that = this;
      console.log("current", current);
      let intervalId = window.setInterval(function () {
        //console.log("polling...", intervalId);
        that.getMonitorList();
      }, 30000);
    },
    getRolesList: function () {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/roles/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result != undefined && Array.isArray(result)) {
            for (let key in result) {
              if (result[key].name == "VMS Operator") {
                this.rolesList = result[key];
              }
            }
          }

          if (this.rolesList.id == this.getCurrentUser.role_id) {
            this.role_operator = true;
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
        });
    },

    getMonitorList: function () {
      this.monitor = [];
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/api/monitorservice/status/ipmonitor/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          console.log("qqqqqqqq", data);
          let validUrl = null;
          if (data.result != undefined) {
            outer_loop: for (let key in data.result) {
              if (data.result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          let monitor = data.result[validUrl].result;

          if (monitor != undefined) {
            for (let key2 in monitor) {
              if (monitor[key2] != undefined) {
                outer_loop: for (let key3 in monitor[key2]) {
                  let url = "http://127.0.0.1:8001";
                  let urls = url.substr(0, 17);

                  let url2 = key3;
                  url2 = url2.substr(0, 17);

                  if (urls != url2) {
                    if (monitor[key2][key3] != undefined) {
                      if (monitor[key2][key3]) {
                        this.monitor.push(monitor[key2][key3]);
                      }
                      break outer_loop;
                    }
                  }
                }
              }
            }
          }

          let count = 0;

          for (let key4 in this.monitor) {
            if (this.monitor[key4].status == 1) {
              count++;
            }
          }

          if (count == this.monitor.length) {
            this.statusOK = true;
            this.statusWarning = false;
            this.statusProblem = false;
          }

          if (count < this.monitor.length) {
            this.statusOK = false;
            this.statusWarning = true;
            this.statusProblem = false;
          }

          if (count == 0) {
            this.statusOK = false;
            this.statusWarning = false;
            this.statusProblem = true;
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/logoutSession");
          console.log(err);
          $this.busy = false;
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.detail,
          //   message_type: "danger"
          // });
        });
    }
  }
};
</script>
