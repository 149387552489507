/** VUEX module for device components **/
import Vue from "vue";
let utils = require("@/assets/global/js/utils.js");
export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    cases: {},
    casesOrder: [],

    actions: {},
    actionsOrder: []
  },
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },

    getCases: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.casesOrder) {
        var obj_id = state.casesOrder[i];
        obj_list.push(state.cases[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }

      return result;
    },
    getCase: (state, getters) => (id) => {
      var result = state.cases[id];
      return result;
    },

    getCaseActions: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.actionsOrder) {
        var obj_id = state.actionsOrder[i];
        obj_list.push(state.actions[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }

      return result;
    },
    getCaseAction: (state, getters) => (id) => {
      var result = state.actions[id];
      return result;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },

    ADD_CASE(state, payload) {
      var id = String(payload.id);
      if (state.casesOrder.indexOf(id) < 0) {
        state.casesOrder.push(id);
      }
      Vue.set(state.cases, id, payload);
    },
    UPDATE_CASE(state, payload) {
      var id = String(payload.id);
      var update_data = payload.data;
      var obj = state.cases[id];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.cases, id, obj);
    },
    DELETE_CASE(state, id) {
      var id2 = String(id);
      var index = state.casesOrder.indexOf(id2);
      if (index >= 0) {
        state.casesOrder.splice(index, 1);
        delete state.cases[id2];
      }
    },
    RESET_CASE(state) {
      state.casesOrder = [];
      state.cases = {};
    },

    ADD_CASE_ACTION(state, payload) {
      var id = String(payload.id);
      if (state.actionsOrder.indexOf(id) < 0) {
        state.actionsOrder.push(id);
      }
      Vue.set(state.actions, id, payload);
    },
    UPDATE_CASE_ACTION(state, payload) {
      var id = String(payload.id);
      var update_data = payload.data;
      var obj = state.actions[id];
      if (obj != undefined) {
        for (var d in update_data) {
          obj[d] = update_data[d];
        }
      }

      Vue.set(state.actions, id, obj);
    },
    DELETE_CASE_ACTION(state, id) {
      var id2 = String(id);
      var index = state.actionsOrder.indexOf(id2);
      if (index >= 0) {
        state.actionsOrder.splice(index, 1);
        delete state.actions[id2];
      }
    },
    RESET_CASE_ACTION(state) {
      state.actionsOrder = [];
      state.actions = {};
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addCase: (context, value) => {
      context.commit("ADD_CASE", value);
    },
    updateCase: (context, value) => {
      context.commit("UPDATE_CASE", value);
    },
    deleteCase: (context, value) => {
      context.commit("DELETE_CASE", value);
    },
    resetCase: (context, value) => {
      context.commit("RESET_CASE", value);
    },

    addCaseAction: (context, value) => {
      context.commit("ADD_CASE_ACTION", value);
    },
    updateCaseAction: (context, value) => {
      context.commit("UPDATE_CASE_ACTION", value);
    },
    deleteCaseAction: (context, value) => {
      context.commit("DELETE_CASE_ACTION", value);
    },
    resetCaseAction: (context, value) => {
      context.commit("RESET_CASE_ACTION", value);
    }
  }
};
