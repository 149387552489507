import Vue from "vue";

var visitStates = {
  intervalId: null,
  checks: [],
  expected: []
};

let copiedVisitStates = JSON.parse(JSON.stringify(visitStates));

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: copiedVisitStates,
  // -----------------------------------------------------------------
  getters: {
    getIntervalId: (state) => {
      return state.intervalId;
    },

    getCheckIn: (state) => {
      let checkIn = [];
      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          ) &&
          (state.checks[i].status === "APPROVED" ||
            state.checks[i].status === "EXPIRED")
        ) {
          checkIn.push(state.checks[i]);
        }
      }
      return checkIn;
    },

    getCheckInCounter: (state) => {
      let total = 0;
      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          ) &&
          state.checks[i].status === "APPROVED"
        ) {
          if (
            !Object.prototype.hasOwnProperty.call(
              state.checks[i]["additional_metadata"],
              "check_out_time"
            ) &&
            state.checks[i].status === "APPROVED"
          ) {
            total += state.checks[i].visitors.length;
          }
        }
      }
      return total;
    },

    getCheckOut: (state) => {
      let checkIn = [];
      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_out_time"
          ) &&
          state.checks[i].status === "COMPLETED"
        ) {
          checkIn.push(state.checks[i]);
        }
      }
      return checkIn;
    },

    getCheckOutCounter: (state) => {
      let total = 0;
      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_out_time"
          ) &&
          state.checks[i].status === "COMPLETED"
        ) {
          total += state.checks[i].visitors.length;
        }
      }
      return total;
    },

    getExpected: (state) => {
      let expected = [];
      for (let i = 0; i < state.expected.length; i++) {
        if (
          !Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          ) &&
          state.expected[i].status == "APPROVED"
        ) {
          console.log("pop", state.expected[i]);
          expected.push(state.expected[i]);
        }
      }
      return expected;
    },

    getExpectedVisitorCounter: (state) => {
      let total = 0;
      for (let i = 0; i < state.expected.length; i++) {
        if (
          !Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          ) &&
          state.expected[i].status == "APPROVED"
        ) {
          total += state.expected[i].visitors.length;
        }
      }
      return total;
    },

    getVisitorCounter: (state) => {
      let total = 0;
      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          )
        ) {
          if (
            !Object.prototype.hasOwnProperty.call(
              state.checks[i]["additional_metadata"],
              "check_out_time"
            )
          ) {
            total += state.checks[i].visitors.length;
          }
        }
      }
      return total;
    },

    getCheckedInButNotYetCheckout: (state) => {
      let checkedIn = [];

      for (let i = 0; i < state.checks.length; i++) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.checks[i]["additional_metadata"],
            "check_in_time"
          )
        ) {
          if (
            !Object.prototype.hasOwnProperty.call(
              state.checks[i]["additional_metadata"],
              "check_out_time"
            )
          ) {
            checkedIn.push(state.checks[i]);
          }
        }
      }
      return checkedIn;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    ADD_CHECKIN(state, payload) {
      var check_data = payload;
      state.checks.push(check_data);
      state.checks.sort((a, b) =>
        a.visit_start_time > b.visit_start_time ? 1 : -1
      );
    },

    ADD_CHECKOUT(state, payload) {
      var check_data = payload;
      state.checks.push(check_data);
      state.checks.sort((a, b) =>
        a.visit_start_time > b.visit_start_time ? 1 : -1
      );
    },

    ADD_EXPECTED(state, payload) {
      var expected_data = payload;
      state.expected.push(expected_data);
      state.expected.sort((a, b) =>
        a.visit_start_time > b.visit_start_time ? 1 : -1
      );
    },

    UPDATE_CHECKOUT(state, payload) {
      var update_id = String(payload.id);

      for (let i = 0; i < state.checks.length; i++) {
        if (state.checks[i].id === update_id) {
          Vue.set(
            state.checks[i],
            "additional_metadata",
            payload["additional_metadata"]
          );
        }
      }
    },

    UPDATE_INTERVAL_ID(state, payload) {
      state.intervalId = payload;
    },

    REMOVE_EXPECTED_VISIT(state, payload) {
      var id = String(payload.id);
      for (let i = 0; i < state.expected.length; i++) {
        if (state.expected[i].id === id) {
          state.expected.splice(i, 1);
        }
      }
    },

    RESET(state) {
      state.expected = [];
      state.checks = [];
    }
  },
  // -----------------------------------------------------------------
  actions: {
    addCheckInList: (context, value) => {
      context.commit("ADD_CHECKIN", value);
    },

    addCheckOutList: (context, value) => {
      context.commit("ADD_CHECKOUT", value);
    },

    addExpectedList: (context, value) => {
      context.commit("ADD_EXPECTED", value);
    },

    updateCheckout: (context, value) => {
      context.commit("UPDATE_CHECKOUT", value);
    },

    expectedListAddCheckIn: (context, value) => {
      context.commit("REMOVE_EXPECTED_VISIT", value);
      //context.commit("ADD_CHECKIN", value);
    },

    updateIntervalId: (context, value) => {
      context.commit("UPDATE_INTERVAL_ID", value);
    },

    reset: (context) => {
      context.commit("RESET");
    }

    /* addCheckInOutList: (context, value) => {
      context.commit("ADD_CHECKIN", value);
    }, */
  }
};
