import Vue from "vue";
import VueLazyload from "vue-lazyload";
import Vuelidate from "vuelidate";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import vSelect from "vue-select";
import { Datetime } from "vue-datetime";
import UUID from "vue-uuid";
import Timesince from "@/components/Timesince/Timesince";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueCompositionAPI from "@vue/composition-api";
import Vue2Editor from "vue2-editor";
// import VuePlyr from 'vue-plyr'
import sodium from "libsodium-wrappers";

import App from "./App.vue";
import router from "@/router";
import store from "./store";

// Plugins
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// Vendor CSS
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue-select/src/scss/vue-select.scss";
import "vue-datetime/dist/vue-datetime.css";
import "plyr/dist/plyr.css";

// App CSS
// import '@/assets/global/scss/sc_global.scss';
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

import Vue2Filters from "vue2-filters";
import "./plugins/filters";

// Global registration
Vue.use(VueLazyload);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Datetime);
Vue.use(UUID);
Vue.use(VueLoading);
Vue.use(VueCompositionAPI);
Vue.use(Vue2Filters);
Vue.use(Vue2Editor);

// Vue.use(VuePlyr)
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);
Vue.component("v-select", vSelect);
Vue.component("v-datetime", Datetime);
Vue.component("time-since", Timesince);

Vue.config.productionTip = false;

let MainApp = new Vue({
  router,
  store: store,
  render: function (h) {
    return h(App);
  },
  methods: {
    getAjaxFetchClient: function (fetchParams) {
      var APISERVER_URL = this.$store.getters["session/getAPIServerURL"];
      var TOKEN_URL = APISERVER_URL + "/auth/token/";

      var api_token = this.$store.getters["session/getAPIToken"];
      var secure_token = this.$store.getters["session/getSecureToken"];

      var fetchConfig = {
        authToken: api_token,
        secureToken: secure_token,
        refreshTokenFunc: this.$store.dispatch,
        refreshTokenFuncArgs: [
          "session/refreshToken",
          {
            url: TOKEN_URL,
            method: "POST",
            headers: null,
            data: { session_key: sessionStorage.sessionKey }
          }
        ]
      };

      if (fetchParams == undefined) {
        fetchParams = {};
      }
      const fetchConfigFinal = {
        ...fetchConfig,
        ...fetchParams
      };
      const client = new AjaxFetch(fetchConfigFinal, this);
      return client;
    }
  }
}).$mount("#app");
