/** VUEX module for PSIM App **/
import Vue from "vue";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import moment from "moment";

let utils = require("@/assets/global/js/utils.js");

var psimStates = {
  viewingDetails: null,
  viewingLocation: null,
  locatingAlert: null,
  initAlerts: false,
  initCases: false,
  initCameras: false,
  initControllers: false,
  initDoors: false,
  initReaders: false,
  initInputs: false,
  initBuildings: false,
  initWorkspaces: false,
  initForms: false,
  buildings: {},
  buildingsOrder: [],
  workspaces: {},
  workspacesOrder: [],
  sharedWorkspaces: {},
  sharedWorkspacesOrder: [],
  manageSharedWorkspaces: {},
  manageSharedWorkspacesOrder: [],
  selectedWorkspace: {
    id: "",
    name: "",
    layout: { panels: [] }
  },
  cameras: {},
  forms: {},
  formsOrder: [],
  camerasOrder: [],
  controllers: {},
  controllersOrder: [],
  doors: {},
  doorsOrder: [],
  readers: {},
  readersOrder: [],
  inputs: {},
  inputsOrder: [],
  bookmarks: {},
  bookmarksOrder: [],
  building_alerts: {},
  floor_alerts: {},
  zone_alerts: {},
  device_alerts: {},
  building_cases: {},
  floor_cases: {},
  zone_cases: {},
  device_cases: {},
  panelOptions: [],
  pageOptions: [],
  active_floor_plan: {}
};
let copiedPsimStates = JSON.parse(JSON.stringify(psimStates));

// console.log(copiedPsimStates.building_alerts);

function generateDeviceId(controller_url, type, no) {
  return controller_url + "_" + type + "_" + no;
}

function generateDeviceName(controller_name, type, no) {
  return controller_name + " (" + type + " " + no + ")";
}

function findControllerSubDevicesByType(controller, type, data) {
  let subDeviceList = [];
  for (let i = 0; i < data.length; i++) {
    let subDevice = data[i];

    // console.log("subDevice");
    // console.log(subDevice);

    subDeviceList.push({
      ...subDevice,
      building: controller.building,
      floor: controller.floor,
      id: generateDeviceId(controller.id, type, subDevice[type + "_no"]),
      name: generateDeviceName(controller.ip, type, subDevice[type + "_no"]),
      ip: controller.ip,
      type: type
    });
  }
  return subDeviceList;
}

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: copiedPsimStates,
  // -----------------------------------------------------------------
  getters: {
    _filter: (state) => (objlist, filters) => {
      if (filters == {} || filters == null || filters == undefined) {
        return objlist;
      } else {
        var results = objlist.filter(function (item) {
          for (var key in filters) {
            var result = utils.searchInDict(item, key, filters[key]);
            if (result == false) {
              return false;
            }
            // console.log("Filtering for " + key + " : " + filters[key]);
          }
          return true;
        });

        return results;
      }
    },
    _get: (state) => (objlist, object_id) => {
      var result = null;
      if (object_id != null && object_id != undefined) {
        for (var i in objlist) {
          var item = objlist[i];
          if (item.id == object_id) {
            result = item;
            break;
          }
        }
      }

      return result;
    },
    getViewingDetails: (state) => {
      return state.viewingDetails;
    },
    getViewingLocation: (state) => {
      return state.viewingLocation;
    },
    getLocatingAlert: (state) => {
      return state.locatingAlert;
    },
    getPanelOptions: (state) => {
      return state.panelOptions;
    },
    getPageOptions: (state) => {
      return state.pageOptions;
    },
    getBuildingsDict: (state) => {
      return state.buildings;
    },
    getBuildings: (state, getters) => (filters) => {
      var obj_list = [];
      // console.log(state.buildingsOrder);
      for (var i in state.buildingsOrder) {
        var obj_id = state.buildingsOrder[i];
        obj_list.push(state.buildings[obj_id]);
      }
      // obj_list.sort(function(a, b){
      //     return a.index - b.index;
      // })

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getBuilding: (state, getters) => (building_id) => {
      var result = state.buildings[building_id];
      return result;
    },
    getFloors: (state, getters) => (filters) => {
      var floors = [];
      var buildings = state.buildings;
      for (var b in buildings) {
        for (var f in buildings[b].floors) {
          // console.log(buildings[b].floors[f])
          var floor_data = buildings[b].floors[f];
          var building_id = buildings[b].id;
          var building_name = buildings[b].name;
          floor_data["building_id"] = building_id;
          floor_data["building"] = building_name;

          floors.push(floor_data);
        }
      }

      return getters._filter(floors, filters);
    },
    getFloor: (state, getters) => (building_id, floor_id) => {
      var floors = state.buildings[String(building_id)].floors;
      var floor_result = floors.filter(function (item) {
        if (item.id == floor_id) {
          return true;
        } else {
          return false;
        }
      });
      if (floor_result.length > 0) {
        return floor_result[0];
      } else {
        return null;
      }
    },
    getZones: (state, getters) => (filters) => {
      var zones = [];
      var floors = getters.getFloors();

      for (var f in floors) {
        for (var z in floors[f].zones) {
          var zone_data = floors[f].zones[z];
          var floor_id = floors[f].id;
          var floor_name = floors[f].name;
          var building_id = floors[f].building_id;
          var building_name = floors[f].building;
          zone_data["floor_id"] = floor_id;
          zone_data["floor"] = floor_name;
          zone_data["building_id"] = building_id;
          zone_data["building"] = building_name;

          zones.push(zone_data);
        }
      }

      return getters._filter(zones, filters);
    },
    getZone: (state, getters) => (building_id, floor_id, zone_id) => {
      var zones = getters.getZones();
      var zone_result = zones.filter(function (item) {
        if (
          item.id == zone_id &&
          item.floor_id == floor_id &&
          item.building_id == building_id
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (zone_result.length > 0) {
        return zone_result[0];
      } else {
        return null;
      }
    },
    getFloorplans: (state, getters) => (filters) => {
      var floorplans = [];
      var floors = getters.getFloors();
      // console.log(floors)
      for (var f in floors) {
        for (var floorplan in floors[f].floorplans) {
          var floorplan_data = floors[f].zones[floorplan];
          var floor_id = floors[f].id;
          var floor_name = floors[f].name;
          var building_id = floors[f].building_id;
          var building_name = floors[f].building;
          floorplan_data["floor_id"] = floor_id;
          floorplan_data["floor"] = floor_name;
          floorplan_data["building_id"] = building_id;
          floorplan_data["building"] = building_name;

          floorplans.push(floorplan_data);
        }
      }

      return getters._filter(floorplans, filters);
    },
    getFloorplan: (state, getters) => (building_id, floor_id, floorplan_id) => {
      var floorplans = getters.getFloorplans();
      // console.log(floorplans)
      var floorplan_result = floorplans.filter(function (item) {
        if (
          item.id == floorplan_id &&
          item.floor_id == floor_id &&
          item.building_id == building_id
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (floorplan_result.length > 0) {
        return floorplan_result[0];
      } else {
        return null;
      }
    },
    getCameras: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.camerasOrder) {
        var obj_id = state.camerasOrder[i];
        obj_list.push(state.cameras[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getCamera: (state, getters) => (id) => {
      // console.log("called psim/getCamera");

      var result = state.cameras[id];

      return result;
    },
    getDoors: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.doorsOrder) {
        var obj_id = state.doorsOrder[i];
        obj_list.push(state.doors[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      return result;
    },
    getDoor: (state, getters) => (id) => {
      var result = state.doors[id];
      return result;
    },
    getReaders: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.readersOrder) {
        var obj_id = state.readersOrder[i];
        obj_list.push(state.readers[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getReader: (state, getters) => (id) => {
      var result = state.readers[id];
      return result;
    },
    getInputs: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.inputsOrder) {
        var obj_id = state.inputsOrder[i];
        obj_list.push(state.inputs[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getInput: (state, getters) => (id) => {
      var result = state.inputs[id];
      return result;
    },
    getControllers: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.controllersOrder) {
        var obj_id = state.controllersOrder[i];
        obj_list.push(state.controllers[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getController: (state, getters) => (id) => {
      // console.log("called psim/getController");

      var result = state.controllers[id];

      return result;
    },
    getBookmarks: (state, getters) => (filters) => {
      var obj_list = [];
      for (var i in state.bookmarksOrder) {
        var obj_id = state.bookmarksOrder[i];
        obj_list.push(state.bookmarks[obj_id]);
      }

      var result = obj_list;
      if (filters != null && filters != undefined) {
        result = getters._filter(obj_list, filters);
      }
      // console.log(result)
      return result;
    },
    getBookmark: (state, getters) => (id) => {
      var result = state.doors[id];
      return result;
    },

    getManageSharedWorkspaces: (state) => {
      var obj_list = [];
      for (var i in state.manageSharedWorkspacesOrder) {
        var obj_id = state.manageSharedWorkspacesOrder[i];
        obj_list.push(state.manageSharedWorkspaces[obj_id]);
      }

      var result = obj_list;
      return result;
    },
    getSharedWorkspaces: (state) => {
      var obj_list = [];
      for (var i in state.sharedWorkspacesOrder) {
        var obj_id = state.sharedWorkspacesOrder[i];
        obj_list.push(state.sharedWorkspaces[obj_id]);
      }

      var result = obj_list;
      return result;
    },
    getWorkspaces: (state) => {
      var obj_list = [];
      for (var i in state.workspacesOrder) {
        var obj_id = state.workspacesOrder[i];
        obj_list.push(state.workspaces[obj_id]);
      }

      var result = obj_list;
      return result;
    },

    getManageSharedWorkspace: (state, getters) => (workspace_id) => {
      return getters._get(state.manageSharedWorkspaces, workspace_id);
    },
    getSharedWorkspace: (state, getters) => (workspace_id) => {
      return getters._get(state.sharedWorkspaces, workspace_id);
    },
    getWorkspace: (state, getters) => (workspace_id) => {
      return getters._get(state.workspaces, workspace_id);
    },

    getSelectedWorkspace: (state) => {
      return state.selectedWorkspace;
      // if (state.selectedWorkspace == null) {
      //     var workspace_id = localStorage.workspaceID;
      //     if (state.workspaces[workspace_id] != undefined) {
      //         return state.workspaces[workspace_id]
      //     } else {
      //         return null;
      //     }
      // } else {
      //     return state.selectedWorkspace;
      // }
    },
    getForms: (state) => {
      var obj_list = [];
      for (var i in state.formsOrder) {
        var obj_id = state.formsOrder[i];
        if (state.forms[obj_id].enable == true) {
          obj_list.push(state.forms[obj_id]);
        }
      }

      var result = obj_list;
      return result;
    },
    getForm: (state, getters) => (form_id) => {
      return getters._get(state.forms, form_id);
    },
    getSelectedForm: (state) => {
      return state.selectedForm;
    },
    getBuildingAlerts: (state) => {
      // console.log(state.building_alerts);
      return state.building_alerts;
    },
    getFloorAlerts: (state) => {
      // console.log("getFloorAlerts");
      return state.floor_alerts;
      // if (floor_id != undefined) {
      //     if (state.floor_alerts[floor_id] != undefined) {
      //         return state.floor_alerts[floor_id];
      //     } else {
      //         return [];
      //     }
      // } else {
      //     return state.floor_alerts;
      // }
    },
    getZoneAlerts: (state) => {
      return state.zone_alerts;
    },
    getDeviceAlerts: (state) => {
      return state.device_alerts;
    },
    getBuildingCases: (state) => {
      return state.building_cases;
    },
    getFloorCases: (state) => {
      return state.floor_cases;
      // if (floor_id != undefined) {
      //     if (state.floor_cases[floor_id] != undefined) {
      //         return state.floor_cases[floor_id];
      //     } else {
      //         return [];
      //     }
      // } else {
      //     return state.floor_cases;
      // }
    },
    getZoneCases: (state) => {
      return state.zone_cases;
    },
    getDeviceCases: (state) => {
      return state.device_cases;
    },
    isAlertsInitiated: (state) => {
      return state.initAlerts;
    },
    isCasesInitiated: (state) => {
      return state.initCases;
    },
    isCamerasInitiated: (state) => {
      return state.initCameras;
    },
    isDoorsInitiated: (state) => {
      return state.initDoors;
    },
    isDevicesInitiated: (state) => {
      var result = false;
      if (state.initCameras == true && state.initDoors == true) {
        result = true;
      }
      return result;
    },
    isBuildingsInitiated: (state) => {
      return state.initBuildings;
    },
    isWorkspacesInitiated: (state) => {
      return state.initWorkspaces;
    },
    isFormsInitiated: (state) => {
      return state.initForms;
    },
    getActiveFloorplan: (state) => {
      return state.active_floor_plan;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        // state[state_name] = state_value;
        Vue.set(state, state_name, state_value);
        // console.log(state_name, "=", state[state_name]);
      }
    },
    ADD_ALERT_TO_DEVICE(state, payload) {
      var device_type = payload["device_type"];
      var device_id = payload["device_id"];
      var device_ip = payload["device_ip"];
      var alert_id = payload["alert_id"];
      alert_id = String(alert_id);
      if (state.device_alerts[device_id] != undefined) {
        if (state.device_alerts[device_id].indexOf(alert_id) < 0) {
          state.device_alerts[device_id].push(alert_id);
          Vue.set(
            state.device_alerts,
            device_id,
            state.device_alerts[device_id]
          );
        }
      } else {
        Vue.set(state.device_alerts, device_id, [alert_id]);
      }

      var device_list = state[device_type + "s"];
      // console.log(device_list);
      for (var d in device_list) {
        var device = device_list[d];
        if (device.id == device_id) {
          // console.log(device);
          var building_id = String(device.building.id);
          var floor_id = String(device.floor.id);
          var zone_id = null;
          if (device.zone != null) {
            zone_id = String(device.zone.id);
          }

          if (state.building_alerts[building_id] != undefined) {
            if (state.building_alerts[building_id].indexOf(alert_id) < 0) {
              state.building_alerts[building_id].push(alert_id);
              Vue.set(
                state.building_alerts,
                building_id,
                state.building_alerts[building_id]
              );
            }
          } else {
            // state.building_alerts[building_id] = [alert_id];
            Vue.set(state.building_alerts, building_id, [alert_id]);
          }
          // console.log(state.building_alerts);
          // Vue.set(state.building_alerts, building_id, state.building_alerts[building_id]);

          if (state.floor_alerts[floor_id] != undefined) {
            if (state.floor_alerts[floor_id].indexOf(alert_id) < 0) {
              state.floor_alerts[floor_id].push(alert_id);
              Vue.set(
                state.floor_alerts,
                floor_id,
                state.floor_alerts[floor_id]
              );
            }
          } else {
            // state.floor_alerts[floor_id] = [alert_id];
            Vue.set(state.floor_alerts, floor_id, [alert_id]);
          }
          // console.log("set floor alerts", floor_id, "alert ID:", alert_id);
          // Vue.set(state.floor_alerts, floor_id, state.floor_alerts[floor_id]);

          if (zone_id != null) {
            if (state.zone_alerts[zone_id] != undefined) {
              if (state.zone_alerts[zone_id].indexOf(alert_id) < 0) {
                state.zone_alerts[zone_id].push(alert_id);
                Vue.set(state.zone_alerts, zone_id, state.zone_alerts[zone_id]);
              }
            } else {
              // state.zone_alerts[zone_id] = [alert_id];
              Vue.set(state.zone_alerts, zone_id, [alert_id]);
            }
          }
          // Vue.set(state.zone_alerts, zone_id, state.zone_alerts[zone_id]);
          break;
        }
      }
    },
    ADD_CASE_TO_DEVICE(state, payload) {
      var device_type = payload["device_type"];
      var device_id = payload["device_id"];
      var device_ip = payload["device_ip"];
      var case_id = payload["case_id"];
      if (state.device_cases[device_id] != undefined) {
        if (state.device_cases[device_id].indexOf(case_id) < 0) {
          state.device_cases[device_id].push(case_id);
        }
      } else {
        state.device_cases[device_id] = [case_id];
      }

      var device_list = state[device_type + "s"];
      for (var d in device_list) {
        var device = device_list[d];
        if (device.id == device_id) {
          // console.log(device);
          var building_id = String(device.building.id);
          var floor_id = String(device.floor.id);
          var zone_id = null;
          if (device.zone != null) {
            zone_id = String(device.zone.id);
          }

          if (state.building_cases[building_id] != undefined) {
            if (state.building_cases[building_id].indexOf(case_id) < 0) {
              state.building_cases[building_id].push(case_id);
            }
          } else {
            state.building_cases[building_id] = [case_id];
          }

          if (state.floor_cases[floor_id] != undefined) {
            if (state.floor_cases[floor_id].indexOf(case_id) < 0) {
              state.floor_cases[floor_id].push(case_id);
            }
          } else {
            state.floor_cases[floor_id] = [case_id];
          }

          if (zone_id != null) {
            if (state.zone_cases[zone_id] != undefined) {
              if (state.zone_cases[zone_id].indexOf(case_id) < 0) {
                state.zone_cases[zone_id].push(case_id);
              }
            } else {
              state.zone_cases[zone_id] = [case_id];
            }
          }

          break;
        }
      }
    },
    DISCARD_ALERT(state, alert_id) {
      alert_id = String(alert_id);
      for (var b in state.building_alerts) {
        if (state.building_alerts[b] != undefined) {
          var b_index = state.building_alerts[b].indexOf(alert_id);
          if (b_index >= 0) {
            state.building_alerts[b].splice(b_index, 1);
            // Vue.set(state.building_alerts, b, state.building_alerts[b]);
            break;
          }
        }
      }

      for (var f in state.floor_alerts) {
        if (state.floor_alerts[f] != undefined) {
          var f_index = state.floor_alerts[f].indexOf(alert_id);
          if (f_index >= 0) {
            state.floor_alerts[f].splice(f_index, 1);
            // Vue.set(state.floor_alerts, f, state.floor_alerts[f]);
            break;
          }
        }
      }

      for (var z in state.zone_alerts) {
        if (state.zone_alerts[z] != undefined) {
          var z_index = state.zone_alerts[z].indexOf(alert_id);
          if (z_index >= 0) {
            state.zone_alerts[z].splice(z_index, 1);
            // Vue.set(state.zone_alerts, z, state.zone_alerts[z]);
            break;
          }
        }
      }

      for (var d in state.device_alerts) {
        if (state.device_alerts[d] != undefined) {
          var d_index = state.device_alerts[d].indexOf(alert_id);
          if (d_index >= 0) {
            state.device_alerts[d].splice(d_index, 1);
            // Vue.set(state.device_alerts, d, state.device_alerts[d]);
            // console.log("Discarded Device Alert", state.device_alerts[d])
            break;
          }
        }
      }
      // console.log(JSON.parse(JSON.stringify(state.building_alerts)));
    },
    ADD_BUILDING(state, payload) {
      var building_id = String(payload.id);
      if (state.buildingsOrder.indexOf(building_id) < 0) {
        state.buildingsOrder.push(building_id);
      }
      Vue.set(state.buildings, building_id, payload);
    },
    UPDATE_BUILDING(state, payload) {
      var building_id = String(payload.id);
      var building_data = payload.data;
      var building_obj = state.buildings[building_id];
      if (building_obj != undefined) {
        for (var d in building_data) {
          building_obj[d] = building_data[d];
        }
      }
      // console.log(building_obj.name + " " + building_obj.index);
      Vue.set(state.buildings, building_id, building_obj);
      // console.log(state.buildings[building_id]);
    },
    UPDATE_BUILDING_INDEX(state, payload) {
      var building_id = String(payload.id);
      var building_new_index = payload.index;
      var building_old_index = state.buildingsOrder.indexOf(building_id);
      while (building_old_index < 0) {
        building_old_index += state.buildingsOrder.length;
      }
      while (building_new_index < 0) {
        building_new_index += state.buildingsOrder.length;
      }
      if (building_new_index >= state.buildingsOrder.length) {
        let k = building_new_index - state.buildingsOrder.length;
        while (k-- + 1) {
          state.buildingsOrder.push(undefined);
        }
      }

      state.buildingsOrder.splice(
        building_new_index,
        0,
        state.buildingsOrder.splice(building_old_index, 1)[0]
      );
      // Vue.set(state.buildingsOrder, newOrder);
    },
    DELETE_BUILDING(state, building_id) {
      var index = state.buildingsOrder.indexOf(String(building_id));
      if (index >= 0) {
        state.buildingsOrder.splice(index, 1);
        delete state.buildings[String(building_id)];
      }
    },
    ADD_FLOOR(state, payload) {
      var building_id = String(payload.building_id);
      var floor_data = payload.data;
      var building_obj = state.buildings[building_id];
      if (building_obj != undefined) {
        building_obj.floors.push(floor_data);
        building_obj.floors.sort(function (a, b) {
          return a.index - b.index;
        });
      }
      Vue.set(state.buildings, building_id, building_obj);
    },
    UPDATE_FLOOR(state, payload) {
      var building_id = String(payload.building_id);
      var floor_id = payload.floor_id;
      var floor_data = payload.data;
      var building_obj = state.buildings[building_id];
      // console.log(building_obj)
      if (building_obj != undefined) {
        for (var f in building_obj.floors) {
          var floor_obj = building_obj.floors[f];
          if (floor_obj.id == floor_id) {
            // console.log(floor_data)
            for (var d in floor_data) {
              floor_obj[d] = floor_data[d];
              // console.log(floor_obj[d]);
            }
            // console.log("Updated Floor")
            break;
          }
        }
      }
      Vue.set(state.buildings, building_id, building_obj);
    },
    SET_FLOORS(state, payload) {
      var building_id = String(payload.building_id);
      var floors = payload.floors;
      var building_obj = state.buildings[building_id];
      if (building_obj != undefined) {
        building_obj.floors = floors;
      }
      Vue.set(state.buildings, building_id, building_obj);
    },
    DELETE_FLOOR(state, payload) {
      var building_id = String(payload.building_id);
      var floor_id = payload.floor_id;
      var building_obj = state.buildings[building_id];
      if (building_obj != undefined) {
        var floor_index = null;
        for (var f in building_obj.floors) {
          var floor_obj = building_obj.floors[f];
          if (floor_obj.id == floor_id) {
            floor_index = f;
            break;
          }
        }

        if (floor_index != null) {
          building_obj.floors.splice(floor_index, 1);
        }
      }
      Vue.set(state.buildings, building_id, building_obj);
    },
    SET_ACTIVE_FLOORPLAN(state, payload) {
      var floor_id = String(payload.floor);
      var floorplan = payload.floorplan;

      Vue.set(state.active_floor_plan, floor_id, floorplan);
    },
    ADD_CAMERA(state, payload) {
      var device_id = String(payload.id);
      if (state.camerasOrder.indexOf(device_id) < 0) {
        state.camerasOrder.push(device_id);
      }
      Vue.set(state.cameras, device_id, payload);
    },
    ADD_DOOR(state, payload) {
      var device_id = String(payload.id);
      if (state.doorsOrder.indexOf(device_id) < 0) {
        state.doorsOrder.push(device_id);
      }
      Vue.set(state.doors, device_id, payload);
    },
    ADD_READER(state, payload) {
      var device_id = String(payload.id);
      if (state.readersOrder.indexOf(device_id) < 0) {
        state.readersOrder.push(device_id);
      }
      Vue.set(state.readers, device_id, payload);
    },
    ADD_INPUT(state, payload) {
      var device_id = String(payload.id);
      if (state.inputsOrder.indexOf(device_id) < 0) {
        state.inputsOrder.push(device_id);
      }
      Vue.set(state.inputs, device_id, payload);
    },
    ADD_CONTROLLER(state, payload) {
      var device_id = String(payload.id);
      if (state.controllersOrder.indexOf(device_id) < 0) {
        state.controllersOrder.push(device_id);
      }
      Vue.set(state.controllers, device_id, payload);
    },
    UPDATE_CAMERA(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      var device_obj = state.cameras[device_id];
      if (device_obj != undefined) {
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      }
      Vue.set(state.cameras, device_id, device_obj);
    },
    UPDATE_DOOR(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      var device_obj = state.doors[device_id];
      if (device_obj != undefined) {
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      }
      Vue.set(state.doors, device_id, device_obj);
    },
    UPDATE_READER(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      var device_obj = state.readers[device_id];
      if (device_obj != undefined) {
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      }
      Vue.set(state.readers, device_id, device_obj);
    },
    UPDATE_INPUT(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      var device_obj = state.inputs[device_id];
      if (device_obj != undefined) {
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      }
      Vue.set(state.inputs, device_id, device_obj);
    },
    UPDATE_CONTROLLER(state, payload) {
      var device_id = String(payload.id);
      var device_update_data = payload.data;
      var device_obj = state.controllers[device_id];
      if (device_obj != undefined) {
        for (var d in device_update_data) {
          device_obj[d] = device_update_data[d];
        }
      }
      Vue.set(state.controllers, device_id, device_obj);
    },
    DELETE_CAMERA(state, id) {
      var device_id = String(id);
      // console.log(state.cameras[device_id]);
      var index = state.camerasOrder.indexOf(device_id);
      if (index >= 0) {
        state.camerasOrder.splice(index, 1);
        delete state.cameras[device_id];
      }
      // console.log(device_id);
      // console.log(index);
      // console.log(state.cameras);
    },
    DELETE_DOOR(state, id) {
      var device_id = String(id);
      var index = state.doorsOrder.indexOf(device_id);
      if (index >= 0) {
        state.doorsOrder.splice(index, 1);
        delete state.doors[device_id];
      }
    },
    DELETE_READER(state, id) {
      var device_id = String(id);
      var index = state.readersOrder.indexOf(device_id);
      if (index >= 0) {
        state.readersOrder.splice(index, 1);
        delete state.readers[device_id];
      }
    },
    DELETE_INPUT(state, id) {
      var device_id = String(id);
      var index = state.inputsOrder.indexOf(device_id);
      if (index >= 0) {
        state.inputsOrder.splice(index, 1);
        delete state.inputs[device_id];
      }
    },
    DELETE_CONTROLLER(state, id) {
      var device_id = String(id);
      var index = state.controllersOrder.indexOf(device_id);
      if (index >= 0) {
        state.controllersOrder.splice(index, 1);
        delete state.controllers[device_id];
      }
    },
    ADD_BOOKMARK(state, payload) {
      var obj_id = String(payload.id);
      if (state.bookmarksOrder.indexOf(obj_id) < 0) {
        state.bookmarksOrder.push(obj_id);
      }
      Vue.set(state.bookmarks, obj_id, payload);
    },
    DELETE_BOOKMARK(state, id) {
      var obj_id = String(id);
      var index = state.bookmarksOrder.indexOf(obj_id);
      if (index >= 0) {
        state.bookmarksOrder.splice(index, 1);
        delete state.bookmarks[obj_id];
      }
    },

    ADD_MANAGE_SHARED_WORKSPACE(state, payload) {
      var obj_id = String(payload.id);
      if (state.manageSharedWorkspacesOrder.indexOf(obj_id) < 0) {
        state.manageSharedWorkspacesOrder.push(obj_id);
      }
      Vue.set(state.manageSharedWorkspaces, obj_id, payload);
    },
    UPDATE_MANAGE_SHARED_WORKSPACE(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_data = payload.data;
      var workspace_obj = state.manageSharedWorkspaces[workspace_id];
      if (workspace_obj != undefined) {
        for (var d in workspace_data) {
          workspace_obj[d] = workspace_data[d];
        }
      }
      Vue.set(state.manageSharedWorkspaces, workspace_id, workspace_obj);
    },
    UPDATE_MANAGE_SHARED_WORKSPACE_INDEX(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_new_index = payload.index;
      var workspace_old_index =
        state.manageSharedWorkspacesOrder.indexOf(workspace_id);
      while (workspace_old_index < 0) {
        workspace_old_index += state.manageSharedWorkspacesOrder.length;
      }
      while (workspace_new_index < 0) {
        workspace_new_index += state.manageSharedWorkspacesOrder.length;
      }
      if (workspace_new_index >= state.manageSharedWorkspacesOrder.length) {
        let k = workspace_new_index - state.manageSharedWorkspacesOrder.length;
        while (k-- + 1) {
          state.manageSharedWorkspacesOrder.push(undefined);
        }
      }

      state.manageSharedWorkspacesOrder.splice(
        workspace_new_index,
        0,
        state.manageSharedWorkspacesOrder.splice(workspace_old_index, 1)[0]
      );
    },
    DELETE_MANAGE_SHARED_WORKSPACE(state, id) {
      var obj_id = String(id);
      var index = state.manageSharedWorkspacesOrder.indexOf(obj_id);
      if (index >= 0) {
        state.manageSharedWorkspacesOrder.splice(index, 1);
        delete state.manageSharedWorkspaces[obj_id];
      }
    },

    ADD_SHARED_WORKSPACE(state, payload) {
      var obj_id = String(payload.id);
      if (state.sharedWorkspacesOrder.indexOf(obj_id) < 0) {
        state.sharedWorkspacesOrder.push(obj_id);
      }
      Vue.set(state.sharedWorkspaces, obj_id, payload);
    },
    UPDATE_SHARED_WORKSPACE(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_data = payload.data;
      var workspace_obj = state.sharedWorkspaces[workspace_id];
      if (workspace_obj != undefined) {
        for (var d in workspace_data) {
          workspace_obj[d] = workspace_data[d];
        }
      }
      Vue.set(state.sharedWorkspaces, workspace_id, workspace_obj);
    },
    UPDATE_SHARED_WORKSPACE_INDEX(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_new_index = payload.index;
      var workspace_old_index =
        state.sharedWorkspacesOrder.indexOf(workspace_id);
      while (workspace_old_index < 0) {
        workspace_old_index += state.sharedWorkspacesOrder.length;
      }
      while (workspace_new_index < 0) {
        workspace_new_index += state.sharedWorkspacesOrder.length;
      }
      if (workspace_new_index >= state.sharedWorkspacesOrder.length) {
        let k = workspace_new_index - state.sharedWorkspacesOrder.length;
        while (k-- + 1) {
          state.sharedWorkspacesOrder.push(undefined);
        }
      }

      state.sharedWorkspacesOrder.splice(
        workspace_new_index,
        0,
        state.sharedWorkspacesOrder.splice(workspace_old_index, 1)[0]
      );
    },
    DELETE_SHARED_WORKSPACE(state, id) {
      var obj_id = String(id);
      var index = state.sharedWorkspacesOrder.indexOf(obj_id);
      if (index >= 0) {
        state.sharedWorkspacesOrder.splice(index, 1);
        delete state.sharedWorkspaces[obj_id];
      }
    },

    ADD_WORKSPACE(state, payload) {
      var obj_id = String(payload.id);
      if (state.workspacesOrder.indexOf(obj_id) < 0) {
        state.workspacesOrder.push(obj_id);
      }
      Vue.set(state.workspaces, obj_id, payload);
    },
    UPDATE_WORKSPACE(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_data = payload.data;
      var workspace_obj = state.workspaces[workspace_id];
      if (workspace_obj != undefined) {
        for (var d in workspace_data) {
          workspace_obj[d] = workspace_data[d];
        }
      }
      Vue.set(state.workspaces, workspace_id, workspace_obj);
    },
    UPDATE_WORKSPACE_INDEX(state, payload) {
      var workspace_id = String(payload.id);
      var workspace_new_index = payload.index;
      var workspace_old_index = state.workspacesOrder.indexOf(workspace_id);
      while (workspace_old_index < 0) {
        workspace_old_index += state.workspacesOrder.length;
      }
      while (workspace_new_index < 0) {
        workspace_new_index += state.workspacesOrder.length;
      }
      if (workspace_new_index >= state.workspacesOrder.length) {
        let k = workspace_new_index - state.workspacesOrder.length;
        while (k-- + 1) {
          state.workspacesOrder.push(undefined);
        }
      }

      state.workspacesOrder.splice(
        workspace_new_index,
        0,
        state.workspacesOrder.splice(workspace_old_index, 1)[0]
      );
    },
    DELETE_WORKSPACE(state, id) {
      var obj_id = String(id);
      var index = state.workspacesOrder.indexOf(obj_id);
      if (index >= 0) {
        state.workspacesOrder.splice(index, 1);
        delete state.workspaces[obj_id];
      }
    },

    ADD_FORM(state, payload) {
      var obj_id = String(payload.id);
      if (state.formsOrder.indexOf(obj_id) < 0) {
        state.formsOrder.push(obj_id);
      }
      Vue.set(state.forms, obj_id, payload);
    },
    UPDATE_FORM(state, payload) {
      var form_id = String(payload.id);
      Vue.set(state.forms, form_id, payload);
    },
    UPDATE_FORMS(state, payload) {
      var form_id = String(payload.id);
      var form_data = payload.form_data;
      var form_obj = state.forms[payload.id];
      if (form_obj != undefined) {
        for (var d in form_data) {
          form_obj[d] = form_data[d];
        }
      }
      Vue.set(state.forms, form_id, form_obj);
    },
    UPDATE_FORM_INDEX(state, payload) {
      var form_id = String(payload.id);
      var form_new_index = payload.index;
      var form_old_index = state.formsOrder.indexOf(form_id);
      while (form_old_index < 0) {
        form_old_index += state.formsOrder.length;
      }
      while (form_new_index < 0) {
        form_new_index += state.formsOrder.length;
      }
      if (form_new_index >= state.formsOrder.length) {
        let k = form_new_index - state.formsOrder.length;
        while (k-- + 1) {
          state.formsOrder.push(undefined);
        }
      }

      state.formsOrder.splice(
        form_new_index,
        0,
        state.formsOrder.splice(form_old_index, 1)[0]
      );
    },
    DELETE_FORM(state, id) {
      var obj_id = String(id);
      var index = state.formsOrder.indexOf(obj_id);
      if (index >= 0) {
        state.formsOrder.splice(index, 1);
        delete state.formsOrder[obj_id];
      }
    },
    RESET_STORE(state) {
      let originalStates = JSON.parse(JSON.stringify(psimStates));

      for (var s in originalStates) {
        // state[s] = psimStates[s];
        Vue.set(state, s, originalStates[s]);
        // console.log("state ", s, " = ", state[s], " - ", originalStates[s]);
      }
      localStorage.removeItem("workspaceID");
    }
  },
  // -----------------------------------------------------------------
  actions: {
    setViewingDetails: (context, value) => {
      // var old_value = context.getters.getViewingDetails;
      context.commit("SET_STATE", { viewingDetails: value });
      // var new_value = context.getters.getViewingDetails;
    },
    setViewingLocation: (context, value) => {
      context.commit("SET_STATE", { viewingLocation: value });
    },
    setLocatingAlert: (context, value) => {
      context.commit("SET_STATE", { locatingAlert: value });
    },
    setPanelOptions: (context, value) => {
      context.commit("SET_STATE", { panelOptions: value });
    },
    setPageOptions: (context, value) => {
      context.commit("SET_STATE", { pageOptions: value });
    },
    setBuildings: (context, value) => {
      context.commit("SET_STATE", { buildings: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { buildingsOrder: [] });
      }
    },
    setFloors: (context, value) => {
      context.commit("SET_FLOORS", value);
    },
    setAlerts: (context, value) => {
      context.commit("SET_STATE", { alerts: value });
    },
    setCases: (context, value) => {
      context.commit("SET_STATE", { cases: value });
    },
    setCameras: (context, value) => {
      context.commit("SET_STATE", { cameras: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { camerasOrder: [] });
      }
    },
    setDoors: (context, value) => {
      context.commit("SET_STATE", { doors: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { doorsOrder: [] });
      }
    },
    setReaders: (context, value) => {
      context.commit("SET_STATE", { readers: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { readersOrder: [] });
      }
    },
    setInputs: (context, value) => {
      context.commit("SET_STATE", { inputs: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { inputsOrder: [] });
      }
    },
    setControllers: (context, value) => {
      context.commit("SET_STATE", { controllers: value });
      if (Object.keys(value).length <= 0) {
        context.commit("SET_STATE", { controllersOrder: [] });
      }
    },

    setManageSharedWorkspaces: (context, value) => {
      context.commit("SET_STATE", { manageSharedWorkspaces: value });
    },
    setSharedWorkspaces: (context, value) => {
      context.commit("SET_STATE", { sharedWorkspaces: value });
    },
    setWorkspaces: (context, value) => {
      context.commit("SET_STATE", { workspaces: value });
    },

    setForms: (context, value) => {
      context.commit("SET_STATE", { forms: value });
    },
    setSelectedWorkspace: (context, value) => {
      context.commit("SET_STATE", { selectedWorkspace: value });
      localStorage.workspaceID = value.id;
    },
    setInitAlertsState: (context, value) => {
      context.commit("SET_STATE", { initAlerts: value });
    },
    setInitCasesState: (context, value) => {
      context.commit("SET_STATE", { initCases: value });
    },
    setInitCamerasState: (context, value) => {
      context.commit("SET_STATE", { initCameras: value });
    },
    setInitDoorsState: (context, value) => {
      context.commit("SET_STATE", { initDoors: value });
    },
    setInitReadersState: (context, value) => {
      context.commit("SET_STATE", { initReaders: value });
    },
    setInitInputsState: (context, value) => {
      context.commit("SET_STATE", { initInputs: value });
    },
    setInitControllersState: (context, value) => {
      context.commit("SET_STATE", { initControllers: value });
    },
    setInitBuildingsState: (context, value) => {
      context.commit("SET_STATE", { initBuildings: value });
    },
    setInitWorkspacesState: (context, value) => {
      context.commit("SET_STATE", { initWorkspaces: value });
    },
    setInitFormsState: (context, value) => {
      context.commit("SET_STATE", { initForms: value });
    },
    addBuilding: (context, value) => {
      context.commit("ADD_BUILDING", value);
    },
    updateBuilding: (context, value) => {
      context.commit("UPDATE_BUILDING", value);
    },
    updateBuildingIndex: (context, value) => {
      context.commit("UPDATE_BUILDING_INDEX", value);
    },
    deleteBuilding: (context, value) => {
      context.commit("DELETE_BUILDING", value);
    },
    addFloor: (context, value) => {
      /* value = { "building_id": building_id, "data": floor_data } */
      context.commit("ADD_FLOOR", value);
    },
    updateFloor: (context, value) => {
      /* value = { "building_id": building_id, "floor_id": floor_id, "data": {"name": floor_name} } */
      // console.log(value)
      context.commit("UPDATE_FLOOR", value);
    },
    deleteFloor: (context, value) => {
      /* value = { "building_id": building_id, "floor_id": floor_id} */
      context.commit("DELETE_FLOOR", value);
    },
    addDevice: (context, value) => {
      /* value = { "device_type": "camera", "data": device_data } */
      if (value["device_type"] == "camera") {
        context.commit("ADD_CAMERA", value.data);
        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateCamera",
        //     {"id": value.data.id, "data": { "name": value.data.name, "building": value.data.building, "floor": value.data.floor, "location": value.data.building.name + " - " + value.data.floor.name }},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "controller") {
        context.commit("ADD_CONTROLLER", value.data);

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateController",
        //     {"id": value.data.id, "data": { "name": value.data.name, "building": value.data.building, "floor": value.data.floor, "location": value.data.building.name + " - " + value.data.floor.name }},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "door") {
        context.commit("ADD_DOOR", value.data);

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateDoor",
        //     {"id": value.data.id, "data": { "name": value.data.name, "building": value.data.building, "floor": value.data.floor, "location": value.data.building.name + " - " + value.data.floor.name }},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "reader") {
        context.commit("ADD_READER", value.data);

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateReader",
        //     {"id": value.data.id, "data": { "name": value.data.name, "building": value.data.building, "floor": value.data.floor, "location": value.data.building.name + " - " + value.data.floor.name }},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "input") {
        context.commit("ADD_INPUT", value.data);

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateInput",
        //     {"id": value.data.id, "data": { "name": value.data.name, "building": value.data.building, "floor": value.data.floor, "location": value.data.building.name + " - " + value.data.floor.name }},
        //     {"root": true}
        // );
      }
    },
    updateDevice: (context, value) => {
      /* value = { "device_type": "camera", "device_id": device_id, data": device_data } */
      if (value["device_type"] == "camera") {
        context.commit("UPDATE_CAMERA", {
          id: value.device_id,
          data: value.data
        });

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateCamera",
        //     {"id": value.device_id, "data": value.data},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "controller") {
        context.commit("UPDATE_CONTROLLER", {
          id: value.device_id,
          data: value.data
        });

        // TODO: check with Eva, do we need to update this in device store? because it has different data structure
        // context.dispatch(
        //     "device/updateController",
        //     {"id": value.device_id, "data": value.data},
        //     {"root": true}
        // );
      } else if (value["device_type"] == "door") {
        context.commit("UPDATE_DOOR", {
          id: value.device_id,
          data: value.data
        });
      } else if (value["device_type"] == "reader") {
        context.commit("UPDATE_READER", {
          id: value.device_id,
          data: value.data
        });
      } else if (value["device_type"] == "input") {
        context.commit("UPDATE_INPUT", {
          id: value.device_id,
          data: value.data
        });
      }
    },
    deleteDevice: (context, value) => {
      /* value = { "device_type": "camera", "id": device_id } */
      if (value["device_type"] == "camera") {
        context.commit("DELETE_CAMERA", value["id"]);
      } else if (value["device_type"] == "controller") {
        context.commit("DELETE_CONTROLLER", value["id"]);
      } else if (value["device_type"] == "door") {
        context.commit("DELETE_DOOR", value["id"]);
      } else if (value["device_type"] == "reader") {
        context.commit("DELETE_READER", value["id"]);
      } else if (value["device_type"] == "input") {
        context.commit("DELETE_INPUT", value["id"]);
      }
    },
    addAlertToDevice: (context, value) => {
      /* value = { "device_ip": device_ip, "device_type": device_type, "alert_id": alert_id } */
      context.commit("ADD_ALERT_TO_DEVICE", value);
    },
    addCaseToDevice: (context, value) => {
      /* value = { "device_ip": device_ip, "device_type": device_type, "alert_id": alert_id } */
      context.commit("ADD_CASE_TO_DEVICE", value);
    },
    addBookmark: (context, value) => {
      context.commit("ADD_BOOKMARK", value);
    },
    deleteBookmark: (context, value) => {
      context.commit("DELETE_BOOKMARK", value);
    },
    addManageSharedWorkspace: (context, value) => {
      context.commit("ADD_MANAGE_SHARED_WORKSPACE", value);
    },
    updateManageSharedWorkspace: (context, value) => {
      context.commit("UPDATE_MANAGE_SHARED_WORKSPACE", value);
    },
    updateManageSharedWorkspaceIndex: (context, value) => {
      context.commit("UPDATE_MANAGE_SHARED_WORKSPACE_INDEX", value);
    },
    deleteManageSharedWorkspace: (context, value) => {
      context.commit("DELETE_MANAGE_SHARED_WORKSPACE", value);
    },
    addSharedWorkspace: (context, value) => {
      context.commit("ADD_SHARED_WORKSPACE", value);
    },
    updateSharedWorkspace: (context, value) => {
      context.commit("UPDATE_SHARED_WORKSPACE", value);
    },
    updateSharedWorkspaceIndex: (context, value) => {
      context.commit("UPDATE_SHARED_WORKSPACE_INDEX", value);
    },
    deleteSharedWorkspace: (context, value) => {
      context.commit("DELETE_SHARED_WORKSPACE", value);
    },
    addWorkspace: (context, value) => {
      context.commit("ADD_WORKSPACE", value);
    },
    updateWorkspace: (context, value) => {
      context.commit("UPDATE_WORKSPACE", value);
    },
    updateWorkspaceIndex: (context, value) => {
      context.commit("UPDATE_WORKSPACE_INDEX", value);
    },
    deleteWorkspace: (context, value) => {
      context.commit("DELETE_WORKSPACE", value);
    },
    addForm: (context, value) => {
      context.commit("ADD_FORM", value);
    },
    updateForm: (context, value) => {
      context.commit("UPDATE_FORM", value);
    },
    updateForms: (context, value) => {
      context.commit("UPDATE_FORMS", value);
    },
    updateFormIndex: (context, value) => {
      context.commit("UPDATE_FORM_INDEX", value);
    },
    deleteForm: (context, value) => {
      context.commit("DELETE_FORM", value);
    },
    discardAlert: (context, alert_id) => {
      // console.log("discardAlert", alert_id);
      context.commit("DISCARD_ALERT", alert_id);
    },
    resetStore: (context) => {
      context.commit("RESET_STORE");
    },
    setActiveFloorplan: (context, value) => {
      // console.log(value)
      context.commit("SET_ACTIVE_FLOORPLAN", value);
    },
    async fetchBuildings(context, value) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/floorplans/buildings/?limit=0";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        // console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }

      if (response.result != undefined) {
        response = response.result;

        for (var i in response) {
          var building_data = response[i];
          for (var f in building_data.floors) {
            var floor = building_data.floors[f];
            if (floor.floorplans != null && floor.floorplans.length > 0) {
              for (var fp in floor.floorplans) {
                var floorplan = floor.floorplans[fp];
                var floorplan_image = utils.cleanBase64ImgSrc(
                  floorplan.image_b64,
                  floorplan.mime_type
                );
                floor.floorplans[fp]["img"] = floorplan_image;

                for (var d in floorplan.devices) {
                  var device_id = d;
                  var device_data = floorplan.devices[d];
                  device_data["building"] = {
                    id: building_data.id,
                    name: building_data.name
                  };
                  device_data["floor"] = { id: floor.id, name: floor.name };
                  floor.floorplans[fp]["devices"][device_id] = device_data;
                }

                // console.log('floor.floorplans[fp]["devices"]')
                // console.log(floor.floorplans[fp]["devices"])
              }

              floor["default_img"] = floor.floorplans[0]["img"];
            }
          }
          context.commit("ADD_BUILDING", building_data);
        }
      }
      // console.log(response)
      return response;
    },
    async fetchFloorDevices(context, value) {
      var building_id = value.building_id;
      var floor_id = value.floor_id;
      // console.log("fetchFloorPlan", value);
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL =
        APISERVER_URL +
        "/floorplans/buildings/" +
        building_id +
        "/floors/" +
        floor_id +
        "/devices/";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }
      if (response.result != undefined) {
        var building_obj = context.getters["getBuilding"](building_id);
        var floor_obj = context.getters["getFloor"](building_id, floor_id);

        response = response.result;

        for (var i in response) {
          var device_data = response[i];
          device_data["building"] = {
            id: building_obj.id,
            name: building_obj.name
          };
          device_data["floor"] = { id: floor_obj.id, name: floor_obj.name };
          context.dispatch("addDevice", {
            device_type: device_data.type,
            data: device_data
          });

          if (device_data.type == "controller") {
            var ctrl_doors = context.rootGetters["device/filterDoors"]({
              ip: device_data.ip
            });
            var ctrl_readers = context.rootGetters["device/filterReaders"]({
              ip: device_data.ip
            });
            var ctrl_inputs = context.rootGetters["device/filterInputs"]({
              ip: device_data.ip
            });

            ctrl_doors.map((door) => {
              let subdevice_type = "door";
              let subdevice_data = {
                id: door.id,
                name: generateDeviceName(
                  device_data.ip,
                  subdevice_type,
                  door["data"]["details"][subdevice_type + "_no"]
                ),
                type: subdevice_type,
                ip: device_data.ip,
                building: { id: building_obj.id, name: building_obj.name },
                floor: { id: floor_obj.id, name: floor_obj.name }
              };
              context.dispatch("addDevice", {
                device_type: subdevice_type,
                data: subdevice_data
              });
            });

            ctrl_readers.map((reader) => {
              let subdevice_type = "reader";
              let subdevice_data = {
                id: reader.id,
                name: generateDeviceName(
                  device_data.ip,
                  subdevice_type,
                  reader["data"]["details"][subdevice_type + "_no"]
                ),
                type: subdevice_type,
                ip: device_data.ip,
                building: { id: building_obj.id, name: building_obj.name },
                floor: { id: floor_obj.id, name: floor_obj.name }
              };
              context.dispatch("addDevice", {
                device_type: subdevice_type,
                data: subdevice_data
              });
            });

            ctrl_inputs.map((input) => {
              let subdevice_type = "input";
              let subdevice_data = {
                id: input.id,
                name: generateDeviceName(
                  device_data.ip,
                  subdevice_type,
                  input["data"]["details"][subdevice_type + "_no"]
                ),
                type: subdevice_type,
                ip: device_data.ip,
                building: { id: building_obj.id, name: building_obj.name },
                floor: { id: floor_obj.id, name: floor_obj.name }
              };
              context.dispatch("addDevice", {
                device_type: subdevice_type,
                data: subdevice_data
              });
            });
          }
        }
      }
      return response;
    },
    async fetchBookmarks(context) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/bookmarks/?limit=0";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }
      if (response.result != undefined) {
        response = response.result;
        for (var i in response) {
          var bookmark_data = response[i];
          context.dispatch("addBookmark", bookmark_data);
        }
      }
      return response;
    },
    async fetchSharedWorkspaces(context) {
      //debugger; // eslint-disable-line no-debugger
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/workspaces/?shared=true&limit=0";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
        // console.log("fetchWorkspaces", response)
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        return err;
      }

      if (response.result != undefined) {
        response = response.result;

        let currentUser = context.rootGetters["session/getCurrentUser"];

        let requiredCheckRoleWorkspaceIds = false;

        let response2;
        let rolesList = [];
        let roleObj = {};
        let workspaceIdsArray = [];

        console.log("currentUser.role_id");
        console.log(currentUser.role_id);

        if (
          currentUser.role_id != null &&
          (!currentUser.is_admin || !currentUser.is_superuser)
        ) {
          response2 = await client.getRequest(APISERVER_URL + "/roles/");
          rolesList = response2.result;

          console.log("rolesList");
          console.log(rolesList);

          roleObj = rolesList.find((role) => {
            return role.id === currentUser.role_id;
          });

          requiredCheckRoleWorkspaceIds = true;
        }

        // /** SC_VMS ONLY **/
        // if (context.rootGetters["session/getScMode"] === "SC_VMS") {
        //   requiredCheckRoleWorkspaceIds = false;
        // }
        // /** end */

        console.log("roleObj.workspaces");
        console.log(roleObj.workspaces);

        if (roleObj.workspaces != null) {
          let workspacesObj = roleObj.workspaces;

          workspaceIdsArray = [];
          if (Object.prototype.hasOwnProperty.call(workspacesObj, "ids")) {
            workspaceIdsArray = workspacesObj["ids"];
          }
        }

        for (var i in response) {
          var w_data = {
            id: response[i].id,
            name: response[i].name,
            index: response[i].index,
            layout: response[i].layout,
            is_default: response[i].is_default,
            is_shared: response[i].is_shared
          };

          console.log("requiredCheckRoleWorkspaceIds");
          console.log(requiredCheckRoleWorkspaceIds);

          if (requiredCheckRoleWorkspaceIds) {
            console.log("workspaceIdsArray");
            console.log(workspaceIdsArray);
            console.log("response[i].id");
            console.log(response[i].id);
            console.log("workspaceIdsArray.includes(response[i].id)");
            console.log(workspaceIdsArray.includes(response[i].id));

            if (workspaceIdsArray.includes(response[i].id)) {
              context.dispatch("addSharedWorkspace", w_data);
            }
          } else {
            if (
              //currentUser.role_id == 21 &&
              w_data.layout.panels[i].panel == "vms_host_invite"
            ) {
              context.dispatch("addSharedWorkspace", w_data);
            }

            if (
              currentUser.role_id == null &&
              w_data.layout.panels[i].panel == "vms_transaction"
            ) {
              context.dispatch("addSharedWorkspace", w_data);
            }
          }

          context.dispatch("addManageSharedWorkspace", w_data);
        }

        // console.log("context.getters.getSharedWorkspaces");
        // console.log(context.getters.getSharedWorkspaces);

        /// set default shared workspace
        if (context.getters.getSharedWorkspaces.length > 0) {
          context.dispatch(
            "setSelectedWorkspace",
            context.getters.getSharedWorkspaces[0]
          );

          context.dispatch("setInitWorkspacesState", true);
        }
      }
      return response;
    },
    async fetchWorkspaces(context) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/workspaces/?limit=0";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
        // console.log("fetchWorkspaces", response)
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }

      var index = 0;

      if (response.result != undefined) {
        response = response.result;
        // response.sort(function(a, b) {
        //     return a.index - b.index;
        // });
        for (var i in response) {
          var w_data = {
            id: response[i].id,
            name: response[i].name,
            index: response[i].index,
            layout: response[i].layout,
            is_default: response[i].is_default,
            is_shared: response[i].is_shared
          };
          context.dispatch("addWorkspace", w_data);

          if (response[i].is_default == true) {
            index = i;
          }
        }

        if (response.length > 0) {
          var first_data = {
            id: response[index].id,
            name: response[index].name,
            index: response[index].index,
            layout: response[index].layout,
            is_default: response[index].is_default,
            is_shared: response[index].is_shared
          };

          /** disable personal default shared workspace for now **/

          /** SC_DASHBOARD ONLY **/
          if (context.rootGetters["session/getScMode"] === "SC_DASHBOARD") {
            context.dispatch("setSelectedWorkspace", first_data);
            context.dispatch("setInitWorkspacesState", true);
          }
        }
      }

      return response;
    },
    async fetchForms(context) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/api/visitorservice/form/all/";

      // console.log("fetchForms API_URL", API_URL);

      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
        // console.log("fetchForms", response);
      } catch (err) {
        // console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }

      var index = 0;

      if (response != undefined) {
        for (let url in response.result) {
          let formsList = response.result[url];
          for (let i in formsList) {
            var w_data = {
              id: formsList[i].id,
              name: formsList[i].name,
              index: formsList[i].index,
              form_data: formsList[i].form_data,
              enable: formsList[i].enable
            };
            // console.log('fetchWorkspaces'. w_data)
            context.dispatch("addForm", w_data);

            // if (response[i].is_default == true) {
            //     index = i;
            // }
          }
        }

        // // response = response.result;
        // // console.log("fetchWorkspaces", response)
        // response.sort(function(a, b) {
        //     return a.index - b.index;
        // });
        // for (var i in response) {
        //     var w_data = {
        //         "id": response[i].id,
        //         "name": response[i].name,
        //         "index": response[i].index,
        //         "form_data": response[i].form_data,
        //     }
        //     // console.log('fetchWorkspaces'. w_data)
        //     context.dispatch('addForm', w_data);
        //
        //     // if (response[i].is_default == true) {
        //     //     index = i;
        //     // }
        // }

        // if (response.length > 0) {
        //     var first_data = {
        //         "id": response[i].id,
        //         "name": response[i].name,
        //         "index": response[i].index,
        //         "form_data": response[i].form_data,
        //     }
        //     // context.dispatch('setSelectedForm', first_data);
        //     context.dispatch('setInitFormsState', true);
        // }
      }

      return response;
    },
    async fetchPanelOptions(context) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/permissions/panels/";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }
      // console.log(response)

      if (response.result != undefined) {
        response = response.result;
        context.dispatch("setPanelOptions", response);
      }
      return response;
    },
    async fetchPageOptions(context) {
      var APISERVER_URL = context.rootGetters["session/getAPIServerURL"];
      var API_URL = APISERVER_URL + "/permissions/pages/";
      const client = await context.dispatch(
        "session/getAjaxFetchClient",
        null,
        { root: true }
      );

      let response;

      try {
        response = await client.getRequest(API_URL);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "session/addGlobalAlertMessage",
          { message_text: err.detail, message_type: "danger" },
          { root: true }
        );
        if (err.status == 401) {
          context.dispatch("session/logoutSession", null, { root: true });
        }
        return err;
      }

      if (response.result != undefined) {
        response = response.result;
        context.dispatch("setPageOptions", response);
      }
      return response;
    }
  }
};
