/** VUEX module for PSIM App **/
import Vue from "vue";
import { uuid } from "vue-uuid";
import router from "@/router";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

let utils = require("@/assets/global/js/utils.js");

export default {
  namespaced: true,
  // -----------------------------------------------------------------
  state: {
    accessTokenType: null,
    accessToken: null,
    secureToken: null,
    authserviceToken: null,
    globalAlertMessage: {},
    currentUser: null,
    apiserverURL: null,
    wsserverURL: null,
    wsserverKey: null,
    scMode: null,
    country_code: null
  },
  // -----------------------------------------------------------------
  getters: {
    getAccessTokenType: (state) => {
      return state.accessTokenType;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getSecureToken: (state) => {
      return state.secureToken;
    },
    getAuthserviceToken: (state) => {
      return state.authserviceToken;
    },
    getGlobalAlertMessage: (state) => {
      return state.globalAlertMessage;
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    getAPIToken: (state) => {
      if (state.accessTokenType != null && state.accessToken != null) {
        var token_str = state.accessTokenType + " " + state.accessToken;
        return token_str;
      } else {
        return null;
      }
    },
    getAPIServerURL: (state) => {
      return state.apiserverURL;
    },
    getWSServerURL: (state) => {
      return state.wsserverURL;
    },
    getWSServerKey: (state) => {
      return state.wsserverKey;
    },
    getScMode: (state) => {
      return state.scMode;
    },
    getCountryCode: (state) => {
      return state.country_code;
    }
  },
  // -----------------------------------------------------------------
  mutations: {
    SET_STATE(state, payload) {
      for (var key in payload) {
        var state_name = key;
        var state_value = payload[key];
        state[state_name] = state_value;
      }
    },
    // TODO need to make a message queue if possible
    ADD_GLOBAL_MESSAGE(state, payload) {
      // state["globalAlertMessage"] = payload;
      Vue.set(state.globalAlertMessage, String(payload.id), payload);
    },
    REMOVE_GLOBAL_MESSAGE(state, msg_id) {
      delete state.globalAlertMessage[String(msg_id)];
    }
  },
  // -----------------------------------------------------------------
  actions: {
    setScMode: (context, value) => {
      context.commit("SET_STATE", { scMode: value });
    },
    setCountryCode: (context, value) => {
      context.commit("SET_STATE", { country_code: value });
    },
    setAccessTokenType: (context, value) => {
      context.commit("SET_STATE", { accessTokenType: value });
    },
    setAccessToken: (context, value) => {
      // console.log("setAccessToken");
      context.commit("SET_STATE", { accessToken: value });
    },
    setSecureToken: (context, value) => {
      context.commit("SET_STATE", { secureToken: value });
    },
    setAuthserviceToken: (context, value) => {
      context.commit("SET_STATE", { authserviceToken: value });
    },
    setCurrentUser: async ({ dispatch, commit, state, rootGetters }, value) => {
      commit("SET_STATE", { currentUser: value });

      if (value != null) {
        /** init initBuildings **/
        // console.log("Fetching buildings...");
        dispatch("psim/setInitBuildingsState", false, { root: true });
        dispatch("psim/setBuildings", {}, { root: true });
        dispatch("psim/fetchBuildings", null, { root: true }).then(
          (b_result) => {
            dispatch("psim/setInitBuildingsState", true, { root: true });
            // console.log("Fetched Buildings:", b_result.length, "Buildings");

            /** initFloorDevices **/
            // console.log("Fetching devices...");
            dispatch("psim/setInitCamerasState", false, { root: true });
            dispatch("psim/setInitDoorsState", false, { root: true });
            dispatch("psim/setCameras", {}, { root: true });
            dispatch("psim/setDoors", {}, { root: true });

            let building_qs = rootGetters["psim/getBuildings"]();
            let fetch_count = 0;
            let floor_count = 0;
            // console.log(building_qs);
            for (let i in building_qs) {
              let building = building_qs[i];
              floor_count += building.floors.length;
              // console.log(building);
              for (let f in building.floors) {
                let floor = building.floors[f];
                let params = { building_id: building.id, floor_id: floor.id };

                dispatch("psim/fetchFloorDevices", params, { root: true }).then(
                  () => {
                    fetch_count += 1;
                    if (fetch_count >= floor_count) {
                      dispatch("psim/setInitCamerasState", true, {
                        root: true
                      });
                      dispatch("psim/setInitDoorsState", true, { root: true });
                      let camera_qs = rootGetters["psim/getCameras"]();
                      let door_qs = rootGetters["psim/getDoors"]();
                      // console.log(
                      //   "Fetched Cameras:",
                      //   camera_qs.length,
                      //   "Cameras"
                      // );
                      // console.log("Fetched Doors:", door_qs.length, "Doors");
                    }
                  }
                );
              }
            }
          }
        );

        /** initAllDevices **/
        dispatch("device/clearDevices", null, { root: true });
        dispatch("device/startDevicesPolling", 1000 * 60, { root: true }); // 60 seconds

        /** initBookmarks **/
        dispatch("psim/fetchBookmarks", null, { root: true });

        /** initSharedWorkspaces **/
        dispatch("psim/fetchSharedWorkspaces", null, { root: true }).then(
          async (result) => {
            // console.log("Init Shared Workspaces");
          }
        );

        /** initWorkspaces **/
        dispatch("psim/fetchWorkspaces", null, { root: true }).then(
          async (result) => {
            if (
              result.length <= 0 &&
              value.pages.includes("workspace_management")
            ) {
              const API_URL = state.apiserverURL + "/workspaces/default/";
              const client = await dispatch(
                "session/getAjaxFetchClient",
                null,
                {
                  root: true
                }
              );

              client
                .postRequest(API_URL)
                .then((data) => {
                  if (data.id !== undefined) {
                    let workspace_data = {
                      id: data.id,
                      name: data.name,
                      index: data.index,
                      layout: data.layout,
                      is_default: data.is_default,
                      is_shared: data.is_shared
                    };
                    dispatch("psim/addWorkspace", workspace_data, {
                      root: true
                    });
                    dispatch("psim/setSelectedWorkspace", workspace_data, {
                      root: true
                    });
                    dispatch("psim/setInitWorkspacesState", true, {
                      root: true
                    });
                  } else {
                    dispatch(
                      "session/addGlobalAlertMessage",
                      {
                        message_text: "Failed to create default workspace",
                        message_type: "danger"
                      },
                      { root: true }
                    );
                  }
                })
                .catch((err) => {
                  // dispatch(
                  //   "session/addGlobalAlertMessage",
                  //   {
                  //     message_text: err.detail,
                  //     message_type: "danger"
                  //   },
                  //   { root: true }
                  // );
                  // if (err.status === 401) {
                  //   dispatch("session/logoutSession", null, { root: true });
                  // }
                });
            }
            // console.log("Init Workspaces");
          }
        );

        /** initPermissions **/
        dispatch("psim/fetchPanelOptions", null, { root: true });
        dispatch("psim/fetchPageOptions", null, { root: true });

        // TODO: need to move this part to VMS
        /** init Forms **/
        dispatch("psim/fetchForms", null, { root: true });
      }
    },
    setAPIServerURL: (context, value) => {
      context.commit("SET_STATE", { apiserverURL: value });
    },
    setWSServerURL: (context, value) => {
      context.commit("SET_STATE", { wsserverURL: value });
    },
    setWSServerKey: (context, value) => {
      context.commit("SET_STATE", { wsserverKey: value });
    },
    addGlobalAlertMessage: (context, value) => {
      value["id"] = uuid.v4();
      context.commit("ADD_GLOBAL_MESSAGE", value);
    },
    removeGlobalAlertMessage: (context, value) => {
      context.commit("REMOVE_GLOBAL_MESSAGE", value);
    },
    async logoutSession(context) {
      context.dispatch("setCurrentUser", null);
      context.dispatch("setAccessTokenType", null);
      context.dispatch("setAccessToken", null);
      context.dispatch("setWSServerKey", null);
      sessionStorage.removeItem("sessionKey");
      sessionStorage.removeItem("wsserverKey");

      /** reset polling **/
      context.dispatch("device/resetDevicesPolling", null, { root: true });
      context.dispatch("device/resetDevicesPolling", null, { root: true });

      context.dispatch("psim/resetStore", null, { root: true });
      context.dispatch("alertWS/resetStore", null, { root: true });
      context.dispatch("caseWS/resetStore", null, { root: true });
      context.dispatch("device/resetStore", null, { root: true });

      context.dispatch("addGlobalAlertMessage", {
        message_text: "Logged out successfully.",
        message_type: "warning"
      });

      await router.push({ name: "login" });
      document.location.href = "/login";
    },
    getAjaxFetchClient: (context) => {
      var APISERVER_URL = context.getters["getAPIServerURL"];
      var TOKEN_URL = APISERVER_URL + "/auth/token/";
      var api_token = context.getters["getAPIToken"];
      var secure_token = context.getters["getSecureToken"];

      var fetchConfig = {
        authToken: api_token,
        secureToken: secure_token,
        refreshTokenFunc: context.dispatch,
        refreshTokenFuncArgs: [
          "refreshToken",
          {
            url: TOKEN_URL,
            method: "POST",
            headers: null,
            data: { session_key: sessionStorage.sessionKey }
          }
        ]
      };
      return new AjaxFetch(fetchConfig, this);
    },
    async refreshToken(context, value) {
      var refreshTokenURL = value.url;
      var refreshTokenMethodType = value.method;
      var refreshTokenHeaders = value.headers;
      var refreshTokenData = value.data;

      if (
        refreshTokenURL == "" ||
        refreshTokenURL == null ||
        refreshTokenURL == undefined
      ) {
        return null;
      }

      var fetchConfig = {
        headers: refreshTokenHeaders
      };

      const client = new AjaxFetch(fetchConfig, this);
      let response = null;
      let result = null;
      response = await client
        .fetch(refreshTokenURL, refreshTokenMethodType, refreshTokenData)
        .catch((err) => {});

      if (response != null && response.session_token != undefined) {
        var accessTokenType = response.token_type;
        var accessToken = response.session_token;
        var sessionKey = response.session_key;
        var wsserverKey = response.wsserver_key;
        // TOCLEAN
        // var accessToken = response.access_token;
        // var secureToken = response.secure_key;

        context.dispatch("setAccessTokenType", accessTokenType);
        context.dispatch("setAccessToken", accessToken);
        context.dispatch("setWSServerKey", wsserverKey);

        if (sessionKey != undefined) {
          sessionStorage.sessionKey = sessionKey;
        }
        if (wsserverKey != undefined) {
          sessionStorage.wsserverKey = wsserverKey;
        }
        // if (secureToken != undefined) {
        //     context.dispatch("setSecureToken", secureToken);
        // }

        result = accessTokenType + " " + accessToken;
        // console.log("Refreshed Token")
      } else {
        sessionStorage.removeItem("sessionKey");
        sessionStorage.removeItem("wsserverKey");
      }

      return result;
    }
  }
};
