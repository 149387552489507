import Vue from "vue";
import moment from "moment";
import _ from "lodash";

const timeAgo = (value) => {
  return moment(value).fromNow(true) + " ago";
};

const dateTime = (value) => {
  if (value == null) {
    return null;
  } else {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
  }
};

const date = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

const date2 = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

const unixToTimeAgo = (value) => {
  return moment(value * 1000).fromNow(true) + " ago";
};

const unixToDateTime = (value) => {
  return moment(value * 1000).format("DD/MM/YYYY HH:mm A");
};

const dateTime12Hour = (value) => {
  return moment(value).format("DD/MM/YYYY HH:mm A");
};

const unixToTime = (value) => {
  return moment(value * 1000).format("HH:mm:ss");
};

const unixToTime12Hour = (value) => {
  if (value != null) {
    return moment(value * 1000).format("HH:mm A");
  } else {
    return "-";
  }
};

const unixToTime12Hours = (value) => {
  return moment(value, "HH:mm:ss").format("HH:mm A");
};

const unixToDate = (value) => {
  return moment(value * 1000).format("YYYY-MM-DD");
};

const dashForEmptyText = (value) => {
  return value ? value : "-";
};

const humanizeText = (value) => {
  //return _.startCase(_.toLower(value));
  return _.startCase(value);
};

const humanizeText2 = (value) => {
  return _.startCase(_.toLower(value));
};

const upperCase = (value) => {
  return value.toUpperCase();
};

const durationConvert = (value) => {
  var num = value;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours != 0 && rminutes != 0) {
    return rhours + " hours " + rminutes + " minutes";
  }
  if (rhours != 0 && rminutes == 0) {
    return rhours + " hours ";
  }
  if (rhours == 0 && rminutes != 0) {
    return rminutes + " minutes ";
  }
};

Vue.filter("timeAgo", timeAgo);
Vue.filter("dateTime", dateTime);
Vue.filter("dateTime12Hour", dateTime12Hour);
Vue.filter("date", date);
Vue.filter("date2", date2);

Vue.filter("unixToTimeAgo", unixToTimeAgo);
Vue.filter("unixToDateTime", unixToDateTime);
Vue.filter("unixToDate", unixToDate);
Vue.filter("unixToTime", unixToTime);
Vue.filter("unixToTime12Hour", unixToTime12Hour);
Vue.filter("unixToTime12Hours", unixToTime12Hours);

Vue.filter("dashForEmptyText", dashForEmptyText);
Vue.filter("durationConvert", durationConvert);
Vue.filter("upperCase", upperCase);
Vue.filter("humanizeText", humanizeText);
Vue.filter("humanizeText2", humanizeText2);
